import * as yup from 'yup'
import {
  maxString200Message,
  maxString200Value,
  requiredFieldMessage
} from '../constants'
import { CompanyDto } from './companies'
import { OpportunityActivityDto } from './opportunityActivity'
import { PersonDto } from './people'
import { QuoteDto } from './quote'

export class OpportunityDto {
  id?: string = undefined
  displayOpportunityNumber?: string = undefined
  targetCompany?: CompanyDto = undefined
  targetPerson?: PersonDto = undefined
  salesperson?: PersonDto = undefined
  opportunityName?: string = undefined
  opportunityDescription?: string = undefined

  // Navigation Properties
  // Opportunity activities are sorted by CreatedDateTime descending, so the most recent activity is the first in the array.
  opportunityActivity?: OpportunityActivityDto[] = undefined
  quote?: QuoteDto = undefined
}

export const opportunityValidationSchema = yup.object({
  targetCompany: yup.object().required(requiredFieldMessage),
  // targetPerson: yup.object().required(requiredFieldMessage),
  opportunityName: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .required(requiredFieldMessage),
  opportunityDescription: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .required(requiredFieldMessage),
  salesperson: yup.object().required(requiredFieldMessage)
})
