import { ApiResponse, DuplicateQuoteDto, QuoteDto } from 'dtos'
import apiClient from '.'

export const getQuoteById = (id: string) =>
  apiClient.get<ApiResponse<QuoteDto>>('/api/quotes/' + id).then(res => res.data)

export const updateQuote = (values: QuoteDto) => {
  const data = new FormData()

  data.append('quote', JSON.stringify(values))

  values.quoteRevision?.quoteLineItem?.quoteLineItemDesignFiles?.forEach(
    (designFile, index) => {
      if (designFile.fileUpload) {
        data.append(
          `quoteLineItemDesignFiles[${index}].fileUpload`,
          designFile.fileUpload
        )
      }
    }
  )

  return apiClient
    .put<ApiResponse<QuoteDto>>('/api/quotes', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => res.data)
}

export const createNewQuoteRevision = (values: QuoteDto) =>
  apiClient
    .post<ApiResponse<QuoteDto>>('/api/quotes/new-revision', values)
    .then(res => res.data)

export const duplicateQuoteRevision = (values: DuplicateQuoteDto) =>
  apiClient
    .post<ApiResponse<DuplicateQuoteDto>>('/api/quotes/duplicate-revision', values)
    .then(res => res.data)
