import { Card, Grid } from '@mui/material'
import { Layout } from 'components/_template'
import { useAuthContext } from 'context'
import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

export default function Blank() {
  const { status } = useAuthContext()
  const location = useLocation()

  useEffect(() => {
    document.title = 'Spud ERP'
  }, [])

  if (status === 'unauthorized') {
    return <Navigate to={'/account/sign-in'} state={{ from: location }} />
  }

  return (
    <Layout>
      <Grid>
        {/* TODO:: Replace with Fork Lift */}
        <img
          src={process.env.PUBLIC_URL + '/NGWhenItMatters.png'}
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          alt='When it matters'
        />
      </Grid>
    </Layout>
  )
}
