import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import { createContext, useEffect, useContext, useState } from 'react'
import { darkTheme, lightTheme } from 'theme'

export const ThemeContext = createContext<{
  themeMode: 'dark' | 'light'
  setMode: (mode: 'dark' | 'light') => void
} | null>(null)

export default function ThemeProvider({ children }: React.PropsWithChildren) {
  const [themeMode, setThemeMode] = useState<'dark' | 'light'>('light')

  useEffect(() => {
    setThemeMode(window.localStorage.getItem('theme') === 'dark' ? 'dark' : 'light')
  }, [])

  const setMode = (mode: 'dark' | 'light') => {
    setThemeMode(mode)
    window.localStorage.setItem('theme', mode)
  }

  return (
    <ThemeContext.Provider value={{ themeMode, setMode }}>
      <MuiThemeProvider theme={themeMode === 'dark' ? darkTheme : lightTheme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  )
}

export const useThemeModeContext = () => useContext(ThemeContext)!
