export function base64ToArrayBuffer(data: string) {
  let binaryString = window.atob(data)
  let binaryLen = binaryString.length
  let bytes = new Uint8Array(binaryLen)
  for (let i = 0; i < binaryLen; i++) {
    let ascii = binaryString.charCodeAt(i)
    bytes[i] = ascii
  }
  return bytes
}
