import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip
} from '@mui/material'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import Popper from '@mui/material/Popper'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import apiClient, { getDepartments } from 'api'
import { UnsavedChangesPrompt } from 'components'
import { Layout } from 'components/_template'
import {
  Details,
  DetailsActions,
  DetailsForm,
  DetailsHeaderCard,
  DetailsTab
} from 'components/_template/details'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import { useAuthContext } from 'context'
import {
  ApiResponse,
  CompanyDto,
  DepartmentDto,
  PermissionDto,
  PersonDto,
  peopleValidationSchema
} from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ListChildComponentProps, VariableSizeList } from 'react-window'
import { errorHandling } from '../constants'
import { formats } from 'theme'
import { ExplanationAccordion } from 'components/_template/accordion'

const LISTBOX_PADDING = 8
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING
  }
  return (
    <Typography component='li' {...dataSet[0]} noWrap style={inlineStyle}>
      {`${dataSet[1].name}`}
    </Typography>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData: React.ReactChild[] = []
  ;(children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item)
      itemData.push(...(item.children || []))
    }
  )

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true
  })
  const itemCount = itemData.length
  // Make items 36 pixels tall on a "small" or larger screen, and 48 piels tall otherwise
  const itemSize = smUp ? 36 : 48

  // Only show a maximum of 8 items in the dropdown
  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemCount * itemSize
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          // Every item is the same height, so don't set the itemSize individually for each index
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
})

export default function PersonDetails() {
  // #region hooks
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { id } = useParams()
  const { COMPANIES_AND_PEOPLE_ADD_EDIT, USERS_ADD_EDIT } = useAuthContext()
  // #endregion

  // #region useState
  const [companies, setCompanies] = useState<CompanyDto[]>([])
  const [departments, setDepartments] = useState<DepartmentDto[]>([])
  const [permissions, setPermissions] = useState<PermissionDto[]>([])
  const [initialValues, setInitialValues] = useState<PersonDto>(new PersonDto())
  const [isGettingPerson, setIsGettingPerson] = useState<boolean>(false)
  // #endregion

  // #region useEffect
  useEffect(() => {
    apiClient
      .get<ApiResponse<CompanyDto[]>>('/api/companies?pageSize=2000')
      .then(({ data: { value } }) => {
        setCompanies(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })

    getDepartments()
      .then(({ value }) => {
        setDepartments(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })

    apiClient
      .get<ApiResponse<PermissionDto[]>>('/api/permissions')
      .then(({ data: { value } }) => {
        setPermissions(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
  }, [])

  useEffect(() => {
    if (id && id.toLowerCase() !== 'new' && id.toLowerCase() !== 'undefined') {
      setIsGettingPerson(true)
      apiClient
        .get<ApiResponse<PersonDto>>('/api/people/' + id)
        .then(({ data: { value } }) => {
          setInitialValues(value)
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
        .finally(() => {
          setIsGettingPerson(false)
        })
    } else {
      setInitialValues(new PersonDto())
    }
  }, [id])
  // #endregion

  const sendPasswordReset = () => {
    apiClient
      .post<ApiResponse<string>>('/api/account/forgot-password', {
        email: initialValues.email
      })
      .then(({ data: { value } }) => {
        enqueueSnackbar(value, { variant: 'success' })
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
  }

  return (
    <Layout title='Person Details' isLoading={isGettingPerson}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange
        validateOnBlur
        validationSchema={peopleValidationSchema}
        onSubmit={async (values, submitProps) => {
          await (values.id ? apiClient.put : apiClient.post)('/api/people', values)
            .then(res => {
              enqueueSnackbar(`${values.firstName} ${values.lastName} has been saved!`, {
                variant: 'success'
              })

              setInitialValues(res.data.value)
              submitProps.resetForm({ values: res.data.value })

              if (!values.id) {
                setTimeout(() => {
                  navigate('/people/' + res.data.value.id, { replace: true })
                }, 100)
              }
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
        }}
      >
        {({
          dirty,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          let personDetailsTabErrors = Boolean(
            errors.firstName ||
              errors.lastName ||
              errors.company ||
              errors.title ||
              errors.email ||
              errors.mobilePhone ||
              errors.workPhone ||
              errors.isActive
          )
          let accountSetupTabErrors = Boolean(
            errors.allowUserToSignIn || errors.permissions || errors.isUser
          )
          let humanResourcesTabErrors = Boolean(
            errors.employmentStartDate || errors.employmentEndDate || errors.departments
          )

          return (
            <>
              <Details
                header={<DetailsHeaderCard title='Person Details' />}
                tabs={[
                  {
                    value: 'details',
                    label: 'PERSON DETAILS',
                    error: personDetailsTabErrors
                  },
                  {
                    value: 'account-setup',
                    label: 'ACCOUNT SETUP',
                    disabled: !USERS_ADD_EDIT,
                    error: accountSetupTabErrors
                  },
                  {
                    value: 'human-resources',
                    label: 'HUMAN RESOURCES',
                    error: humanResourcesTabErrors
                  }
                ]}
                onSubmit={handleSubmit}
              >
                <DetailsTab value='details'>
                  <DetailsForm>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <TextField
                            disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                            error={Boolean(touched.firstName && errors.firstName)}
                            fullWidth
                            helperText={touched.firstName && errors.firstName}
                            label='First Name'
                            name='firstName'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.firstName ?? ''}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <TextField
                            disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                            error={Boolean(touched.lastName && errors.lastName)}
                            fullWidth
                            helperText={touched.lastName && errors.lastName}
                            label='Last Name'
                            name='lastName'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.lastName ?? ''}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <Autocomplete
                            disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                            id='company'
                            disableListWrap
                            getOptionLabel={option => option.name}
                            ListboxComponent={ListboxComponent}
                            onChange={(_e, value) => {
                              setFieldValue('company', value)
                            }}
                            options={companies}
                            PopperComponent={StyledPopper}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label='Company'
                                error={Boolean(touched.company && errors.company)}
                                helperText={touched.company && errors.company}
                              />
                            )}
                            renderOption={(props, option, state) =>
                              [props, option, state.index] as React.ReactNode
                            }
                            // The Autocomplete is only blank when the value is null, NOT undefined, so this must be "values.company ?? null"
                            value={values.company ?? null}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <TextField
                            disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                            error={Boolean(touched.title && errors.title)}
                            fullWidth
                            helperText={touched.title && errors.title}
                            label='Title'
                            name='title'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder='Project Manager'
                            value={values?.title ?? ''}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <TextField
                            disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                            error={Boolean(touched.email && errors.email)}
                            fullWidth
                            helperText={touched.email && errors.email}
                            label='Email'
                            name='email'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values?.email ?? ''}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <TextField
                            disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                            error={Boolean(touched.mobilePhone && errors.mobilePhone)}
                            fullWidth
                            helperText={touched.mobilePhone && errors.mobilePhone}
                            label='Mobile No.'
                            name='mobilePhone'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder='+1 (123) 456-8790'
                            value={values?.mobilePhone ?? ''}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <TextField
                            disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                            error={Boolean(touched.workPhone && errors.workPhone)}
                            fullWidth
                            helperText={touched.workPhone && errors.workPhone}
                            label='Office No.'
                            name='workPhone'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder='+1 (123) 456-8790'
                            value={values?.workPhone ?? ''}
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.isActive}
                                disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                                name='isActive'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.isActive}
                              />
                            }
                            label='Active'
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </DetailsForm>
                </DetailsTab>

                <DetailsTab value='account-setup'>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Spud ERP Access
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={6} alignItems={'space-between'}>
                            <Tooltip
                              title={
                                USERS_ADD_EDIT
                                  ? ''
                                  : 'Must have "Users add/edit" permission'
                              }
                              placement='bottom'
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={values?.allowUserToSignIn ?? false}
                                    disabled={isSubmitting || !USERS_ADD_EDIT}
                                    name='allowUserToSignIn'
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values?.allowUserToSignIn}
                                  />
                                }
                                label='Access to Spud ERP'
                              />
                            </Tooltip>

                            <Tooltip
                              title={
                                // This is intentionally initialValues.AllowUserToSignIn and NOT values.AllowUserToSignIn, because only users who are saved in the database as
                                // AllowUserToSignIn = true should be able to receive password reset emails. Otherwise, a user who can't actually sign in according to the database
                                // could receive a password reset email.
                                !initialValues.allowUserToSignIn
                                  ? 'Please check "Access to Spud ERP" and save to send this user a password reset email.'
                                  : ''
                              }
                            >
                              <span>
                                <Button
                                  disabled={
                                    isSubmitting ||
                                    !initialValues.allowUserToSignIn ||
                                    !USERS_ADD_EDIT
                                  }
                                  onClick={sendPasswordReset}
                                  variant='text'
                                >
                                  SEND PASSWORD RESET
                                </Button>
                              </span>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Feature Access
                              </Typography>
                            </Grid>
                          </Grid>
                          {permissions
                            .filter(
                              permission =>
                                !permission.specialIdentifier?.includes('REPORT')
                            )
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(permission => (
                              <Grid item xs={12} key={permission.id}>
                                <Tooltip
                                  title={
                                    USERS_ADD_EDIT
                                      ? ''
                                      : 'Must have "Users add/edit" permission'
                                  }
                                  placement='bottom'
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.permissions
                                          .map(permission => permission.id)
                                          .includes(permission.id)}
                                        disabled={
                                          isSubmitting ||
                                          !values.allowUserToSignIn ||
                                          !USERS_ADD_EDIT
                                        }
                                        onChange={e => {
                                          // Add if not exist in permissions array
                                          if (e.target.checked) {
                                            setFieldValue('permissions', [
                                              ...values.permissions,
                                              permission
                                            ])
                                            // Remove if exist in permissions array
                                          } else {
                                            setFieldValue(
                                              'permissions',
                                              values.permissions.filter(
                                                existingPermission =>
                                                  existingPermission.id !== permission.id
                                              )
                                            )
                                          }
                                        }}
                                      />
                                    }
                                    label={permission.name}
                                  />
                                </Tooltip>
                              </Grid>
                            ))}
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems='center'
                            justifyContent='space-between'
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#2780E3', fontWeight: 600 }}
                              >
                                Report Access
                              </Typography>
                            </Grid>
                          </Grid>
                          {permissions
                            .filter(permission =>
                              permission.specialIdentifier?.includes('REPORT')
                            )
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(permission => (
                              <Grid item xs={12} key={permission.id}>
                                <Tooltip
                                  title={
                                    USERS_ADD_EDIT
                                      ? ''
                                      : 'Must have "Users add/edit" permission'
                                  }
                                  placement='bottom'
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.permissions
                                          .map(permission => permission.id)
                                          .includes(permission.id)}
                                        disabled={
                                          isSubmitting ||
                                          !values.allowUserToSignIn ||
                                          !USERS_ADD_EDIT
                                        }
                                        onChange={e => {
                                          // Add if not exist in permissions array
                                          if (e.target.checked) {
                                            setFieldValue('permissions', [
                                              ...values.permissions,
                                              permission
                                            ])
                                            // Remove if exist in permissions array
                                          } else {
                                            setFieldValue(
                                              'permissions',
                                              values.permissions.filter(
                                                existingPermission =>
                                                  existingPermission.id !== permission.id
                                              )
                                            )
                                          }
                                        }}
                                      />
                                    }
                                    label={permission.name}
                                  />
                                </Tooltip>
                              </Grid>
                            ))}
                        </Grid>
                      </DetailsForm>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsForm>
                        <Grid item xs={12}>
                          <ExplanationAccordion>
                            Users must also have the "Companies and people add/edit"
                            permission to access Account Setup under Person
                            Details.||Users must have access to at least one report to see
                            the "Reports" feature in the navigation menu.
                          </ExplanationAccordion>
                        </Grid>
                      </DetailsForm>
                    </Grid>
                  </Grid>
                </DetailsTab>

                <DetailsTab value='human-resources'>
                  <DetailsForm>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <TextField
                            disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label='Start Date'
                            name='employmentStartDate'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type='date'
                            value={
                              values?.employmentStartDate
                                ? moment
                                    .utc(values.employmentStartDate)
                                    .format(formats.dateOnlyField)
                                : ''
                            }
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <TextField
                            disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            label='End Date'
                            name='employmentEndDate'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder='End Date'
                            type='date'
                            value={
                              values?.employmentEndDate
                                ? moment
                                    .utc(values.employmentEndDate)
                                    .format(formats.dateOnlyField)
                                : ''
                            }
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Tooltip
                          title={
                            COMPANIES_AND_PEOPLE_ADD_EDIT
                              ? ''
                              : 'Must have "Companies and people add/edit" permission'
                          }
                          placement='bottom'
                        >
                          <FormControl fullWidth>
                            <InputLabel id='departments-label'>Positions</InputLabel>
                            <Select
                              labelId='departments-label'
                              disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                              multiple
                              fullWidth
                              value={values.departments.map(department => department.id)}
                              onChange={(
                                event: SelectChangeEvent<string[]>,
                                _child: React.ReactNode
                              ) => {
                                if (Array.isArray(event.target.value)) {
                                  setFieldValue(
                                    'departments',
                                    departments.filter(department =>
                                      event.target.value.includes(department.id)
                                    )
                                  )
                                }
                              }}
                              input={<OutlinedInput label='Positions' />}
                              renderValue={selected => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map(departmentId => (
                                    <Chip
                                      key={departmentId}
                                      label={
                                        departments.filter(
                                          department => department.id === departmentId
                                        )[0].name
                                      }
                                    />
                                  ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {departments
                                .map(department => department.id)
                                .map(departmentId => (
                                  <MenuItem key={departmentId} value={departmentId}>
                                    <Checkbox
                                      checked={
                                        values.departments
                                          .map(department => department.id)
                                          .indexOf(departmentId) > -1
                                      }
                                    />
                                    <ListItemText
                                      primary={
                                        departments.filter(
                                          department => department.id === departmentId
                                        )[0].name
                                      }
                                    />
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </DetailsForm>
                </DetailsTab>

                <DetailsActions>
                  <Button
                    color='secondary'
                    disabled={isSubmitting}
                    onClick={() => {
                      navigate('/people')
                    }}
                    variant='text'
                  >
                    BACK
                  </Button>

                  <Tooltip
                    title={
                      COMPANIES_AND_PEOPLE_ADD_EDIT
                        ? ''
                        : 'Must have "Companies and people add/edit" permission'
                    }
                    placement='bottom'
                  >
                    <span>
                      <LoadingButton
                        color='primary'
                        disabled={isSubmitting || !COMPANIES_AND_PEOPLE_ADD_EDIT}
                        loading={isSubmitting}
                        onClick={() => {
                          // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                          // Otherwise, sometimes the field errors won't display even though the validation throws them
                          validateForm().then(errors => {
                            const errorKeys = Object.keys(errors)
                            if (errorKeys.length === 0) {
                              submitForm()
                            } else {
                              setTouched(setNestedObjectValues(errors, true))
                              showFormErrorsPrompt(errors)
                            }
                          })
                        }}
                        variant='contained'
                      >
                        <span>{values.id ? 'UPDATE' : 'CREATE'} PERSON</span>
                      </LoadingButton>
                    </span>
                  </Tooltip>
                </DetailsActions>
              </Details>

              <UnsavedChangesPrompt when={dirty} />
            </>
          )
        }}
      </Formik>
    </Layout>
  )
}
