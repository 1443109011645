import { ApiResponse, QuoteAssemblyUnitTypeDto } from 'dtos'
import { QuoteAssemblyUnitTypesParameters } from 'parameters'
import { URIQuery } from 'utils'
import apiClient from '.'

export const getQuoteAssemblyUnitTypes = (parameters: QuoteAssemblyUnitTypesParameters) =>
  apiClient
    .get<ApiResponse<QuoteAssemblyUnitTypeDto[]>>(
      '/api/quote-assembly-unit-types' + URIQuery({ ...parameters })
    )
    .then(res => res.data)
