import apiClient from 'api'
import { ApiResponse, VendorOrderDto, VendorReorderDto } from 'dtos'
import { VendorOrdersParameters, VendorOrdersReceivingParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getVendorOrders = (parameters: VendorOrdersParameters) =>
  apiClient
    .get<ApiResponse<VendorOrderDto[]>>(
      '/api/vendor-orders' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getVendorOrdersReceiving = (parameters: VendorOrdersReceivingParameters) =>
  apiClient
    .get<ApiResponse<VendorOrderDto[]>>(
      '/api/vendor-orders/receiving' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getVendorOrderById = (id: string) =>
  apiClient
    .get<ApiResponse<VendorOrderDto>>('/api/vendor-orders/' + id)
    .then(({ data }) => data)

export const createVendorOrder = (values: VendorOrderDto) =>
  apiClient
    .post<ApiResponse<VendorOrderDto>>('/api/vendor-orders', values)
    .then(({ data }) => data)

export const updateVendorOrder = (values: VendorOrderDto) =>
  apiClient
    .put<ApiResponse<VendorOrderDto>>('/api/vendor-orders', values)
    .then(({ data }) => data)

export const readyToSchedule = (id: string) =>
  apiClient
    .post<ApiResponse<VendorOrderDto>>('/api/vendor-orders/ready-to-schedule/' + id)
    .then(({ data }) => data)

export const reorderVendorOrder = (values: VendorReorderDto) =>
  apiClient
    .post<ApiResponse<VendorOrderDto>>('/api/vendor-orders/reorder', values)
    .then(({ data }) => data)
