import { ReadMoreOutlined, SearchOutlined } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { Layout } from 'components/_template'
import { List, ListHeaderCard, ListItemCard } from 'components/_template/list'
import { useNavigate } from 'react-router-dom'
import { Avatar } from '@mui/material'
import { Box, Grid } from '@mui/material'
import {
  green,
  grey,
  red,
  orange,
  blueGrey,
  lightBlue,
  purple
} from '@mui/material/colors'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import ContactsIcon from '@mui/icons-material/Contacts'
import DescriptionIcon from '@mui/icons-material/Description'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import TagFacesIcon from '@mui/icons-material/TagFaces'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import EngineeringIcon from '@mui/icons-material/Engineering'
export default function Opportunities() {
  const navigate = useNavigate()

  return (
    <Layout title='Vehicles'>
      {/* HEADER CARD */}
      {/* HEADER CARD */}
      {/* HEADER CARD */}

      <List
        header={
          <ListHeaderCard
            title='Vehicles'
            filters={[
              <TextField
                data-search
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchOutlined fontSize='small' />
                    </InputAdornment>
                  )
                }}
                label='Search'
                name='search'
                size='small'
                type='search'
              />,
              <FormControlLabel control={<Checkbox />} label='Active only' />
            ]}
            actions={[
              <Tooltip title='Add' placement='bottom'>
                <Button
                  onClick={() => navigate('/vehicle/new')}
                  variant='contained'
                  size='medium'
                >
                  ADD NEW
                </Button>
              </Tooltip>
            ]}
          />
        }
      >
        {/* SUMMARY CARD */}
        {/* SUMMARY CARD */}
        {/* SUMMARY CARD */}

        <ListItemCard>
          <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
            {/* STAT 1 */}
            {/* STAT 1 */}
            {/* STAT 1 */}
            <Grid item>
              {' '}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  variant='rounded'
                  sx={{
                    bgcolor: 'white',
                    color: grey[400],
                    ml: -1,
                    mr: 1,
                    boxShadow: 3
                  }}
                >
                  <LocalShippingIcon />
                </Avatar>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='caption'>Total Vehicles</Typography>
                  <Typography variant='h6' sx={{ ml: 1 }}>
                    {14}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* STAT 2 */}
            {/* STAT 2 */}
            {/* STAT 2 */}
            <Grid item>
              {' '}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  variant='rounded'
                  sx={{
                    bgcolor: 'white',
                    color: orange[500],
                    ml: -1,
                    mr: 1,
                    boxShadow: 3
                  }}
                >
                  <EngineeringIcon />
                </Avatar>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='caption'>Upcoming Maintenance</Typography>
                  <Typography variant='h6' sx={{ ml: 1 }}>
                    {7}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ListItemCard>
        {/* DATA CARD */}
        {/* DATA CARD */}
        {/* DATA CARD */}

        <ListItemCard
          chips={[
            <Chip
              key='vendor'
              label='Upcoming maintenance'
              color='primary'
              variant='outlined'
            />
          ]}
          key={1}
          title={'Chevy Van - JH4CC2660RC002031'}
          actions={[
            <Tooltip title={'Details'}>
              <IconButton
                onClick={() => navigate('/vehicle/new')}
                color='primary'
                // size='large'
              >
                <ReadMoreOutlined fontSize='large' />
              </IconButton>
            </Tooltip>
          ]}
        >
          <Typography variant='body2' sx={{ color: '#7F7F7F', fontWeight: 600 }}>
            {'Next maintenance due 6/1/2022'}
          </Typography>
        </ListItemCard>
      </List>
    </Layout>
  )
}
