// import { People, Punches } from '@prisma/client'
import * as yup from 'yup'
import { PersonDto, peopleValidationSchema } from './people'

export const PunchesModelToDto = (model: any): PunchesDto => {
  let result: PunchesDto = PunchesInitialValues

  if (model) {
    // result = {
    //   Id: model.Id,
    //   CreatedDateTimeUtc: model.WorkDate,
    //   WorkDate: model.WorkDate,
    //   WorkingRemote: model.WorkingRemote,
    //   ManualInsert: model.ManualInsert,
    //   // Person: model.Person ?? null
    // }
  }

  return result
}

export type PunchesDto = {
  Id?: string
  CreatedDateTimeUtc: Date | null
  WorkDate: Date | null
  WorkingRemote: boolean | null
  ManualInsert: boolean | null
  Person: PersonDto | null
}

export const PunchesInitialValues: PunchesDto = {
  Id: undefined,
  CreatedDateTimeUtc: null,
  WorkDate: null,
  WorkingRemote: false,
  ManualInsert: false,
  Person: null
}

export const PunchesValidationSchema = yup.object<PunchesDto>({
  Id: yup.string().nullable(),
  CreatedDateTimeUtc: yup.string().nullable(),
  WorkDate: yup.string().nullable(),
  WorkingRemote: yup.bool(),
  ManualInsert: yup.bool()
  // Person:
  //   PersonValidationSchema.nullable('Company Is Required.').required(
  //     'Company Is Required.'
  //   )
})
