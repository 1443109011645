import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField
} from '@mui/material'
import { RatesInkDto, ratesInkValidationSchema } from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { showFormErrorsPrompt } from './_template/form/FormErrorsPrompt'

interface RatesInksAddEditDialogProps {
  onClose: () => void
  onSave: (ink: RatesInkDto) => Promise<void | RatesInkDto>
  open: boolean
  values: RatesInkDto
}

export default function RatesInksAddEditDialog({
  onClose,
  onSave,
  open,
  values
}: RatesInksAddEditDialogProps) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={values}
        validateOnBlur
        validateOnChange
        validationSchema={ratesInkValidationSchema}
        onSubmit={async (values, submitProps) => {
          await onSave(values).finally(() => {
            submitProps.setSubmitting(false)
          })
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Add Inks</DialogTitle>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.gcmiColors && errors.gcmiColors)}
                      fullWidth
                      helperText={touched.gcmiColors && errors.gcmiColors}
                      label='GCMI Colors'
                      name='gcmiColors'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.gcmiColors || ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(
                        touched.pantoneEquivalents && errors.pantoneEquivalents
                      )}
                      fullWidth
                      helperText={touched.pantoneEquivalents && errors.pantoneEquivalents}
                      label='Pantone Equivalents'
                      name='pantoneEquivalents'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.pantoneEquivalents || ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.hexCode && errors.hexCode)}
                      fullWidth
                      helperText={touched.hexCode && errors.hexCode}
                      label='Hex'
                      name='hexCode'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.hexCode || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isActive}
                          disabled={isSubmitting}
                          name='isActive'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.isActive}
                        />
                      }
                      label='Active'
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button variant='text' color='secondary' onClick={onClose}>
                  CLOSE
                </Button>

                <Button
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
