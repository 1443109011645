import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import { ExplanationAccordion } from 'components/_template/accordion'
import { BoardTypeDto, CompanyDto, RatesPaperDto, ratesPaperValidationSchema } from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { showFormErrorsPrompt } from './_template/form/FormErrorsPrompt'

interface RatesPaperAddersAddEditDialogProps {
  boardTypes: BoardTypeDto[]
  onClose: () => void
  onSave: (paper: RatesPaperDto) => Promise<void | RatesPaperDto>
  open: boolean
  values: RatesPaperDto
  vendors: CompanyDto[]
}

export default function RatesPaperAddersAddEditDialog({
  boardTypes,
  onClose,
  onSave,
  open,
  values,
  vendors
}: RatesPaperAddersAddEditDialogProps) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={values}
        validateOnBlur
        validateOnChange
        validationSchema={ratesPaperValidationSchema}
        onSubmit={async (values, submitProps) => {
          await onSave(values).finally(() => {
            submitProps.setSubmitting(false)
          })
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Add Paper</DialogTitle>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={isSubmitting}
                        error={Boolean(touched.company && errors.company)}
                        fullWidth
                        helperText={touched.company && errors.company}
                        label='Vendor'
                        name='company'
                        onBlur={handleBlur}
                        onChange={e => {
                          setFieldValue('company', JSON.parse(e.target.value))
                        }}
                        select
                        value={
                          values?.company
                            ? JSON.stringify(
                                vendors.filter(
                                  vendor => vendor.id === values.company!.id
                                )[0]
                              )
                            : ''
                        }
                      >
                        {vendors
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          .map(vendor => (
                            <MenuItem value={JSON.stringify(vendor)} key={vendor.id}>
                              {vendor.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.paperCode && errors.paperCode)}
                      fullWidth
                      helperText={touched.paperCode && errors.paperCode}
                      label='Paper Code'
                      name='paperCode'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.paperCode || ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={isSubmitting}
                        error={Boolean(touched.boardType && errors.boardType)}
                        fullWidth
                        helperText={touched.boardType && errors.boardType}
                        label='Board'
                        name='boardType'
                        onBlur={handleBlur}
                        onChange={e => {
                          setFieldValue('boardType', JSON.parse(e.target.value))
                        }}
                        select
                        value={
                          values?.boardType
                            ? JSON.stringify(
                                boardTypes.filter(
                                  boardType => boardType.id === values.boardType!.id
                                )[0]
                              )
                            : ''
                        }
                      >
                        {boardTypes
                          ?.sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0))
                          .map(boardType => (
                            <MenuItem
                              value={JSON.stringify(boardType)}
                              key={boardType.id}
                            >
                              {boardType.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.flute && errors.flute)}
                      fullWidth
                      helperText={touched.flute && errors.flute}
                      label='Flute'
                      name='flute'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.flute || ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.ect && errors.ect)}
                      fullWidth
                      helperText={touched.ect && errors.ect}
                      label='Ect / Mullen'
                      name='ect'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ect}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.basisWeightValue && errors.basisWeightValue)}
                      fullWidth
                      helperText={touched.basisWeightValue && errors.basisWeightValue}
                      label='Basis Weight Value'
                      name='basisWeightValue'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.basisWeightValue}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.blend && errors.blend)}
                      fullWidth
                      helperText={touched.blend && errors.blend}
                      label='Blend'
                      name='blend'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.blend || ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.liner1 && errors.liner1)}
                      fullWidth
                      helperText={touched.liner1 && errors.liner1}
                      label='Liner 1'
                      name='liner1'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.liner1}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.medium1 && errors.medium1)}
                      fullWidth
                      helperText={touched.medium1 && errors.medium1}
                      label='Medium 1'
                      name='medium1'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.medium1}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(
                        touched.medium1TakeUpFactor && errors.medium1TakeUpFactor
                      )}
                      fullWidth
                      helperText={
                        touched.medium1TakeUpFactor && errors.medium1TakeUpFactor
                      }
                      label='Medium 1 Take Up Factor'
                      name='medium1TakeUpFactor'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.medium1TakeUpFactor}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.liner2 && errors.liner2)}
                      fullWidth
                      helperText={touched.liner2 && errors.liner2}
                      label='Liner 2'
                      name='liner2'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.liner2}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.medium2 && errors.medium2)}
                      fullWidth
                      helperText={touched.medium2 && errors.medium2}
                      label='Medium 2'
                      name='medium2'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.medium2}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(
                        touched.medium2TakeUpFactor && errors.medium2TakeUpFactor
                      )}
                      fullWidth
                      helperText={
                        touched.medium2TakeUpFactor && errors.medium2TakeUpFactor
                      }
                      label='Medium 2 Take Up Factor'
                      name='medium2TakeUpFactor'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.medium2TakeUpFactor}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.liner3 && errors.liner3)}
                      fullWidth
                      helperText={touched.liner3 && errors.liner3}
                      label='Liner 3'
                      name='liner3'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.liner3}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.medium3 && errors.medium3)}
                      fullWidth
                      helperText={touched.medium3 && errors.medium3}
                      label='Medium 3'
                      name='medium3'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.medium3}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(
                        touched.medium3TakeUpFactor && errors.medium3TakeUpFactor
                      )}
                      fullWidth
                      helperText={
                        touched.medium3TakeUpFactor && errors.medium3TakeUpFactor
                      }
                      label='Medium 3 Take Up Factor'
                      name='medium3TakeUpFactor'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.medium3TakeUpFactor}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.liner4 && errors.liner4)}
                      fullWidth
                      helperText={touched.liner4 && errors.liner4}
                      label='Liner 4'
                      name='liner4'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.liner4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(
                        touched.starchFactorAllMediumsCombined &&
                          errors.starchFactorAllMediumsCombined
                      )}
                      fullWidth
                      helperText={
                        touched.starchFactorAllMediumsCombined &&
                        errors.starchFactorAllMediumsCombined
                      }
                      label='Starch Factor All Mediums Combined'
                      name='starchFactorAllMediumsCombined'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.starchFactorAllMediumsCombined}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.setupFee && errors.setupFee)}
                      fullWidth
                      helperText={touched.setupFee && errors.setupFee}
                      label='Setup Fee'
                      name='setupFee'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.setupFee}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.msf0to5 && errors.msf0to5)}
                      fullWidth
                      helperText={touched.msf0to5 && errors.msf0to5}
                      label='Msf&gt;0&lt;5'
                      name='msf0to5'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.msf0to5}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.msf5to10 && errors.msf5to10)}
                      fullWidth
                      helperText={touched.msf5to10 && errors.msf5to10}
                      label='Msf&gt;=5&lt;10'
                      name='msf5to10'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.msf5to10}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.msf10to15 && errors.msf10to15)}
                      fullWidth
                      helperText={touched.msf10to15 && errors.msf10to15}
                      label='Msf&gt;=10&lt;15'
                      name='msf10to15'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.msf10to15}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.msf15to30 && errors.msf15to30)}
                      fullWidth
                      helperText={touched.msf15to30 && errors.msf15to30}
                      label='Msf&gt;=15&lt;30'
                      name='msf15to30'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.msf15to30}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.msf30to45 && errors.msf30to45)}
                      fullWidth
                      helperText={touched.msf30to45 && errors.msf30to45}
                      label='Msf&gt;=30&lt;45'
                      name='msf30to45'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.msf30to45}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.msf45to60 && errors.msf45to60)}
                      fullWidth
                      helperText={touched.msf45to60 && errors.msf45to60}
                      label='Msf&gt;=45&lt;60'
                      name='msf45to60'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.msf45to60}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.msf60to75 && errors.msf60to75)}
                      fullWidth
                      helperText={touched.msf60to75 && errors.msf60to75}
                      label='Msf&gt;=60'
                      name='msf60to75'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.msf60to75}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isActive}
                          disabled={isSubmitting}
                          name='isActive'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.isActive}
                        />
                      }
                      label='Active'
                    />
                  </Grid>
                  {/* ACCORDION NOTES CARD */}
                  {/* ACCORDION NOTES CARD */}
                  {/* ACCORDION NOTES CARD */}
                  <Grid item xs={12}>
                    {/* <ExplanationAccordion>
            	      xxxxxxxxxxxxxxxxxxx||xxxxxxxxxxxxxxxxxxx 
             	   </ExplanationAccordion> */}
                    <ExplanationAccordion development>
                      Board drop down is hardcoded because the quote builder requires
                      specific board selection for blend.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button variant='text' color='secondary' onClick={onClose}>
                  CLOSE
                </Button>

                <Button
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
