import {
  Box,
  CSSObject,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  SxProps,
  Theme,
  styled,
  useMediaQuery
} from '@mui/material'
import { useAuthContext } from 'context'
import { PropsWithChildren, useEffect } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

import { Inventory, QueryStats } from '@mui/icons-material'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import Business from '@mui/icons-material/Business'
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard'
import GradingIcon from '@mui/icons-material/Grading'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import MoveUpIcon from '@mui/icons-material/MoveUp'
import People from '@mui/icons-material/People'
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual'
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import WrapTextIcon from '@mui/icons-material/WrapText'
import { baseTheme } from 'theme/baseTheme'

// ADD MENU ITEM USING TEMPLATE FILE (THIS COMMENT IS USED FOR SEARCHING DO NOT REMOVE PER DKS)

const openedMixin = (theme: Theme): CSSObject => ({
  width: baseTheme.drawer.width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    width: baseTheme.drawer.width,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
)

function MenuItem({ children, ...rest }: PropsWithChildren<NavLinkProps & SxProps>) {
  return (
    /* @ts-ignore */
    <ListItemButton
      selected={window.location.href.includes(rest.to.toString())}
      component={NavLink}
      {...rest}
    >
      {children}
    </ListItemButton>
  )
}

export type NavProps = {
  isLearningNavigationOpen: boolean
  onOpenMenu: () => void
  onToggleLearningNavigation: () => void
}

function Nav({
  isLearningNavigationOpen,
  onOpenMenu,
  onToggleLearningNavigation
}: NavProps) {
  const {
    BANDING_ADD_EDIT,
    BIG_JACK_TO_DOS_ADD_EDIT,
    COMPANIES_AND_PEOPLE_ADD_EDIT,
    COMPANIES_AND_PEOPLE_READ_ONLY,
    CUSTOMER_ORDERS_REPORT,
    FINISHED_GOODS_INVENTORY_ADD_EDIT,
    FINISHED_GOODS_INVENTORY_ADJUSTMENTS_REPORT,
    FINISHED_GOODS_INVENTORY_STOCK_REPORT,
    FORKLIFT_TO_DOS_ADD_EDIT,
    JOBS_SCHEDULING_ADD_EDIT,
    LITTLE_JACK_TO_DOS_ADD_EDIT,
    OPPORTUNITIES_ADD_EDIT,
    OPPORTUNITIES_READ_ONLY,
    RATES_ADD_EDIT,
    RAW_INVENTORY_ADD_EDIT,
    RAW_INVENTORY_ADJUSTMENTS_REPORT,
    RAW_INVENTORY_STOCK_REPORT,
    RECEIVING_ADD_EDIT,
    RECEIVING_REPORT,
    SHIPPING_ADD_EDIT,
    SHIPPING_REPORT,
    VENDOR_ORDERS_ADD_EDIT,
    VENDOR_ORDERS_REPORT,
    VENDOR_USAGE_REPORT
  } = useAuthContext()

  return (
    <List sx={{ border: 'none' }}>
      {(OPPORTUNITIES_ADD_EDIT || OPPORTUNITIES_READ_ONLY) && (
        <MenuItem to='/opportunities'>
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText primary='Opportunities' />
        </MenuItem>
      )}

      {VENDOR_ORDERS_ADD_EDIT && (
        <MenuItem to='/vendor-orders'>
          <ListItemIcon>
            <GradingIcon />
          </ListItemIcon>
          <ListItemText primary='Vendor Orders' />
        </MenuItem>
      )}

      {RECEIVING_ADD_EDIT && (
        <MenuItem to='/receiving'>
          <ListItemIcon>
            <QrCodeScannerIcon />
          </ListItemIcon>
          <ListItemText primary='Receiving' />
        </MenuItem>
      )}

      {RAW_INVENTORY_ADD_EDIT && (
        <MenuItem to='/raw-inventory'>
          <ListItemIcon>
            <Inventory />
          </ListItemIcon>
          <ListItemText primary='Raw Inventory' />
        </MenuItem>
      )}

      {JOBS_SCHEDULING_ADD_EDIT && (
        <MenuItem to='/job-scheduling'>
          <ListItemIcon>
            <WorkHistoryIcon />
          </ListItemIcon>
          <ListItemText primary='Jobs / Scheduling' />
        </MenuItem>
      )}

      {FORKLIFT_TO_DOS_ADD_EDIT && (
        <MenuItem to='/forklift-to-dos'>
          <ListItemIcon>
            <MoveUpIcon />
          </ListItemIcon>
          <ListItemText primary='Forklift To Dos' />
        </MenuItem>
      )}

      {LITTLE_JACK_TO_DOS_ADD_EDIT && (
        <MenuItem to='/little-jack-to-dos'>
          <ListItemIcon>
            <PhotoSizeSelectLargeIcon />
          </ListItemIcon>
          <ListItemText primary='Little Jack To Dos' />
        </MenuItem>
      )}

      {BIG_JACK_TO_DOS_ADD_EDIT && (
        <MenuItem to='/big-jack-to-dos'>
          <ListItemIcon>
            <PhotoSizeSelectActualIcon />
          </ListItemIcon>
          <ListItemText primary='Big Jack To Dos' />
        </MenuItem>
      )}

      {BANDING_ADD_EDIT && (
        <MenuItem to='/banding'>
          <ListItemIcon>
            <WrapTextIcon />
          </ListItemIcon>
          <ListItemText primary='Banding' />
        </MenuItem>
      )}

      {FINISHED_GOODS_INVENTORY_ADD_EDIT && (
        <MenuItem to='/finished-goods-inventory'>
          <ListItemIcon>
            <WarehouseIcon />
          </ListItemIcon>
          <ListItemText primary='Finished Goods Inventory' />
        </MenuItem>
      )}

      {SHIPPING_ADD_EDIT && (
        <MenuItem to='/shipping'>
          <ListItemIcon>
            <DepartureBoardIcon />
          </ListItemIcon>
          <ListItemText primary='Shipping' />
        </MenuItem>
      )}

      {/* 
        <MenuItem to='/work-orders'>
          <ListItemIcon>
            <HomeRepairServiceIcon />
          </ListItemIcon>
          <ListItemText primary='Work Orders' />
        </MenuItem> */}

      {/* <MenuItem to='/vehicles'>
          <ListItemIcon>
            <LocalShippingIcon />
          </ListItemIcon>
          <ListItemText primary='Vehicles' />
        </MenuItem> */}

      {/* <ListItemButton
        aria-label={
          isLearningNavigationOpen
            ? 'Collapse Learning Categories'
            : 'Open Learning Categories'
        }
        onClick={e => {
          !isLearningNavigationOpen && onOpenMenu()
          onToggleLearningNavigation()
        }}
      >
        <ListItemIcon>
          <LocalLibraryIcon />
        </ListItemIcon>
        <ListItemText primary='Learning' />
        {isLearningNavigationOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={isLearningNavigationOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          <MenuItem to='/courses' sx={{ pl: 5 }}>
            <ListItemIcon>
              <CircleIcon sx={{ fontSize: 8 }} />
            </ListItemIcon>
            <ListItemText primary='Course Administration' />
          </MenuItem>
        </List>
      </Collapse> */}

      {(COMPANIES_AND_PEOPLE_ADD_EDIT || COMPANIES_AND_PEOPLE_READ_ONLY) && (
        <MenuItem to='/people'>
          <ListItemIcon>
            <People />
          </ListItemIcon>
          <ListItemText primary='People' />
        </MenuItem>
      )}

      {(COMPANIES_AND_PEOPLE_ADD_EDIT || COMPANIES_AND_PEOPLE_READ_ONLY) && (
        <MenuItem to='/companies'>
          <ListItemIcon>
            <Business />
          </ListItemIcon>
          <ListItemText primary='Companies' />
        </MenuItem>
      )}

      {RATES_ADD_EDIT && (
        <MenuItem to='/rates'>
          <ListItemIcon>
            <MonetizationOnIcon />
          </ListItemIcon>
          <ListItemText primary='Rates' />
        </MenuItem>
      )}

      {(CUSTOMER_ORDERS_REPORT ||
        FINISHED_GOODS_INVENTORY_ADJUSTMENTS_REPORT ||
        FINISHED_GOODS_INVENTORY_STOCK_REPORT ||
        RAW_INVENTORY_ADJUSTMENTS_REPORT ||
        RAW_INVENTORY_STOCK_REPORT ||
        RECEIVING_REPORT ||
        SHIPPING_REPORT ||
        VENDOR_ORDERS_REPORT ||
        VENDOR_USAGE_REPORT) && (
        <MenuItem to='/reports'>
          <ListItemIcon>
            <QueryStats />
          </ListItemIcon>
          <ListItemText primary='Reports' />
        </MenuItem>
      )}
    </List>
  )
}

export type MenuProps = {
  isLearningNavigationOpen: boolean
  isMenuOpen: boolean
  onCloseMenu: () => void
  onOpenMenu: () => void
  onToggleLearningNavigation: () => void
}

export default function Menu({
  isLearningNavigationOpen,
  isMenuOpen,
  onCloseMenu,
  onOpenMenu,
  onToggleLearningNavigation
}: MenuProps) {
  const isMobileWidth = useMediaQuery(baseTheme.breakpoints.down('sm'))

  useEffect(() => {
    if (isMobileWidth) {
      // If the menu is "open" (fully expanded on non mobile displays,) and the user changes resolutions or scrolls in,
      // make sure to close the menu so the drawer doesn't randomly appear.
      onCloseMenu()
    }
  }, [isMobileWidth])

  return isMobileWidth ? (
    <MuiDrawer
      anchor='left'
      variant={'temporary'}
      open={isMenuOpen}
      onClose={onCloseMenu}
      sx={{
        zIndex: theme => theme.zIndex.drawer,
        width: baseTheme.drawer.width
      }}
    >
      {/* This Box prevents the drawer from overlapping with the header (AppBar) */}
      <Box sx={theme => ({ ...theme.mixins.toolbar })} />
      <Nav
        isLearningNavigationOpen={isLearningNavigationOpen}
        onOpenMenu={onOpenMenu}
        onToggleLearningNavigation={onToggleLearningNavigation}
      />
    </MuiDrawer>
  ) : (
    <Drawer
      anchor='left'
      variant={'permanent'}
      open={isMenuOpen}
      onClose={onCloseMenu}
      sx={{
        zIndex: theme => theme.zIndex.drawer
      }}
    >
      {/* This Box prevents the drawer from overlapping with the header (AppBar) */}
      <Box sx={theme => ({ ...theme.mixins.toolbar })} />
      <Nav
        isLearningNavigationOpen={isLearningNavigationOpen}
        onOpenMenu={onOpenMenu}
        onToggleLearningNavigation={onToggleLearningNavigation}
      />
    </Drawer>
  )
}
