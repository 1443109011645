import { ApiResponse, FinishedGoodsInventoryAdjustmentDto } from 'dtos'
import apiClient from '.'

export const createFinishedGoodsInventoryReconciliationAdjustment = (
  values: FinishedGoodsInventoryAdjustmentDto
) =>
  apiClient
    .post<ApiResponse<boolean>>(
      '/api/finished-goods-inventory-adjustments/reconcile',
      values
    )
    .then(({ data }) => data)

export const createFinishedGoodsInventoryScrapAdjustment = (
  values: FinishedGoodsInventoryAdjustmentDto
) =>
  apiClient
    .post<ApiResponse<boolean>>('/api/finished-goods-inventory-adjustments/scrap', values)
    .then(({ data }) => data)
