import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent
} from '@mui/material'
import React from 'react'
import Chip from './Chip'

interface StatusOption {
  label: string
  color: 'red' | 'green' | 'blue' | 'yellow' | 'purple' | 'gray'
  count?: number
  value: string
}

interface ChipStatusSelectProps {
  label: string
  onChange: (values: string[]) => void
  options: StatusOption[]
  value: string[]
}

export default function ChipStatusSelect({ label, onChange, options, value }: ChipStatusSelectProps) {
  const getStatusLabelString = (status: string) => {
    return (
      options.filter(option => option.value === status)[0].label +
      (options.filter(option => option.value === status)[0].count === 0
        ? ' (' + 0 + ')'
        : options.filter(option => option.value === status)[0].count
        ? ' (' + options.filter(option => option.value === status)[0].count + ')'
        : '')
    )
  }

  return (
    <FormControl fullWidth size='small' sx={{ minWidth: '110px' }}>
      <InputLabel /*id='departments-label'*/>{label}</InputLabel>
      <Select
        // labelId='departments-label'
        multiple
        fullWidth
        value={value}
        size='small'
        onChange={(event: SelectChangeEvent<string[]>, _child: React.ReactNode) => {
          if (Array.isArray(event.target.value)) {
            onChange(event.target.value)
          }
        }}
        input={<OutlinedInput label={label} fullWidth size='small' />}
        renderValue={selected =>
          selected.map(status => (
            <Chip variant={options.filter(option => option.value === status)[0].color}>{getStatusLabelString(status)}</Chip>
          ))
        }
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 48 * 4.5 + 8,
              width: 250
            }
          }
        }}
        sx={{
          minWidth: '100%'
        }}
      >
        {options.map(listOption => (
          <MenuItem key={listOption.value} value={listOption.value}>
            <Checkbox checked={value.indexOf(listOption.value) > -1} />

            <ListItemText primary={getStatusLabelString(listOption.value)} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
