import {
  PostAddOutlined,
  ReadMoreOutlined,
  RequestQuote,
  SearchOutlined
} from '@mui/icons-material'
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import * as api from 'api'
import { Chip, ChipStatusSelect, OpportunityAddDialog } from 'components'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import { List, ListHeaderCard, ListItemCard } from 'components/_template/list'
import { useAuthContext } from 'context'
import {
  OpportunityActivityDto,
  OpportunityDto,
  OpportunityNextActivityTypeDto,
  OpportunityProbabilityDto,
  OpportunityStatusDto,
  PersonDto
} from 'dtos'
import { useSnackbar } from 'notistack'
import { OpportunitiesParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDate, useDebounce } from 'utils'
import { errorHandling } from '../constants'
import ListFilters from 'components/_template/list/ListFilters'

const PERMISSION_UNEDITABLE_MESSAGE = 'Must have "Opportunities add/edit" permission'

/**
 * @param inputDate DateOnly input string.
 * @param trueOnEquals If `true`, values on the same day will be considered past due. **DEFAULT:** `false`
 * @returns boolean past due.
 */
function getIsPastDue(inputDate: string, trueOnEquals: boolean = false) {
  const date = new Date(inputDate + 'T00:00:00').setHours(0, 0, 0, 0)
  const rightNow = new Date().setHours(0, 0, 0, 0)

  return trueOnEquals ? date <= rightNow : date < rightNow
}

/**
 *
 * @param inputDate
 * @param relativeDays
 * @returns
 */
function getRelativeDate(inputDate: string, relativeDays: number = 6): string {
  const date = new Date(inputDate + 'T00:00:00')
  const currentDate = new Date()
  const oneDayInMs = 24 * 60 * 60 * 1000 // Number of milliseconds in a day

  // Calculate the difference in days
  const timeDiffInDays = Math.floor((currentDate.getTime() - date.getTime()) / oneDayInMs)

  if (timeDiffInDays === 0) {
    return 'Today'
  } else if (timeDiffInDays === 1) {
    return 'Yesterday'
  } else if (timeDiffInDays === -1) {
    return 'Tomorrow'
  } else if (timeDiffInDays >= -relativeDays && timeDiffInDays <= relativeDays) {
    const relativeDays =
      timeDiffInDays < 0
        ? `${Math.abs(timeDiffInDays)} days from now`
        : `${timeDiffInDays} days ago`
    return relativeDays
  } else {
    // If the date is not within the last 6 days, return m/d/yyyy format
    const month = date.getMonth() + 1 // Months are 0-based in JavaScript Date
    const day = date.getDate()
    const year = date.getFullYear()
    return `${month}/${day}/${year}`
  }
}

export default function Opportunities() {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { user, OPPORTUNITIES_ADD_EDIT, SALESPERSON } = useAuthContext()

  const initialStatuses = [
    'includeOpportunity',
    'includeInternalReview',
    'includeSendQuote',
    'includeQuoteApproved'
  ]
  const initialNextActivityTypeOptions = [
    'includeFaceToFaceMeeting',
    'includePhoneCall',
    'includeEmail',
    'includeText'
  ]

  const [addActivityDialogOpen, setAddActivityDialogOpen] = useState<boolean>(false)
  const [count, setCount] = useState<number>(0)
  const [filterField, setFilterField] = useState<string>('')
  const [filterValue, setFilterValue] = useState<string>('')
  const [nextActivityDateFrom, setNextActivityDateFrom] = useState<string>('')
  const [nextActivityDateTo, setNextActivityDateTo] = useState<string>('')
  const [isGettingOpportunities, setIsGettingOpportunities] = useState<boolean>(false)
  const [isGettingOpportunityActivityTypes, setIsGettingOpportunityActivityTypes] =
    useState<boolean>(false)
  const [
    isGettingOpportunityNextActivityTypes,
    setIsGettingOpportunityNextActivityTypes
  ] = useState<boolean>(false)
  const [isGettingOpportunityProbabilities, setIsGettingOpportunityProbabilities] =
    useState<boolean>(false)
  const [isGettingOpportunityStatuses, setIsGettingOpportunityStatuses] =
    useState<boolean>(false)
  const [isGettingPersonSalespeople, setIsGettingPersonSalespeople] =
    useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [opportunities, setOpportunities] = useState<OpportunityDto[]>([])
  const [opportunity, setOpportunity] = useState<OpportunityDto>(new OpportunityDto())
  const [opportunityActivityTypes, setOpportunityActivityTypes] = useState<
    OpportunityActivityDto[]
  >([])
  const [opportunityNextActivityTypes, setOpportunityNextActivityTypes] = useState<
    OpportunityNextActivityTypeDto[]
  >([])
  const [opportunityStatuses, setOpportunityStatuses] = useState<OpportunityStatusDto[]>(
    []
  )
  const [parameters, setParameters] = useState<OpportunitiesParameters>({
    statuses: initialStatuses,
    nextActivityTypeOptions: initialNextActivityTypeOptions,
    page: 0,
    pageSize: 10
  })
  const [salespeople, setSalespeople] = useState<PersonDto[]>([])
  const [opportunityProbabilities, setOpportunityProbabilities] = useState<
    OpportunityProbabilityDto[]
  >([])

  useEffect(() => {
    setIsLoading(
      isGettingOpportunities ||
        isGettingOpportunityActivityTypes ||
        isGettingOpportunityNextActivityTypes ||
        isGettingOpportunityProbabilities ||
        isGettingOpportunityStatuses ||
        isGettingPersonSalespeople
    )
  }, [
    isGettingOpportunities,
    isGettingOpportunityActivityTypes,
    isGettingOpportunityNextActivityTypes,
    isGettingOpportunityProbabilities,
    isGettingOpportunityStatuses,
    isGettingPersonSalespeople
  ])

  useEffect(() => {
    setIsGettingOpportunityActivityTypes(true)
    api
      .getOpportunityActivityTypes({ page: 0, pageSize: 999 })
      .then(({ value }) => {
        setOpportunityActivityTypes(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingOpportunityActivityTypes(false)
      })

    setIsGettingOpportunityNextActivityTypes(true)
    api
      .getOpportunityNextActivityTypes({ page: 0, pageSize: 999 })
      .then(({ value }) => {
        setOpportunityNextActivityTypes(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingOpportunityNextActivityTypes(false)
      })

    setIsGettingOpportunityProbabilities(true)
    api
      .getOpportunityProbabilities({ page: 0, pageSize: 999 })
      .then(({ value }) => {
        setOpportunityProbabilities(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingOpportunityProbabilities(false)
      })

    setIsGettingOpportunityStatuses(true)
    api
      .getOpportunityStatuses({ page: 0, pageSize: 999 })
      .then(({ value }) => {
        setOpportunityStatuses(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingOpportunityStatuses(false)
      })

    setIsGettingPersonSalespeople(true)
    api
      .getPersonSalespeople()
      .then(res => {
        setSalespeople(res.value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingPersonSalespeople(false)
      })
  }, [])

  useEffect(() => {
    if (SALESPERSON) {
      setParameters({
        ...parameters,
        salespersonId: user!.id
      })
    }
  }, [user])

  useEffect(() => {
    getOpportunities(parameters)
  }, [parameters])

  const getOpportunities = useDebounce((parameters: OpportunitiesParameters) => {
    setIsGettingOpportunities(true)
    api
      .getOpportunities(parameters)
      .then(res => {
        setOpportunities(res.value)
        setCount(res.totalCount!)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingOpportunities(false)
      })
  }, 300)

  return (
    <Layout title='Opportunities' isLoading={isLoading}>
      <OpportunityAddDialog
        open={addActivityDialogOpen}
        onClose={() => {
          setAddActivityDialogOpen(false)
        }}
        onSave={values => {
          setIsLoading(true)
          api
            .addActivity({
              ...values,
              opportunity
            })
            .then(() => {
              enqueueSnackbar('Activity created successfully!', { variant: 'success' })
              getOpportunities(parameters)
              setAddActivityDialogOpen(false)
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              setIsLoading(false)
            })
        }}
        opportunity={opportunity}
        opportunityStatuses={opportunityStatuses}
        opportunityNextActivityTypes={opportunityNextActivityTypes}
        opportunityActivityTypes={opportunityActivityTypes}
        opportunityProbabilities={opportunityProbabilities}
      />

      <List
        pagination={{
          page: parameters.page,
          pageSize: parameters.pageSize,
          count,
          onPageChange: page => setParameters({ ...parameters, page }),
          onPageSizeChange: pageSize => setParameters({ ...parameters, pageSize })
        }}
        header={
          <ListHeaderCard
            title={'Opportunities (' + count + ')'}
            actions={[
              <Tooltip
                title={
                  OPPORTUNITIES_ADD_EDIT
                    ? 'Add opportunity'
                    : PERMISSION_UNEDITABLE_MESSAGE
                }
                placement='bottom'
              >
                <span>
                  <Button
                    disabled={!OPPORTUNITIES_ADD_EDIT}
                    onClick={() => {
                      navigate('/opportunities/new')
                    }}
                    variant='contained'
                    size='medium'
                  >
                    ADD NEW
                  </Button>
                </span>
              </Tooltip>
            ]}
          />
        }
      >
        <ListItemCard>
          <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                label='Salesperson'
                onChange={e => {
                  setParameters({
                    ...parameters,
                    salespersonId: e.target.value ?? undefined
                  })
                }}
                select
                SelectProps={{
                  displayEmpty: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
                size='small'
                value={parameters.salespersonId || ''}
              >
                <MenuItem value={''}>All</MenuItem>
                {salespeople
                  .sort((a, b) =>
                    `${a.firstName} ${a.lastName}`.localeCompare(
                      `${b.firstName} ${b.lastName}`
                    )
                  )
                  .map(salesperson => (
                    <MenuItem key={salesperson.id} value={salesperson.id}>
                      {salesperson.firstName} {salesperson.lastName}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={4}>
              <ChipStatusSelect
                label='Statuses'
                onChange={value => {
                  setParameters({
                    ...parameters,
                    statuses: value
                  })
                }}
                options={[
                  {
                    label: 'Opportunity',
                    color: 'green',
                    value: 'includeOpportunity'
                    // count: findStatusCount('includeOpportunity')
                  },
                  {
                    label: 'Internal Review',
                    color: 'yellow',
                    value: 'includeInternalReview'
                    // count: findStatusCount('includeInternalReview')
                  },
                  {
                    label: 'Send Quote',
                    color: 'red',
                    value: 'includeSendQuote'
                    // count: findStatusCount('includeSendQuote')
                  },
                  {
                    label: 'Quote Approved',
                    color: 'purple',
                    value: 'includeQuoteApproved'
                    // count: findStatusCount('includeQuoteApproved')
                  },
                  {
                    label: 'Sold',
                    color: 'blue',
                    value: 'includeSold'
                    // count: findStatusCount('includeSold')
                  },
                  {
                    label: 'Lost',
                    color: 'gray',
                    value: 'includeLost'
                    // count: findStatusCount('includeLost')
                  }
                ]}
                value={parameters.statuses ?? []}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <ChipStatusSelect
                label='Next Activity Types'
                onChange={value => {
                  setParameters({
                    ...parameters,
                    nextActivityTypeOptions: value
                  })
                }}
                options={[
                  {
                    label: 'Face To Face Meeting',
                    color: 'red',
                    value: 'includeFaceToFaceMeeting'
                    // count: findStatusCount('includeFaceToFaceMeeting')
                  },
                  {
                    label: 'Phone Call',
                    color: 'purple',
                    value: 'includePhoneCall'
                    // count: findStatusCount('includePhoneCall')
                  },
                  {
                    label: 'Email',
                    color: 'gray',
                    value: 'includeEmail'
                    // count: findStatusCount('includeEmail')
                  },
                  {
                    label: 'Text',
                    color: 'yellow',
                    value: 'includeText'
                    // count: findStatusCount('includeText')
                  }
                ]}
                value={parameters.nextActivityTypeOptions ?? []}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button
                color='primary'
                size='medium'
                variant='text'
                onClick={() => {
                  setParameters({
                    ...parameters,
                    page: 0,
                    statuses: initialStatuses,
                    nextActivityTypeOptions: initialNextActivityTypeOptions
                  })
                }}
              >
                RESET FILTERS
              </Button>
            </Grid>
          </Grid>
        </ListItemCard>

        <ListItemCard>
          <ListFilters
            applied={[
              {
                label: `Company Name contains "${parameters.filterCompanyName}"`,
                when: Boolean(parameters.filterCompanyName),
                onDelete: () => {
                  setParameters({ ...parameters, filterCompanyName: '' })
                }
              },
              {
                label: `Customer Part Number contains "${parameters.filterCustomerPartNumber}"`,
                when: Boolean(parameters.filterCustomerPartNumber),
                onDelete: () => {
                  setParameters({ ...parameters, filterCustomerPartNumber: '' })
                }
              },
              {
                label:
                  parameters.filterNextActivityDateFrom &&
                  !parameters.filterNextActivityDateTo
                    ? `Next Activity Date equals \"${parameters.filterNextActivityDateFrom}\"`
                    : !parameters.filterNextActivityDateFrom &&
                      parameters.filterNextActivityDateTo
                    ? `Next Activity Date less than or equal to \"${parameters.filterNextActivityDateTo}\"`
                    : `Next Activity Date equals or between \"${parameters.filterNextActivityDateFrom}\" and \"${parameters.filterNextActivityDateTo}\"`,
                when: Boolean(
                  parameters.filterNextActivityDateFrom ||
                    parameters.filterNextActivityDateTo
                ),
                onDelete: () => {
                  setParameters({
                    ...parameters,
                    filterNextActivityDateFrom: '',
                    filterNextActivityDateTo: ''
                  })
                }
              },
              {
                label: `Opportunity Description contains "${parameters.filterOpportunityDescription}"`,
                when: Boolean(parameters.filterOpportunityDescription),
                onDelete: () => {
                  setParameters({ ...parameters, filterOpportunityDescription: '' })
                }
              },
              {
                label: `Opportunity Number contains "${parameters.filterOpportunityNumber}"`,
                when: Boolean(parameters.filterOpportunityNumber),
                onDelete: () => {
                  setParameters({ ...parameters, filterOpportunityNumber: '' })
                }
              },
              {
                label: `Person Name contains"${parameters.filterPersonName}"`,
                when: Boolean(parameters.filterPersonName),
                onDelete: () => {
                  setParameters({ ...parameters, filterPersonName: '' })
                }
              },
              {
                label: `Person Email contains "${parameters.filterPersonEmail}"`,
                when: Boolean(parameters.filterPersonEmail),
                onDelete: () => {
                  setParameters({ ...parameters, filterPersonEmail: '' })
                }
              },
              {
                label: `Person Mobile Phone contains "${parameters.filterPersonMobilePhone}"`,
                when: Boolean(parameters.filterPersonMobilePhone),
                onDelete: () => {
                  setParameters({ ...parameters, filterPersonMobilePhone: '' })
                }
              },
              {
                label: `SKU# contains "${parameters.filterSkuNumber}"`,
                when: Boolean(parameters.filterSkuNumber),
                onDelete: () => {
                  setParameters({ ...parameters, filterSkuNumber: '' })
                }
              }
            ]}
          >
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label='Filter Field'
                  margin='dense'
                  onChange={e => {
                    setFilterField(e.target.value)
                    setFilterValue('')
                    setNextActivityDateFrom('')
                    setNextActivityDateTo('')
                  }}
                  select
                  size='small'
                  sx={{ minWidth: 200 }}
                  value={filterField || ''}
                >
                  <MenuItem value='filterCompanyName'>Company Name</MenuItem>

                  <MenuItem value='filterCustomerPartNumber'>
                    Customer Part Number
                  </MenuItem>

                  <MenuItem value='filterNextActivityDate'>Next Activity Date</MenuItem>

                  <MenuItem value='filterOpportunityDescription'>
                    Opportunity Description
                  </MenuItem>

                  <MenuItem value='filterOpportunityNumber'>Opportunity Number</MenuItem>

                  <MenuItem value='filterPersonName'>Person Name</MenuItem>

                  <MenuItem value='filterPersonEmail'>Person Email</MenuItem>

                  <MenuItem value='filterPersonMobilePhone'>Person Mobile Phone</MenuItem>

                  <MenuItem value='filterSkuNumber'>SKU#</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={2}>
                {[
                  '',
                  'filterCompanyName',
                  'filterCustomerPartNumber',
                  'filterOpportunityDescription',
                  'filterOpportunityNumber',
                  'filterPersonName',
                  'filterPersonEmail',
                  'filterPersonMobilePhone',
                  'filterSkuNumber'
                ].includes(filterField) && (
                  <TextField
                    fullWidth
                    disabled={!filterField}
                    label='Filter Value'
                    margin='dense'
                    onChange={e => {
                      setFilterValue(e.target.value)
                    }}
                    size='small'
                    value={filterValue || ''}
                  />
                )}

                {filterField === 'filterNextActivityDate' && (
                  <TextField
                    margin='dense'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label='Next Activity Date From'
                    name='nextActivityDateFrom'
                    onChange={e => {
                      setNextActivityDateFrom(e.target.value)
                    }}
                    size='small'
                    type='date'
                    value={nextActivityDateFrom || ''}
                  />
                )}
                {filterField === 'filterNextActivityDate' && (
                  <TextField
                    margin='dense'
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    label='Next Activity Date To'
                    name='nextActivityDateTo'
                    onChange={e => {
                      setNextActivityDateTo(e.target.value)
                    }}
                    size='small'
                    type='date'
                    value={nextActivityDateTo || ''}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={1}>
                <Button
                  color='inherit'
                  disabled={
                    !filterField ||
                    (!filterValue && !nextActivityDateFrom && !nextActivityDateTo)
                  }
                  size='medium'
                  variant='contained'
                  onClick={() => {
                    if (filterField === 'filterNextActivityDate') {
                      setParameters({
                        ...parameters,
                        filterNextActivityDateFrom: nextActivityDateFrom,
                        filterNextActivityDateTo: nextActivityDateTo
                      })
                    } else {
                      setParameters({
                        ...parameters,
                        [filterField]: filterValue
                      })
                    }

                    setFilterField('')
                    setFilterValue('')
                    setNextActivityDateFrom('')
                    setNextActivityDateTo('')
                  }}
                >
                  APPLY FILTER
                </Button>
              </Grid>

              <Grid item xs={12} sm={1}>
                <Button
                  color='primary'
                  size='medium'
                  variant='text'
                  onClick={() => {
                    setParameters({
                      ...parameters,
                      page: 0,
                      filterCompanyName: '',
                      filterCustomerPartNumber: '',
                      filterNextActivityDateFrom: '',
                      filterNextActivityDateTo: '',
                      filterOpportunityDescription: '',
                      filterOpportunityNumber: '',
                      filterPersonName: '',
                      filterPersonEmail: '',
                      filterPersonMobilePhone: '',
                      filterSkuNumber: ''
                    })

                    setFilterField('')
                    setFilterValue('')
                    setNextActivityDateFrom('')
                    setNextActivityDateTo('')
                  }}
                >
                  RESET FILTERS
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </Grid>
          </ListFilters>
        </ListItemCard>

        {opportunities.map(opportunity => (
          <ListItemCard
            key={opportunity.id}
            // TODO: Update other components to use the same <Chip> as Opportunities.tsx for consistency
            chips={[
              ...[
                opportunity.opportunityActivity![0].opportunityStatus
                  ?.specialIdentifier === 'OPPORTUNITY_STATUS_OPPORTUNITY' && (
                  <Grid item>
                    <Chip variant='green'>Opportunity</Chip>
                  </Grid>
                ),

                opportunity.opportunityActivity![0].opportunityStatus
                  ?.specialIdentifier === 'OPPORTUNITY_STATUS_INTERNAL_REVIEW' && (
                  <Grid item>
                    <Chip variant='yellow'>Internal Review</Chip>
                  </Grid>
                ),

                opportunity.opportunityActivity![0].opportunityStatus
                  ?.specialIdentifier === 'OPPORTUNITY_STATUS_SEND_QUOTE' && (
                  <Grid item>
                    <Chip variant='red'>Send Quote</Chip>
                  </Grid>
                ),

                opportunity.opportunityActivity![0].opportunityStatus
                  ?.specialIdentifier === 'OPPORTUNITY_STATUS_QUOTE_APPROVED' && (
                  <Grid item>
                    <Chip variant='purple'>Quote Approved</Chip>
                  </Grid>
                ),

                opportunity.opportunityActivity![0].opportunityStatus
                  ?.specialIdentifier === 'OPPORTUNITY_STATUS_SOLD' && (
                  <Grid item>
                    <Chip variant='blue'>Sold</Chip>
                  </Grid>
                ),

                opportunity.opportunityActivity![0].opportunityStatus
                  ?.specialIdentifier === 'OPPORTUNITY_STATUS_LOST' && (
                  <Grid item>
                    <Chip variant='gray'>Lost</Chip>
                  </Grid>
                ),

                opportunity.opportunityActivity![0].opportunityNextActivityType
                  ?.specialIdentifier ===
                  'OPPORTUNITY_NEXT_ACTIVITY_TYPE_FACE_TO_FACE_MEETING' && (
                  <Grid item>
                    <Chip variant='red'>Face to Face Meeting</Chip>
                  </Grid>
                ),

                opportunity.opportunityActivity![0].opportunityNextActivityType
                  ?.specialIdentifier === 'OPPORTUNITY_NEXT_ACTIVITY_TYPE_PHONE_CALL' && (
                  <Grid item>
                    <Chip variant='purple'>Phone Call</Chip>
                  </Grid>
                ),

                opportunity.opportunityActivity![0].opportunityNextActivityType
                  ?.specialIdentifier === 'OPPORTUNITY_NEXT_ACTIVITY_TYPE_EMAIL' && (
                  <Grid item>
                    <Chip variant='gray'>Email</Chip>
                  </Grid>
                ),

                opportunity.opportunityActivity![0].opportunityNextActivityType
                  ?.specialIdentifier === 'OPPORTUNITY_NEXT_ACTIVITY_TYPE_TEXT' && (
                  <Grid item>
                    <Chip variant='yellow'>Text</Chip>
                  </Grid>
                )
              ]
            ]}
            title={
              opportunity.opportunityActivity![0].opportunityStatus?.specialIdentifier ===
              'OPPORTUNITY_STATUS_SOLD'
                ? `Sold on ${formatDate(
                    opportunity.opportunityActivity![0].activityDate!,
                    true
                  )}`
                : opportunity.opportunityActivity![0].opportunityStatus
                    ?.specialIdentifier === 'OPPORTUNITY_STATUS_LOST'
                ? `Lost on ${formatDate(
                    opportunity.opportunityActivity![0].activityDate!,
                    true
                  )}`
                : opportunity.opportunityActivity![0].nextActivityDate &&
                  getRelativeDate(opportunity.opportunityActivity![0].nextActivityDate)
            }
            titleColor={
              getIsPastDue(opportunity.opportunityActivity![0].nextActivityDate!)
                ? 'error'
                : 'inherit'
            }
            actions={[
              ...(opportunity.quote
                ? [
                    <Tooltip title='View Quote'>
                      <IconButton
                        onClick={() => navigate('/quotes/' + opportunity.quote!.id)}
                        color='primary'
                      >
                        <RequestQuote fontSize='large' />
                      </IconButton>
                    </Tooltip>
                  ]
                : []),
              <Tooltip
                title={`View Opportunity #${opportunity.displayOpportunityNumber}`}
              >
                <IconButton
                  onClick={() => navigate('/opportunities/' + opportunity.id)}
                  color='primary'
                >
                  <ReadMoreOutlined fontSize='large' />
                </IconButton>
              </Tooltip>,
              <Tooltip
                title={
                  OPPORTUNITIES_ADD_EDIT ? 'Add activity' : PERMISSION_UNEDITABLE_MESSAGE
                }
                placement='bottom'
              >
                <span>
                  <IconButton
                    disabled={
                      opportunity.opportunityActivity![0].opportunityStatus
                        ?.specialIdentifier === 'OPPORTUNITY_STATUS_SOLD' ||
                      !OPPORTUNITIES_ADD_EDIT
                    }
                    onClick={() => {
                      setOpportunity(opportunity)
                      setAddActivityDialogOpen(true)
                    }}
                    color='primary'
                  >
                    <PostAddOutlined fontSize='large' />
                  </IconButton>
                </span>
              </Tooltip>
            ]}
          >
            <Typography
              variant='h6'
              sx={{ fontWeight: 600 }}
              color={
                getIsPastDue(opportunity.opportunityActivity![0].nextActivityDate!)
                  ? 'error'
                  : 'primary'
              }
            >
              {opportunity.targetCompany?.name}
            </Typography>
            <Typography variant='h6' sx={{ color: '#7F7F7F' }}>
              {opportunity.targetPerson?.firstName} {opportunity.targetPerson?.lastName}
              {opportunity.targetPerson?.email && ` - ${opportunity.targetPerson.email}`}
              {opportunity.targetPerson?.mobilePhone &&
                ` - ${opportunity.targetPerson.mobilePhone}`}
            </Typography>

            <Typography variant='h6' sx={{ color: '#7F7F7F' }}>
              SKU #: {opportunity.quote?.quoteRevision?.quoteLineItem?.skuNumber}
            </Typography>

            <Typography variant='h6' sx={{ color: '#7F7F7F' }}>
              Customer Part Number:{' '}
              {opportunity.quote?.quoteRevision?.quoteLineItem?.customerPartNumber}
            </Typography>

            <Typography variant='h6' sx={{ color: '#7F7F7F' }}>
              Salesperson: {opportunity.salesperson?.firstName}{' '}
              {opportunity.salesperson?.lastName}
            </Typography>

            <Typography variant='h6' sx={{ color: '#7F7F7F' }}>
              Opportunity #: {opportunity.displayOpportunityNumber}
            </Typography>

            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              Opportunity Description: {opportunity.opportunityDescription}
            </Typography>
          </ListItemCard>
        ))}
      </List>
      <ExplanationAccordion>
        If the user who is signed into Spud ERP has the "Salesperson" feature access, the
        Salesperson dropdown defaults to their name. If the user who is signed in does not
        have "Salesperson" feature access, "All" is selected by default.||The statuses
        "Opportunity", "Internal Review", "Send Quote", and "Quote Approved" are selected
        by default.||All Next Activity Types (Email, Phone call, Face to face meeting, and
        Text) are selected by default.||If an Opportunity has a Next Activity Date of
        today or a date that is in the past, the Next Activity Date and Company name are
        displayed in red.||Opportunity screen cards are sorted by Next Activity Date
        oldest to newest.
      </ExplanationAccordion>
    </Layout>
  )
}
