import React from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

interface DecimalFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

export const DecimalFormat = React.forwardRef<NumericFormatProps, DecimalFormatProps>(
  function DecimalFormat(props, ref) {
    const { onChange, ...other } = props

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          })
        }}
        allowNegative={false}
        decimalScale={4}
      />
    )
  }
)

interface IntFormatProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

export const IntFormat = React.forwardRef<NumericFormatProps, IntFormatProps>(
  function IntFormat(props, ref) {
    const { onChange, ...other } = props

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value
            }
          })
        }}
        allowNegative={false}
        decimalScale={0}
      />
    )
  }
)
