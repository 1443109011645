import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { DuplicateQuoteDto, QuoteDto, duplicateQuoteValidationSchema } from 'dtos'
import { Formik, getIn, setNestedObjectValues } from 'formik'
import { ExplanationAccordion } from './_template/accordion'
import { showFormErrorsPrompt } from './_template/form/FormErrorsPrompt'
import { useAuthContext } from 'context'
import { useEffect, useState } from 'react'

const OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE =
  'Quote cannot be duplicated to the current opportunity once the opportunity is sold'

interface Props {
  onClose: () => void
  onSave: (
    values: DuplicateQuoteDto,
    setSubmitting: (isSubmitting: boolean) => void
  ) => void
  open: boolean
  quote: QuoteDto
}

export default function QuoteAddDialog({ onClose, onSave, open, quote }: Props) {
  const { CAN_DUPLICATE_QUOTE, OPPORTUNITIES_ADD_EDIT } = useAuthContext()

  const [initialValues, setInitialValues] = useState<DuplicateQuoteDto>(
    new DuplicateQuoteDto()
  )

  useEffect(() => {
    setInitialValues({
      quoteId: quote?.id,
      opportunityToUse:
        quote?.opportunityLastStatus?.specialIdentifier === 'OPPORTUNITY_STATUS_SOLD'
          ? 'newOpportunity'
          : '',
      skuNumber: quote?.quoteRevision?.quoteLineItem?.skuNumber
    })
  }, [quote])

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values, setSubmitting)
        }}
        validateOnBlur
        validateOnChange
        validationSchema={duplicateQuoteValidationSchema}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Duplicate Quote Rev</DialogTitle>

                  <Grid item xs={12}>
                    <Typography
                      variant='body1'
                      color={
                        Boolean(
                          getIn(touched, 'opportunityToUse') &&
                            getIn(errors, 'opportunityToUse')
                        )
                          ? 'error'
                          : undefined
                      }
                      sx={
                        Boolean(
                          getIn(touched, 'opportunityToUse') &&
                            getIn(errors, 'opportunityToUse')
                        )
                          ? undefined
                          : {
                              color: '#7F7F7F'
                            }
                      }
                    >
                      <Tooltip
                        title={
                          quote?.opportunityLastStatus?.specialIdentifier ===
                          'OPPORTUNITY_STATUS_SOLD'
                            ? OPPORTUNITY_SOLD_UNEDITABLE_MESSAGE
                            : ''
                        }
                        placement='bottom'
                      >
                        <FormControl
                          error={Boolean(
                            getIn(touched, 'opportunityToUse') &&
                              getIn(errors, 'opportunityToUse')
                          )}
                        >
                          <RadioGroup
                            name='opportunityToUse'
                            row
                            value={values.opportunityToUse}
                          >
                            <FormControlLabel
                              disabled={
                                isSubmitting ||
                                !(OPPORTUNITIES_ADD_EDIT || CAN_DUPLICATE_QUOTE) ||
                                quote?.opportunityLastStatus?.specialIdentifier ===
                                  'OPPORTUNITY_STATUS_SOLD'
                              }
                              value='currentOpportunity'
                              onClick={e => {
                                // These radio buttons set the 'opportunityToUse' value to one of two hard-coded strings
                                setFieldValue('opportunityToUse', 'currentOpportunity')
                              }}
                              control={<Radio />}
                              label='Duplicate This Quote Rev to Current Opportunity'
                            />
                            <FormControlLabel
                              disabled={
                                isSubmitting ||
                                !(OPPORTUNITIES_ADD_EDIT || CAN_DUPLICATE_QUOTE) ||
                                quote?.opportunityLastStatus?.specialIdentifier ===
                                  'OPPORTUNITY_STATUS_SOLD'
                              }
                              value='newOpportunity'
                              onClick={e => {
                                // These radio buttons set the 'opportunityToUse' value to one of two hard-coded strings
                                setFieldValue('opportunityToUse', 'newOpportunity')
                              }}
                              control={<Radio />}
                              label='Duplicate This Quote Rev to New Opportunity'
                            />
                          </RadioGroup>
                          <FormHelperText>
                            {touched.opportunityToUse && errors.opportunityToUse}
                          </FormHelperText>
                        </FormControl>
                      </Tooltip>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting || !OPPORTUNITIES_ADD_EDIT}
                      error={Boolean(touched.skuNumber && errors.skuNumber)}
                      fullWidth
                      helperText={touched.skuNumber && errors.skuNumber}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            {quote.readonly_company?.internalExternal ? 'MC' : 'JP'}
                          </InputAdornment>
                        )
                      }}
                      label='SKU#'
                      name='skuNumber'
                      onBlur={handleBlur}
                      // This field is the only place that manages prepending input adornment, the back-end doesn't touch the prefix
                      onChange={e => {
                        setFieldValue(
                          'skuNumber',
                          (quote.readonly_company?.internalExternal ? 'MC' : 'JP') +
                            e.target.value
                        )
                      }}
                      // The field value here needs to trim off the input adornment prefix since it is already shown and prepended in the input adornment itself
                      value={values.skuNumber?.substring(2) || ''}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      If a user selects "Duplicate this quote rev to new opportunity", the
                      SKU# field will be prepopulated with the SKU# of the quote revision
                      the user is duplicating, but user must modify this value before
                      saving. In most cases, this will involve changing the REV# at the
                      end of the SKU# value. || If a user selects "Duplicate this quote
                      rev to new opportunity", a new Opportunity is created with the next
                      available Opportunity #. The Opportunity Details, Quote Details, and
                      Design Files (if uploaded) are duplicated to the new Opportunity,
                      but the Activity History is not. || If the Opportunity that the
                      quote is associated with is in "Sold" or "Lost" status, the user can
                      only select "Duplicate this quote rev to new opportunity". ||
                      Selecting either "Duplicate this quote rev to current opportunity"
                      or "Duplicate this quote rev to new opportunity" will copy the
                      current quote rev's Quote Details and pull the current values from
                      Rates. || When SAVE is pressed, a unique SKU# value check runs and
                      if the SKU# field contains a value already in use anywhere in the
                      system, a warning will be displayed, and the information will not be
                      saved. || When SAVE is pressed, if user selected "Duplicate this
                      quote rev to current opportunity", the dialog will close, and user
                      will see the new quote revision under the current opportunity. The
                      new rev will be editable, and any previous rev(s) will be read-only.
                      || When SAVE is pressed, if user selected "Duplicate this quote rev
                      to new opportunity" and the unique SKU# check passes, the dialog
                      will close, and user will see the new quote rev under the new
                      opportunity.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button
                  disabled={isSubmitting}
                  variant='text'
                  color='secondary'
                  onClick={onClose}
                >
                  CANCEL
                </Button>

                <Button
                  disabled={isSubmitting}
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
