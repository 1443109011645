export function formatTime(time: string): string {
  let timeParts = time.split(':')

  if (timeParts.length < 2) {
    return 'Invalid time'
  }

  let hours = parseInt(timeParts[0])
  let minutes = parseInt(timeParts[1])

  while (minutes > 60) {
    hours += 1
    minutes -= 60
  }

  if (hours > 23) {
    return 'Invalid time'
  }

  let meridiem = hours > 12 ? 'PM' : 'AM'

  return `${hours === 0 ? 12 : hours > 12 ? hours - 12 : hours}:${
    minutes < 10 ? '0' : ''
  }${minutes} ${meridiem}`
}
