import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  IconButtonProps,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { SyntheticEvent, useState } from 'react'
import EmailIcon from '@mui/icons-material/Email'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PhoneIcon from '@mui/icons-material/Phone'
import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import { blue, green, orange, pink } from '@mui/material/colors'
import { ExpandMore } from '@mui/icons-material'
import * as React from 'react'
import PreviewIcon from '@mui/icons-material/Preview'
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import { grey, lightBlue, red, yellow } from '@mui/material/colors'
import ListSubheader from '@mui/material/ListSubheader'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import DraftsIcon from '@mui/icons-material/Drafts'
import SendIcon from '@mui/icons-material/Send'
import ExpandLess from '@mui/icons-material/ExpandLess'
import StarBorder from '@mui/icons-material/StarBorder'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import ListAltIcon from '@mui/icons-material/ListAlt'
import ArticleIcon from '@mui/icons-material/Article'
import QuizIcon from '@mui/icons-material/Quiz'
import { Layout } from 'components/_template'
type DataProps = {
  Id: string
  CreatedDateTimeUtc: string
  WorkingRemote: boolean
  ManualInsert: boolean
}

type Props = {
  data: DataProps[]
}

export default function LearningCourses() {
  return <Layout title='Learning'>Learning</Layout>
}
