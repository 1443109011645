import { unstable_useBlocker as useBlocker } from 'react-router-dom'

export type UnsavedChangesPromptProps = {
  when: boolean
  message?: string
}

export default function UnsavedChangesPrompt({
  when,
  message = 'Are you sure you want to leave this page? You have made some changes that have not been saved yet. If you leave now, you will lose all unsaved progress.'
}: UnsavedChangesPromptProps) {
  useBlocker(() => {
    if (when) {
      return !window.confirm(message)
    }

    return false
  })

  return null
}
