import { LoadingButton } from '@mui/lab'
import { Button, Grid, TextField, Tooltip, Typography } from '@mui/material'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import Popper from '@mui/material/Popper'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import * as api from 'api'
import { OpportunityAddDialog } from 'components'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import {
  Details,
  DetailsActions,
  DetailsForm,
  DetailsHeaderCard,
  DetailsTab
} from 'components/_template/details'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import { ListItemCard } from 'components/_template/list'
import { useAuthContext } from 'context'
import {
  CompanyDto,
  OpportunityActivityTypeDto,
  OpportunityDto,
  OpportunityNextActivityTypeDto,
  OpportunityProbabilityDto,
  OpportunityStatusDto,
  PersonDto,
  opportunityValidationSchema
} from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ListChildComponentProps, VariableSizeList } from 'react-window'
import { formatDate, useDebounce } from 'utils'
import { errorHandling } from '../constants'

const PERMISSION_UNEDITABLE_MESSAGE = 'Must have "Opportunities add/edit" permission'
const LISTBOX_PADDING = 8

function renderRowCompany(props: ListChildComponentProps) {
  const { data, index, style } = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING
  }
  return (
    <Typography component='li' {...dataSet[0]} noWrap style={inlineStyle}>
      {`${dataSet[1].name}`}
    </Typography>
  )
}

function renderRowPerson(props: ListChildComponentProps) {
  const { data, index, style } = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING
  }
  return (
    <Typography component='li' {...dataSet[0]} noWrap style={inlineStyle}>
      {`${dataSet[1].firstName + ' ' + dataSet[1].lastName}`}
    </Typography>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponentCompany = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData: React.ReactChild[] = []
  ;(children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item)
      itemData.push(...(item.children || []))
    }
  )

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true
  })
  const itemCount = itemData.length
  // Make items 36 pixels tall on a "small" or larger screen, and 48 piels tall otherwise
  const itemSize = smUp ? 36 : 48

  // Only show a maximum of 8 items in the dropdown
  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemCount * itemSize
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          // Every item is the same height, so don't set the itemSize individually for each index
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRowCompany}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

// Adapter for react-window
const ListboxComponentPerson = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData: React.ReactChild[] = []
  ;(children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item)
      itemData.push(...(item.children || []))
    }
  )

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true
  })
  const itemCount = itemData.length
  // Make items 36 pixels tall on a "small" or larger screen, and 48 piels tall otherwise
  const itemSize = smUp ? 36 : 48

  // Only show a maximum of 8 items in the dropdown
  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemCount * itemSize
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          // Every item is the same height, so don't set the itemSize individually for each index
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRowPerson}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
})

export default function QuoteDetails() {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { id } = useParams()
  const { OPPORTUNITIES_ADD_EDIT } = useAuthContext()

  const getCompanyPeople = useDebounce((companyId: string) => {
    setIsCompanyPeopleLoading(true)
    api
      .getPersonCompanyPeopleByCompanyId(companyId)
      .then(res => {
        setPeople(res.value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsCompanyPeopleLoading(false)
      })
  }, 300)

  const [addActivityDialogOpen, setAddActivityDialogOpen] = useState<boolean>(false)
  const [companies, setCompanies] = useState<CompanyDto[]>([])
  const [initialValues, setInitialValues] = useState<OpportunityDto>(new OpportunityDto())
  const [isCompaniesLoading, setIsCompaniesLoading] = useState<boolean>(false)
  const [isCompanyPeopleLoading, setIsCompanyPeopleLoading] = useState<boolean>(false)
  const [isSalespeopleLoading, setIsSalespeopleLoading] = useState<boolean>(false)
  const [isOpportunityLoading, setIsOpportunityLoading] = useState<boolean>(false)
  const [opportunityActivityTypes, setOpportunityActivityTypes] = useState<
    OpportunityActivityTypeDto[]
  >([])
  const [opportunityNextActivityTypes, setOpportunityNextActivityTypes] = useState<
    OpportunityNextActivityTypeDto[]
  >([])
  const [opportunityStatuses, setOpportunityStatuses] = useState<OpportunityStatusDto[]>(
    []
  )
  const [opportunityProbabilities, setOpportunityProbabilities] = useState<
    OpportunityProbabilityDto[]
  >([])
  const [people, setPeople] = useState<PersonDto[]>([])
  const [salespeople, setSalespeople] = useState<PersonDto[]>([])

  useEffect(() => {
    setIsCompaniesLoading(true)
    api
      .getCompanyTargetCompanies()
      .then(({ value }) => {
        setCompanies(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsCompaniesLoading(false)
      })
    api
      .getOpportunityActivityTypes({ page: 0, pageSize: 999 })
      .then(({ value }) => {
        setOpportunityActivityTypes(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
    api
      .getOpportunityNextActivityTypes({ page: 0, pageSize: 999 })
      .then(({ value }) => {
        setOpportunityNextActivityTypes(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
    api
      .getOpportunityStatuses({ page: 0, pageSize: 999 })
      .then(({ value }) => {
        setOpportunityStatuses(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
    setIsSalespeopleLoading(true)
    api
      .getPersonSalespeople()
      .then(({ value }) => {
        setSalespeople(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsSalespeopleLoading(false)
      })
    api
      .getOpportunityProbabilities({ page: 0, pageSize: 999 })
      .then(({ value }) => {
        setOpportunityProbabilities(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
  }, [])

  useEffect(() => {
    if (initialValues.targetCompany) {
      getCompanyPeople(initialValues.targetCompany.id)
    }
  }, [initialValues])

  useEffect(() => {
    if (id && id.toLowerCase() !== 'new' && id.toLowerCase() !== 'undefined') {
      api
        .getOpportunityById(id)
        .then(({ value }) => {
          setInitialValues(value)
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
        .finally(() => {})
    } else {
      setInitialValues(new OpportunityDto())
    }
  }, [id])

  return (
    <Layout
      title='Opportunity Details'
      isLoading={
        isCompaniesLoading ||
        isCompanyPeopleLoading ||
        isOpportunityLoading ||
        isSalespeopleLoading
      }
    >
      <OpportunityAddDialog
        open={addActivityDialogOpen}
        onClose={() => {
          setAddActivityDialogOpen(false)
        }}
        onSave={values => {
          setIsOpportunityLoading(true)
          api
            .addActivity({
              ...values,
              opportunity: initialValues
            })
            .then(() => {
              enqueueSnackbar('Activity created successfully!', { variant: 'success' })

              api
                .getOpportunityById(id!)
                .then(({ value }) => {
                  setInitialValues(value)
                })
                .catch((errors: string[]) => {
                  errorHandling(errors)
                })
                .finally(() => {})

              setAddActivityDialogOpen(false)
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              setIsOpportunityLoading(false)
            })
        }}
        opportunity={initialValues}
        opportunityStatuses={opportunityStatuses}
        opportunityNextActivityTypes={opportunityNextActivityTypes}
        opportunityActivityTypes={opportunityActivityTypes}
        opportunityProbabilities={opportunityProbabilities}
      />

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnChange
        validateOnBlur
        validationSchema={opportunityValidationSchema}
        onSubmit={(values, formikHelpers) => {
          setIsOpportunityLoading(true)
          if (values.id) {
            api
              .updateOpportunity({
                ...values,
                opportunityActivity: []
              })
              .then(({ value }) => {
                enqueueSnackbar('Opportunity saved successfully!', { variant: 'success' })
                formikHelpers.resetForm({ values: value })
              })
              .catch((errors: string[]) => {
                errorHandling(errors)
              })
              .finally(() => {
                setIsOpportunityLoading(false)
              })
          } else {
            api
              .createOpportunity({
                ...values,
                opportunityActivity: []
              })
              .then(({ value }) => {
                enqueueSnackbar('Opportunity saved successfully!', { variant: 'success' })
                formikHelpers.resetForm({ values: value })

                setTimeout(() => {
                  navigate('/opportunities/' + value.id, { replace: true })
                }, 100)
              })
              .catch((errors: string[]) => {
                errorHandling(errors)
              })
              .finally(() => {
                setIsOpportunityLoading(false)
              })
          }
        }}
      >
        {({
          dirty,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <Details
              header={
                <DetailsHeaderCard
                  title={
                    values.displayOpportunityNumber
                      ? `Opportunity Details #${values.displayOpportunityNumber}`
                      : 'Opportunity Details'
                  }
                />
              }
              tabs={[
                { value: 'details', label: 'Details' },
                {
                  value: 'activity-history',
                  label: 'Activity History',
                  disabled: !values.id
                }
              ]}
              onSubmit={handleSubmit}
            >
              <DetailsTab value='details'>
                <DetailsForm>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Tooltip
                        title={
                          values.opportunityActivity &&
                          values.opportunityActivity[0]?.opportunityStatus
                            ?.specialIdentifier === 'OPPORTUNITY_STATUS_SOLD'
                            ? 'Opportunity cannot be edited once it is sold'
                            : OPPORTUNITIES_ADD_EDIT
                            ? ''
                            : PERMISSION_UNEDITABLE_MESSAGE
                        }
                        placement='bottom'
                      >
                        <Autocomplete
                          disabled={
                            !OPPORTUNITIES_ADD_EDIT ||
                            (values.opportunityActivity &&
                              values.opportunityActivity[0]?.opportunityStatus
                                ?.specialIdentifier === 'OPPORTUNITY_STATUS_SOLD')
                          }
                          id='targetCompany'
                          disableListWrap
                          getOptionLabel={option => option.name}
                          ListboxComponent={ListboxComponentCompany}
                          onChange={(_e, value) => {
                            if (value) {
                              getCompanyPeople(value.id)
                            }
                            setFieldValue('targetCompany', value)
                            setFieldValue('targetPerson', null)
                          }}
                          options={companies}
                          PopperComponent={StyledPopper}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label='Company'
                              error={Boolean(
                                touched.targetCompany && errors.targetCompany
                              )}
                              helperText={touched.targetCompany && errors.targetCompany}
                            />
                          )}
                          renderOption={(props, option, state) =>
                            [props, option, state.index] as React.ReactNode
                          }
                          // The Autocomplete is only blank when the value is null, NOT undefined, so this must be "values.targetCompany ?? null"
                          value={values.targetCompany ?? null}
                        />
                      </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                      <Tooltip
                        title={
                          values.opportunityActivity &&
                          values.opportunityActivity[0]?.opportunityStatus
                            ?.specialIdentifier === 'OPPORTUNITY_STATUS_SOLD'
                            ? 'Opportunity cannot be edited once it is sold'
                            : OPPORTUNITIES_ADD_EDIT
                            ? ''
                            : PERMISSION_UNEDITABLE_MESSAGE
                        }
                        placement='bottom'
                      >
                        <Autocomplete
                          disabled={
                            !OPPORTUNITIES_ADD_EDIT ||
                            (values.opportunityActivity &&
                              values.opportunityActivity[0]?.opportunityStatus
                                ?.specialIdentifier === 'OPPORTUNITY_STATUS_SOLD')
                          }
                          id='targetPerson'
                          disableListWrap
                          getOptionLabel={option =>
                            option.firstName + ' ' + option.lastName
                          }
                          ListboxComponent={ListboxComponentPerson}
                          onChange={(_e, value) => {
                            setFieldValue('targetPerson', value)
                          }}
                          options={people.filter(
                            person =>
                              person.company?.id === values.targetCompany?.id &&
                              person.company?.id
                          )}
                          PopperComponent={StyledPopper}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label='Person'
                              error={Boolean(touched.targetPerson && errors.targetPerson)}
                              helperText={touched.targetPerson && errors.targetPerson}
                            />
                          )}
                          renderOption={(props, option, state) =>
                            [props, option, state.index] as React.ReactNode
                          }
                          // The Autocomplete is only blank when the value is null, NOT undefined, so this must be "values.salesperson ?? null"
                          value={values.targetPerson ?? null}
                        />
                      </Tooltip>
                    </Grid>

                    {values.targetPerson?.email && (
                      <Grid item xs={12}>
                        <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                          Email: {values.targetPerson.email}
                        </Typography>
                      </Grid>
                    )}

                    {values.targetPerson?.mobilePhone && (
                      <Grid item xs={12}>
                        <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                          Phone: {values.targetPerson.mobilePhone}
                        </Typography>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Tooltip
                        title={
                          values.opportunityActivity &&
                          values.opportunityActivity[0]?.opportunityStatus
                            ?.specialIdentifier === 'OPPORTUNITY_STATUS_SOLD'
                            ? 'Opportunity cannot be edited once it is sold'
                            : OPPORTUNITIES_ADD_EDIT
                            ? ''
                            : PERMISSION_UNEDITABLE_MESSAGE
                        }
                        placement='bottom'
                      >
                        <TextField
                          disabled={
                            !OPPORTUNITIES_ADD_EDIT ||
                            (values.opportunityActivity &&
                              values.opportunityActivity[0]?.opportunityStatus
                                ?.specialIdentifier === 'OPPORTUNITY_STATUS_SOLD')
                          }
                          error={Boolean(
                            touched.opportunityName && errors.opportunityName
                          )}
                          fullWidth
                          helperText={touched.opportunityName && errors.opportunityName}
                          label='Project Name'
                          name='opportunityName'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.opportunityName || ''}
                        />
                      </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                      <Tooltip
                        title={
                          values.opportunityActivity &&
                          values.opportunityActivity[0]?.opportunityStatus
                            ?.specialIdentifier === 'OPPORTUNITY_STATUS_SOLD'
                            ? 'Opportunity cannot be edited once it is sold'
                            : OPPORTUNITIES_ADD_EDIT
                            ? ''
                            : PERMISSION_UNEDITABLE_MESSAGE
                        }
                        placement='bottom'
                      >
                        <TextField
                          disabled={
                            !OPPORTUNITIES_ADD_EDIT ||
                            (values.opportunityActivity &&
                              values.opportunityActivity[0]?.opportunityStatus
                                ?.specialIdentifier === 'OPPORTUNITY_STATUS_SOLD')
                          }
                          error={Boolean(
                            touched.opportunityDescription &&
                              errors.opportunityDescription
                          )}
                          fullWidth
                          helperText={
                            touched.opportunityDescription &&
                            errors.opportunityDescription
                          }
                          label='Opportunity Description'
                          maxRows={4}
                          minRows={4}
                          multiline
                          name='opportunityDescription'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.opportunityDescription || ''}
                        />
                      </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                      <Tooltip
                        title={
                          values.opportunityActivity &&
                          values.opportunityActivity[0]?.opportunityStatus
                            ?.specialIdentifier === 'OPPORTUNITY_STATUS_SOLD'
                            ? 'Opportunity cannot be edited once it is sold'
                            : OPPORTUNITIES_ADD_EDIT
                            ? ''
                            : PERMISSION_UNEDITABLE_MESSAGE
                        }
                        placement='bottom'
                      >
                        <Autocomplete
                          disabled={
                            !OPPORTUNITIES_ADD_EDIT ||
                            (values.opportunityActivity &&
                              values.opportunityActivity[0]?.opportunityStatus
                                ?.specialIdentifier === 'OPPORTUNITY_STATUS_SOLD')
                          }
                          id='salesperson'
                          disableListWrap
                          getOptionLabel={option =>
                            option.firstName + ' ' + option.lastName
                          }
                          ListboxComponent={ListboxComponentPerson}
                          onChange={(_e, value) => {
                            setFieldValue('salesperson', value)
                          }}
                          options={salespeople}
                          PopperComponent={StyledPopper}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label='Salesperson'
                              error={Boolean(touched.salesperson && errors.salesperson)}
                              helperText={touched.salesperson && errors.salesperson}
                            />
                          )}
                          renderOption={(props, option, state) =>
                            [props, option, state.index] as React.ReactNode
                          }
                          // The Autocomplete is only blank when the value is null, NOT undefined, so this must be "values.salesperson ?? null"
                          value={values.salesperson ?? null}
                        />
                      </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                      <ExplanationAccordion>
                        A company must be selected, but contact is optional.||Contact
                        dropdown will populate based on selection in the company
                        dropdown.||Email and Phone are populated based on the selected
                        Contact. These will be just labels without data until a contact is
                        selected.||Opportunity description and salesperson are required.||
                        Opportunity number is automatically incremented by 1.
                      </ExplanationAccordion>
                      <ExplanationAccordion development>
                        The first opportunity number in the system is 1000
                      </ExplanationAccordion>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsActions>
                        <Button
                          color='secondary'
                          onClick={() => {
                            navigate('/opportunities')
                          }}
                          variant='text'
                        >
                          BACK
                        </Button>

                        <Tooltip
                          title={
                            values.opportunityActivity &&
                            values.opportunityActivity[0]?.opportunityStatus
                              ?.specialIdentifier === 'OPPORTUNITY_STATUS_SOLD'
                              ? 'Opportunity cannot be edited once it is sold'
                              : OPPORTUNITIES_ADD_EDIT
                              ? ''
                              : PERMISSION_UNEDITABLE_MESSAGE
                          }
                          placement='bottom'
                        >
                          <span>
                            <LoadingButton
                              disabled={
                                !OPPORTUNITIES_ADD_EDIT ||
                                (values.opportunityActivity &&
                                  values.opportunityActivity[0]?.opportunityStatus
                                    ?.specialIdentifier === 'OPPORTUNITY_STATUS_SOLD')
                              }
                              color='primary'
                              variant='contained'
                              loading={isSubmitting}
                              onClick={() => {
                                // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                                // Otherwise, sometimes the field errors won't display even though the validation throws them
                                validateForm().then(errors => {
                                  const errorKeys = Object.keys(errors)
                                  if (errorKeys.length === 0) {
                                    submitForm()
                                  } else {
                                    setTouched(setNestedObjectValues(errors, true))
                                    showFormErrorsPrompt(errors)
                                  }
                                })
                              }}
                            >
                              {values.id ? 'UPDATE' : 'CREATE'} OPPORTUNITY
                            </LoadingButton>
                          </span>
                        </Tooltip>
                      </DetailsActions>
                    </Grid>
                  </Grid>
                </DetailsForm>
              </DetailsTab>

              <DetailsTab value='activity-history'>
                <Grid container spacing={2} alignItems='center'>
                  <Grid item container justifyContent='flex-end'>
                    <Grid item>
                      <Tooltip
                        title={
                          values.opportunityActivity &&
                          values.opportunityActivity[0]?.opportunityStatus
                            ?.specialIdentifier === 'OPPORTUNITY_STATUS_SOLD'
                            ? 'Opportunity cannot be edited once it is sold'
                            : OPPORTUNITIES_ADD_EDIT
                            ? ''
                            : PERMISSION_UNEDITABLE_MESSAGE
                        }
                        placement='bottom'
                      >
                        <span>
                          <Button
                            disabled={
                              !OPPORTUNITIES_ADD_EDIT ||
                              (values.opportunityActivity &&
                                values.opportunityActivity[0]?.opportunityStatus
                                  ?.specialIdentifier === 'OPPORTUNITY_STATUS_SOLD')
                            }
                            onClick={() => {
                              setAddActivityDialogOpen(true)
                            }}
                          >
                            ADD ACTIVITY
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>
                  </Grid>

                  {values.opportunityActivity?.map(opportunityActivity => (
                    <Grid item xs={12}>
                      <ListItemCard
                        title={formatDate(opportunityActivity.activityDate!, true)}
                        subtitle={opportunityActivity.opportunityStatus?.name}
                        variant='outlined'
                      >
                        <Typography variant='body2'>
                          {opportunityActivity.opportunityActivityType?.name} -{' '}
                          {opportunityActivity.activityNote}
                        </Typography>

                        <Typography variant='body2'>
                          Created By: {opportunityActivity.READONLY_createdBy?.firstName}{' '}
                          {opportunityActivity.READONLY_createdBy?.lastName}
                        </Typography>
                      </ListItemCard>
                    </Grid>
                  ))}
                </Grid>
              </DetailsTab>
            </Details>
          )
        }}
      </Formik>
    </Layout>
  )
}
