import { Grid } from '@mui/material'
import React, { Children } from 'react'

export default function DetailsActions({ children }: React.PropsWithChildren) {
  return (
    <Grid container spacing={2} alignItems='center' justifyContent='flex-end'>
      {Children.map(children, (child, index) => (
        <Grid item key={index}>
          {child}
        </Grid>
      ))}
    </Grid>
  )
}
