import * as yup from 'yup'
import {
  fieldTypeMessage,
  maxIntMessage,
  maxIntValue,
  minIntMessage,
  minIntValue,
  requiredFieldMessage
} from '.././constants'

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class BandingDto {
  jobNumber?: number = undefined
  bundleNumber?: number = undefined
  quantity?: number = undefined
}

export const bandingScanBarcodeValidationSchema = yup.object<BandingDto>({
  jobNumber: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .required(requiredFieldMessage)
    .min(minIntValue, minIntMessage)
    .max(maxIntValue, maxIntMessage),
  bundleNumber: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .required(requiredFieldMessage)
    .min(minIntValue, minIntMessage)
    .max(maxIntValue, maxIntMessage),
  quantity: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .required(requiredFieldMessage)
    .min(minIntValue, minIntMessage)
    .max(maxIntValue, maxIntMessage)
})
