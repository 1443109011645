import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material'
import { LearningCourseSectionDto } from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import * as yup from 'yup'
import { showFormErrorsPrompt } from './_template/form/FormErrorsPrompt'

interface LearningCourseSectionAddEditDialogProps {
  learningCourseSection: LearningCourseSectionDto
  onClose: () => void
  onSave: (values: LearningCourseSectionDto) => void
  open: boolean
}

export default function LearningCourseSectionAddEditDialog({
  learningCourseSection,
  onClose,
  onSave,
  open
}: LearningCourseSectionAddEditDialogProps) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={learningCourseSection}
        validateOnBlur
        validateOnChange
        validationSchema={yup.object({})}
        onSubmit={values => {
          onSave(values)
        }}
      >
        {({
          dirty,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Add Learning Section</DialogTitle>

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.title && errors.title)}
                      fullWidth
                      helperText={touched.title && errors.title}
                      label='Title'
                      name='title'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.title}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      helperText={touched.description && errors.description}
                      label='Description'
                      maxRows={4}
                      minRows={4}
                      multiline
                      name='description'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button variant='text' color='secondary' onClick={onClose}>
                  CLOSE
                </Button>

                <Button
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
