import { CssBaseline } from '@mui/material'
import { AuthProvider, ThemeProvider, useAuthContext } from 'context'
import { SnackbarProvider } from 'notistack'
import {
  Banding,
  BigJackToDoDetails,
  BigJackToDos,
  Blank,
  Companies,
  CompanyDetails,
  CourseDetails,
  Courses,
  FinishedGoodsInventory,
  FinishedGoodsInventoryDetails,
  ForgotPassword,
  ForkliftToDos,
  RawInventory,
  RawInventoryDetails,
  JobScheduling,
  JobSchedulingDetails,
  Learning,
  LittleJackToDoDetails,
  LittleJackToDos,
  MaterialCoordinator,
  MaterialCoordinatorDetails,
  Opportunities,
  OpportunityDetails,
  People,
  PersonDetails,
  QuoteDetails,
  Rates,
  Receiving,
  ResetPassword,
  Shipping,
  ShippingDetails,
  SignIn,
  VehicleDetails,
  Vehicles,
  VendorOrderDetails,
  VendorOrders,
  WorkOrderDetails,
  WorkOrders
} from 'pages'
import Reports from 'pages/Reports'
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation
} from 'react-router-dom'

const AuthorizeRoute = () => {
  const auth = useAuthContext()
  const location = useLocation()

  if (auth.status === 'unauthorized') {
    return <Navigate to='/account/sign-in' state={{ from: location }} />
  }

  return <Outlet />
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='account/sign-in' element={<SignIn />} />
      <Route path='account/forgot-password' element={<ForgotPassword />} />
      <Route path='account/reset-password' element={<ResetPassword />} />

      <Route element={<AuthorizeRoute />}>
        <Route path='*' element={<Blank />} />
        <Route path='/banding' element={<Banding />} />
        <Route path='/big-jack-to-dos' element={<BigJackToDos />} />
        <Route path='/big-jack-to-dos/:id' element={<BigJackToDoDetails />} />
        <Route path='/companies' element={<Companies />} />
        <Route path='/companies/:id' element={<CompanyDetails />} />
        <Route path='/courses' element={<Courses />} />
        <Route path='/courses/:id' element={<CourseDetails />} />
        <Route path='/finished-goods-inventory' element={<FinishedGoodsInventory />} />
        <Route
          path='/finished-goods-inventory/:id'
          element={<FinishedGoodsInventoryDetails />}
        />
        <Route path='/forklift-to-dos' element={<ForkliftToDos />} />
        <Route path='/job-scheduling' element={<JobScheduling />} />
        <Route path='/job-scheduling/:id' element={<JobSchedulingDetails />} />
        <Route path='/learning' element={<Learning />} />
        <Route path='/little-jack-to-dos' element={<LittleJackToDos />} />
        <Route path='/little-jack-to-dos/:id' element={<LittleJackToDoDetails />} />
        <Route path='/material-coordinator' element={<MaterialCoordinator />} />
        <Route
          path='/material-coordinator/:id'
          element={<MaterialCoordinatorDetails />}
        />
        <Route path='/opportunities' element={<Opportunities />} />
        <Route path='/opportunities/:id' element={<OpportunityDetails />} />
        <Route path='/people' element={<People />} />
        <Route path='/people/:id' element={<PersonDetails />} />
        <Route path='/quotes/:id' element={<QuoteDetails />} />
        <Route path='/rates' element={<Rates />} />
        <Route path='/raw-inventory' element={<RawInventory />} />
        <Route path='/raw-inventory/:id' element={<RawInventoryDetails />} />
        <Route path='/receiving' element={<Receiving />} />
        <Route path='/reports' element={<Reports />} />
        <Route path='/shipping' element={<Shipping />} />
        <Route path='/shipping/:id' element={<ShippingDetails />} />
        <Route path='/vehicles' element={<Vehicles />} />
        <Route path='/vehicles/:id' element={<VehicleDetails />} />
        <Route path='/vendor-orders' element={<VendorOrders />} />
        <Route path='/vendor-orders/:id' element={<VendorOrderDetails />} />
        <Route path='/work-orders' element={<WorkOrders />} />
        <Route path='/work-orders/:id' element={<WorkOrderDetails />} />
      </Route>
    </>
  )
)

function App() {
  return (
    <ThemeProvider>
      <SnackbarProvider maxSnack={6} autoHideDuration={8000}>
        <AuthProvider>
          <CssBaseline />
          <RouterProvider router={router} />
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default App
