import { ApiResponse, RatesEndCostAdderDto } from 'dtos'
import { RatesEndCostAddersParameters } from 'parameters'
import { URIQuery } from 'utils'
import apiClient from '.'

export const getRatesEndCostAdders = (parameters: RatesEndCostAddersParameters) =>
  apiClient
    .get<ApiResponse<RatesEndCostAdderDto[]>>(
      '/api/rates-end-cost-adders' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)
