import { LoadingButton } from '@mui/lab'
import { Button, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import * as api from 'api'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import {
  Details,
  DetailsActions,
  DetailsForm,
  DetailsHeaderCard,
  DetailsTab
} from 'components/_template/details'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import { JobDto, jobValidationSchema } from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { useSnackbar } from 'notistack'
import { ReportTravelerExceptLastPage } from 'pages'
import { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { useNavigate, useParams } from 'react-router-dom'
import { format, formats } from 'theme'
import { useDebounce } from 'utils'
import { errorHandling } from '../constants'

export default function JobSchedulingDetails() {
  // #region hooks
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { id } = useParams()
  // #endregion

  // #region useState
  const [initialValues, setInitialValues] = useState<JobDto>(new JobDto())
  const [priorityList, setPriorityList] = useState<JobDto[]>([])
  // #region LoadingState
  const [isJobLoading, setIsJobLoading] = useState<boolean>(false)
  const [isPriorityListLoading, setIsPriorityListLoading] = useState<boolean>(false)
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false)
  // #endregion
  // #endregion

  // #region useEffect (Alphabetical by dependencies)
  useEffect(() => {}, [])
  // #endregion

  const openTravelerExceptLastPageReportWindow = (vendorOrderId: string) => {
    setIsReportLoading(true)
    api
      .getTravelerReport(id ?? '')
      .then(response => {
        const newWindow = window.open('', '_blank', 'width=800,height=600')

        if (newWindow) {
          newWindow.document.body.innerHTML =
            '<div id="traveler-except-last-page-report-container"></div>'

          const root = createRoot(
            newWindow.document.getElementById(
              'traveler-except-last-page-report-container'
            ) as HTMLElement
          )

          root.render(
            <ReportTravelerExceptLastPage
              opportunity={response.value}
              vendorOrderId={vendorOrderId}
              jobId={id ?? ''}
            />
          )
        } else {
          console.error('Failed to open a new window.')
        }
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsReportLoading(false)
      })
  }

  useEffect(() => {
    if (id && id.toLowerCase() !== 'new' && id.toLowerCase() !== 'undefined') {
      setIsJobLoading(true)
      api
        .getJobById(id)
        .then(({ value }) => {
          setInitialValues(value)
          if (value.scheduleDate) {
            getPriorityList(id, value.scheduleDate)
          }
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
        .finally(() => {
          setIsJobLoading(false)
        })
    } else {
    }
  }, [id])

  const getPriorityList = useDebounce((id: string, scheduleDate: string) => {
    setIsPriorityListLoading(true)
    api
      .getJobPriorityListByDateAndId(id, scheduleDate)
      .then(res => {
        setPriorityList(res.value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsPriorityListLoading(false)
      })
  }, 300)

  return (
    <Layout
      title='Job Scheduling Details'
      isLoading={isJobLoading || isPriorityListLoading || isReportLoading}
    >
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        validationSchema={jobValidationSchema}
        onSubmit={async (values, submitProps) => {
          setIsJobLoading(true)
          await api
            .updateJob(values)
            .then(({ value }) => {
              enqueueSnackbar('Job Has Been Saved!', {
                variant: 'success'
              })

              openTravelerExceptLastPageReportWindow(value.vendorOrder?.id ?? '')
              setInitialValues(value)
              submitProps.resetForm({ values: value })
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
            .finally(() => {
              setIsJobLoading(false)
            })
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <Details
              header={
                <DetailsHeaderCard
                  title={`Job Schedule Details - Job#: ${values.jobNumber} - SKU#: ${values.vendorOrder?.quoteLineItem?.skuNumber} - Quantity Received: ${values.vendorOrder?.receivedToDate}`}
                />
              }
              tabs={[{ value: 'tab1', label: 'Details' }]}
              onSubmit={e => e.preventDefault()}
            >
              <DetailsTab value='tab1'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(touched.scheduleDate && errors.scheduleDate)}
                            fullWidth
                            helperText={touched.scheduleDate && errors.scheduleDate}
                            InputLabelProps={{ shrink: true }}
                            label='Schedule Date'
                            name='scheduleDate'
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e)
                              getPriorityList(id, e.target.value)
                            }}
                            type='date'
                            value={
                              values?.scheduleDate
                                ? format(values.scheduleDate, formats.dateOnlyField)
                                : ''
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(touched.priority && errors.priority)}
                            fullWidth
                            helperText={touched.priority && errors.priority}
                            label='Select Priority'
                            name='priority'
                            onBlur={handleBlur}
                            onChange={e => {
                              setFieldValue('priority', parseInt(e.target.value))
                            }}
                            select
                            value={values.priority || ''}
                          >
                            <MenuItem key={'highestPriority'} value={1}>
                              Highest Priority
                            </MenuItem>
                            {/* If the user selects "Highest Priority" the priority is sent to the back-end as 1, otherwise,
                                  it is sent as one more than the priority of the job it should be placed AFTER */}
                            {priorityList.map(job => (
                              <MenuItem key={job.id} value={(job.priority ?? 0) + 1}>
                                {`${job?.priority?.toString()}: After Job #${
                                  job.jobNumber
                                } - ${job.vendorOrder?.company?.name}`}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Quote Line Item Notes:{' '}
                            {values.vendorOrder?.quoteLineItem?.lineItemNotes}
                          </Typography>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#2780E3', fontWeight: 600 }}
                          >
                            Order Information
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Customer Part Number:{' '}
                            {values.vendorOrder?.quoteLineItem?.customerPartNumber}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Contract Duration (Months):{' '}
                            {values.vendorOrder?.quoteLineItem?.contractDuration}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Customer Order Quantity:{' '}
                            {
                              values.vendorOrder?.quoteLineItem
                                ?.assemblyCustomerOrderQuantity
                            }
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Vendor Order Quantity:{' '}
                            {
                              values.vendorOrder?.quoteLineItem
                                ?.checkValuesVendorOrderQuantity
                            }
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Finished Good Unit Quantity Per Bundle:{' '}
                            {
                              values.vendorOrder?.quoteLineItem
                                ?.assemblyFinishedGoodUnitQuantityPerBundle
                            }
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Outs: {values.vendorOrder?.quoteLineItem?.assemblyOuts}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Test Run Quantity (Pieces):{' '}
                            {values.vendorOrder?.quoteLineItem?.testRunQuantity}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Minimum Order Run Quantity (Pieces):{' '}
                            {values.vendorOrder?.quoteLineItem?.minimumOrderRunQuantity}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Finished Goods Projection:{' '}
                            {values.vendorOrder?.finishedGoodsProjection}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Projected Bundles: {values.vendorOrder?.projectedBundles}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                            Job Center: {values.vendorOrder?.quoteLineItem?.jobCenter}
                          </Typography>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Received
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                          <Grid item xs={12} sm={3}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Pallet #
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Quantity
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#7F7F7F', fontWeight: 600 }}
                            >
                              Received Date
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={3} />
                        </Grid>
                        {values.vendorOrder?.rawInventory?.map(
                          (rawInventoryItem, index) => (
                            <Grid container item xs={12}>
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {rawInventoryItem.palletNumber}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {rawInventoryItem.quantityReceived}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                                  {format(
                                    rawInventoryItem.createdDateTimeUtc,
                                    formats.dateTime
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={3} />
                            </Grid>
                          )
                        )}
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      Job center is automatically populated from the quote. If the job
                      must be run on Big Jack. i.e. If gross width &gt; 64 or if gross
                      length &gt; 125 then the job center drop down is disabled,
                      otherwise, the user can change the job center.||When saved, a PDF
                      will be generated and the user can open the PDF to print if
                      needed.||Priority can only be selected once a date and Job Center
                      have been selected. It lists all jobs that have not been completed
                      and are not in the status of started or paused.||All fields on this
                      card are required.
                    </ExplanationAccordion>
                    {/* <ExplanationAccordion development>
                          xxxxxxxxxxxxx
                        </ExplanationAccordion> */}
                  </Grid>
                </Grid>
              </DetailsTab>

              <DetailsActions>
                <Button
                  color='secondary'
                  onClick={() => {
                    navigate('/job-scheduling')
                  }}
                  variant='text'
                >
                  BACK
                </Button>

                <LoadingButton
                  color='primary'
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </LoadingButton>
              </DetailsActions>
            </Details>
          )
        }}
      </Formik>
    </Layout>
  )
}
