import { FormikErrors } from 'formik'
import { enqueueSnackbar } from 'notistack'

export const showFormErrorsPrompt = (errors: FormikErrors<any>) => {
  return Object.keys(errors).length !== 0
    ? enqueueSnackbar('Please Correct Form Errors (Red Text) Before Saving.', {
        variant: 'error'
      })
    : null
}
