import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material'
import { ExplanationAccordion } from 'components/_template/accordion'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import { RawInventoryDto, receivingScanBarcodeValidationSchema } from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { useRef } from 'react'

interface ReceivingScanBarcodeDialogProps {
  onClose: () => void
  open: boolean
  rawInventory?: RawInventoryDto
  onSave: (values: RawInventoryDto) => Promise<void | RawInventoryDto>
}

export default function ReceivingScanBarcodeDialog({
  onClose,
  open,
  onSave,
  rawInventory
}: ReceivingScanBarcodeDialogProps) {
  // These refs need to be cast to type any because TypeScript doesn't like them being initialized to null
  const vendorOrderNumberRef: any = useRef(null)
  const vendorItemNumberRef: any = useRef(null)
  const palletNumberRef: any = useRef(null)
  const quantityReceivedRef: any = useRef(null)

  const onFocusVendorOrderNumber = () => {
    vendorOrderNumberRef.current.focus()
  }
  const onFocusVendorItemNumber = () => {
    vendorItemNumberRef.current.focus()
  }
  const onFocusPalletNumber = () => {
    palletNumberRef.current.focus()
  }
  const onFocusQuantityReceived = () => {
    quantityReceivedRef.current.focus()
  }

  return (
    // disableRestoreFocus seems necessary for the autofocus prop to function. See https://github.com/mui/material-ui/issues/33004#issuecomment-1455260156
    <Dialog open={open} disableRestoreFocus>
      <Formik
        enableReinitialize
        initialValues={rawInventory || new RawInventoryDto()}
        validateOnBlur
        validateOnChange
        validationSchema={receivingScanBarcodeValidationSchema}
        onSubmit={(values, formikHelpers) => {
          // Clear the form values whether the save method saves successfully or throws an error
          onSave(values).finally(() => {
            onFocusVendorOrderNumber()
            formikHelpers.resetForm()
          })
        }}
      >
        {({
          dirty,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          const onSubmitForm = () => {
            // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
            // Otherwise, sometimes the field errors won't display even though the validation throws them
            validateForm().then(errors => {
              const errorKeys = Object.keys(errors)
              if (errorKeys.length === 0) {
                submitForm()
              } else {
                setTouched(setNestedObjectValues(errors, true))
                showFormErrorsPrompt(errors)
              }
            })
          }

          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>
                    Scan Barcodes (or key in values) for each pallet
                  </DialogTitle>

                  <Grid item xs={12}>
                    <TextField
                      autoFocus={rawInventory === undefined}
                      error={Boolean(
                        touched.vendorOrderNumber && errors.vendorOrderNumber
                      )}
                      fullWidth
                      helperText={touched.vendorOrderNumber && errors.vendorOrderNumber}
                      label='Vendor Order Number'
                      name='vendorOrderNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.vendorOrderNumber || ''}
                      inputRef={vendorOrderNumberRef}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          onSubmitForm()
                        }
                      }}
                      inputProps={{ tabIndex: 1 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.vendorItemNumber && errors.vendorItemNumber)}
                      fullWidth
                      helperText={touched.vendorItemNumber && errors.vendorItemNumber}
                      label='Vendor Item Number'
                      name='vendorItemNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.vendorItemNumber || ''}
                      inputRef={vendorItemNumberRef}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          onSubmitForm()
                        }
                      }}
                      inputProps={{ tabIndex: 2 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      autoFocus={rawInventory !== undefined}
                      error={Boolean(touched.palletNumber && errors.palletNumber)}
                      fullWidth
                      helperText={touched.palletNumber && errors.palletNumber}
                      label='Pallet Number'
                      name='palletNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.palletNumber || ''}
                      inputRef={palletNumberRef}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          onSubmitForm()
                        }
                      }}
                      inputProps={{ tabIndex: 3 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.quantityReceived && errors.quantityReceived)}
                      fullWidth
                      helperText={touched.quantityReceived && errors.quantityReceived}
                      label='Quantity Received'
                      name='quantityReceived'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.quantityReceived || ''}
                      inputRef={quantityReceivedRef}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          onSubmitForm()
                        }
                      }}
                      inputProps={{ tabIndex: 4 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      Based on the scan the forklift operator is notified to pick up the
                      received pallet.||If the Vendor Order Number, Vendor Item Number,
                      and Pallet Number combination has already been received, a warning
                      will be displayed saying the Pallet already exists and cannot be
                      scanned by receiving again.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button
                  disabled={isSubmitting}
                  color='secondary'
                  onClick={() => {
                    onClose()
                  }}
                  tabIndex={6}
                  variant='text'
                >
                  CLOSE
                </Button>

                <Button
                  disabled={isSubmitting}
                  onClick={() => {
                    onSubmitForm()
                  }}
                  tabIndex={5}
                  variant='contained'
                >
                  SUBMIT
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
