import apiClient from 'api'
import { ApiResponse, ShippingDto } from 'dtos'
import { ShippingParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getShipping = (parameters: ShippingParameters) =>
  apiClient
    .get<ApiResponse<ShippingDto[]>>('/api/shipping' + URIQuery({ ...parameters }))
    .then(({ data }) => data)

export const getShippingById = (id: string) =>
  apiClient.get<ApiResponse<ShippingDto>>('/api/shipping/' + id).then(({ data }) => data)

export const createShipping = (values: ShippingDto) =>
  apiClient
    .post<ApiResponse<ShippingDto>>('/api/shipping', values)
    .then(({ data }) => data)

export const updateShipping = (values: ShippingDto) =>
  apiClient
    .put<ApiResponse<ShippingDto>>('/api/shipping', values)
    .then(({ data }) => data)
