import { Grid, TablePagination } from '@mui/material'
import { Children, PropsWithChildren, ReactElement, useEffect } from 'react'
import { ListHeaderCardProps } from './ListHeaderCard'

export type ListPagination = {
  count: number
  page: number
  pageSize: number
  onPageChange: (page: number) => void
  onPageSizeChange: (pageSize: number) => void
}

export type ListProps = {
  header?: ReactElement<ListHeaderCardProps>
  summary?: ReactElement
  pagination?: ListPagination
}

export default function List({
  children,
  header,
  summary,
  pagination
}: PropsWithChildren<ListProps>): ReactElement {
  useEffect(() => {
    // Handle ending up on an empty page when filtering or changing page size
    if (
      (pagination?.count ?? 0) <
      (pagination?.page ?? 0) * (pagination?.pageSize ?? 0)
    ) {
      pagination?.onPageChange(Math.floor(pagination.count / pagination.pageSize))
    }
  }, [pagination])

  return (
    <Grid container spacing={2}>
      {header && (
        <Grid item xs={12}>
          {header}
        </Grid>
      )}

      {summary && (
        <Grid item xs={12}>
          {summary}
        </Grid>
      )}

      {Children.map(children, child => (
        <Grid item xs={12}>
          {child}
        </Grid>
      ))}

      {pagination && (
        <Grid item xs={12} container justifyContent='flex-end'>
          <TablePagination
            component='div'
            count={pagination.count}
            page={pagination.page}
            rowsPerPage={pagination.pageSize}
            onPageChange={(_, page: number) => pagination.onPageChange(page)}
            onRowsPerPageChange={e =>
              pagination.onPageSizeChange(e.target.value as unknown as number)
            }
          />
        </Grid>
      )}
    </Grid>
  )
}
