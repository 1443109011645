import * as yup from 'yup'
import {
  fieldTypeMessage,
  maxTextFieldMessage,
  minTextFieldMessage,
  requiredFieldMessage
} from '../constants'

export class ResetPasswordDto {
  email?: string = ''
  password?: string = ''
  confirmPassword?: string = ''
}

export const resetPasswordValidationSchema = yup.object({
  email: yup.string().email(fieldTypeMessage('Email')).required(requiredFieldMessage),
  password: yup
    .string()
    .min(8, minTextFieldMessage(8))
    .max(64, maxTextFieldMessage(64))
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$/,
      'Password Must Contain at Least One Uppercase Letter, One Lowercase Letter, and One Number.'
    )
    .required(requiredFieldMessage),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Password and Confirm Password Must Match.')
    .required(requiredFieldMessage)
})
