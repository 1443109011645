import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'
import { ReportBillOfLadingDto } from 'dtos'
import { formatDate } from 'utils'

const styles = StyleSheet.create({
  cell: {
    flexDirection: 'row', // Arrange columns horizontally
    justifyContent: 'space-between', // Evenly distribute columns
    textAlign: 'left', // Left-align the text
    width: '100%' // Occupy the entire width of the page
  },
  column: {
    flex: 1,
    padding: 5 // Adjust as needed
  },
  headerCell: {
    flexDirection: 'row', // Arrange columns horizontally
    fontWeight: 'bold', // Bold font
    justifyContent: 'space-between', // Evenly distribute columns
    textAlign: 'left', // Left-align the text
    width: '100%' // Occupy the entire width of the page
  },
  headerCellGreen: {
    color: 'green', // Text color green
    flexDirection: 'row', // Arrange columns horizontally
    fontWeight: 'bold', // Bold font
    justifyContent: 'space-between', // Evenly distribute columns
    textAlign: 'left', // Left-align the text
    width: '100%' // Occupy the entire width of the page
  },
  headerCellWhite: {
    color: 'white', // Text color white
    flexDirection: 'row', // Arrange columns horizontally
    fontWeight: 'bold', // Bold font
    justifyContent: 'space-between', // Evenly distribute columns
    textAlign: 'left', // Left-align the text
    width: '100%' // Occupy the entire width of the page
  },
  horizontalLine: {
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    marginBottom: 5,
    marginTop: 5
  },
  logo: {
    height: 75,
    width: 250
  },
  page: {
    // The Bill of Lading background should be white, not gray like other reports
    flexDirection: 'row',
    fontSize: 10
  },
  pdf: {
    height: '500px',
    width: '100%'
  },
  section: {
    flexGrow: 1,
    margin: 10,
    padding: 10
  },
  table: {
    alignItems: 'center',
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    flexDirection: 'row', // Arrange columns horizontally
    justifyContent: 'space-between', // Evenly distribute columns
    width: '100%' // Occupy the entire width of the page
  },
  tableGreen: {
    alignItems: 'center',
    backgroundColor: 'green',
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    flexDirection: 'row', // Arrange columns horizontally
    justifyContent: 'space-between', // Evenly distribute columns
    width: '100%' // Occupy the entire width of the page
  },
  tablePadded: {
    alignItems: 'center',
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    flexDirection: 'row', // Arrange columns horizontally
    justifyContent: 'space-between', // Evenly distribute columns
    marginTop: '20px',
    width: '100%' // Occupy the entire width of the page
  },
  textBlock: {
    marginBottom: 10
  },
  textBoldGreen: {
    color: 'green',
    fontWeight: 'bold'
  }
})

interface ReportBillOfLadingProps {
  reportBillOfLadingDto: ReportBillOfLadingDto
}

const TableWithPDFButton = ({ reportBillOfLadingDto }: ReportBillOfLadingProps) => {
  return (
    <div>
      <PDFViewer style={styles.pdf}>
        <Document>
          <Page size='LETTER' style={styles.page}>
            <View style={styles.section}>
              <Image src={process.env.PUBLIC_URL + '/nglogo.jpg'} style={styles.logo} />

              <Text style={styles.textBlock}>{''}</Text>

              <Text style={styles.textBlock}>
                <Text style={styles.textBoldGreen}>Shipper</Text>
                {'\n'}
                {reportBillOfLadingDto.shipperNumber}
              </Text>

              <Text style={styles.textBlock}>
                <Text style={styles.textBoldGreen}>Date</Text>
                {'\n'}
                {formatDate(new Date())}
              </Text>

              <View style={styles.horizontalLine} />

              <View style={styles.table}>
                <View style={styles.column}>
                  <Text style={styles.headerCellGreen}>From</Text>
                  <Text style={styles.cell}>JackPack</Text>
                  <Text style={styles.cell}>2100 T A Mansour Blvd</Text>
                  <Text style={styles.cell}>Flint, MI 48532</Text>
                  <Text style={styles.cell}> </Text>
                </View>

                <View style={styles.column}>
                  <Text style={styles.headerCellGreen}>Ship To</Text>

                  <Text style={styles.cell}>{reportBillOfLadingDto.company?.name}</Text>

                  <Text style={styles.cell}>
                    {reportBillOfLadingDto.shipToAddress?.address1}
                  </Text>

                  <Text style={styles.cell}>
                    {reportBillOfLadingDto.shipToAddress?.address2}
                  </Text>

                  <Text style={styles.cell}>
                    {reportBillOfLadingDto.shipToAddress?.city},{' '}
                    {reportBillOfLadingDto.shipToAddress?.state}{' '}
                    {reportBillOfLadingDto.shipToAddress?.postalCode}
                  </Text>
                </View>

                <View style={styles.column}>
                  <Text style={styles.headerCellGreen}>Bill To</Text>
                  <Text style={styles.cell}>{reportBillOfLadingDto.company?.name}</Text>

                  <Text style={styles.cell}>
                    {reportBillOfLadingDto.billToAddress?.address1}
                  </Text>

                  <Text style={styles.cell}>
                    {reportBillOfLadingDto.billToAddress?.address2}
                  </Text>

                  <Text style={styles.cell}>
                    {reportBillOfLadingDto.billToAddress?.city},{' '}
                    {reportBillOfLadingDto.billToAddress?.state}{' '}
                    {reportBillOfLadingDto.billToAddress?.postalCode}
                  </Text>
                </View>
              </View>

              <View style={styles.horizontalLine} />

              <View style={styles.tableGreen}>
                <Text style={styles.headerCellWhite}>Seal #</Text>
                <Text style={styles.headerCellWhite}>Carrier</Text>
                <Text style={styles.headerCellWhite}>Trailer #</Text>
              </View>

              <View style={styles.table}>
                <Text style={styles.cell}>{reportBillOfLadingDto.sealNumber}</Text>
                <Text style={styles.cell}>{reportBillOfLadingDto.carrier}</Text>
                <Text style={styles.cell}>{reportBillOfLadingDto.trailerNumber}</Text>
              </View>

              <Text style={styles.textBlock}>{''}</Text>
              <View style={styles.tableGreen}>
                <Text style={styles.headerCellWhite}>Job #</Text>
                <Text style={styles.headerCellWhite}>Quantity (Pieces)</Text>
                <Text style={styles.headerCellWhite}>Weight</Text>
              </View>

              {reportBillOfLadingDto.shippingJobs?.map(shippingJob => (
                <View style={styles.table}>
                  <Text style={styles.cell}>{shippingJob.job?.jobNumber}</Text>
                  <Text style={styles.cell}>
                    {shippingJob.shippingItems?.reduce(
                      (accumulator, currentValue) =>
                        accumulator + currentValue.finishedGoodsInventory?.quantity!,
                      0
                    ) ?? ''}
                  </Text>
                  <Text style={styles.cell}>{shippingJob.weight}</Text>
                </View>
              ))}

              <Text style={styles.textBlock}>{''}</Text>
              <View style={styles.tableGreen}>
                <Text style={styles.headerCellWhite}>SLI#</Text>
                <Text style={styles.headerCellWhite}>Signature</Text>
                <Text style={styles.headerCellWhite}>Date Signed</Text>
              </View>

              <View style={styles.tablePadded}>
                <Text style={styles.cell}>{reportBillOfLadingDto.movementNumber}</Text>
                <Text style={styles.headerCell}>______________________________</Text>
                <Text style={styles.headerCell}>______________________________</Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  )
}

export default TableWithPDFButton
