import { ApiResponse, OpportunityNextActivityTypeDto } from 'dtos'
import { OpportunityNextActivityTypesParameters } from 'parameters'
import { URIQuery } from 'utils'
import apiClient from '.'

export const getOpportunityNextActivityTypes = (
  parameters: OpportunityNextActivityTypesParameters
) =>
  apiClient
    .get<ApiResponse<OpportunityNextActivityTypeDto[]>>(
      '/api/opportunity-next-activity-types' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)
