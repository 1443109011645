import { ErrorOutline } from '@mui/icons-material'
import { TabContext, TabList } from '@mui/lab'
import { Card, CardContent, Grid, Tab } from '@mui/material'
import React, { useState } from 'react'

export type DetailsProps = {
  header?: React.ReactNode
  tabs: { value: string; label: string; disabled?: boolean; error?: boolean }[]
  defaultValue?: any
  onSubmit: React.FormEventHandler<HTMLFormElement>
}

export default function Details({
  children,
  defaultValue = '',
  header,
  tabs,
  onSubmit
}: React.PropsWithChildren<DetailsProps>) {
  const [tabContextValue, setTabContextValue] = useState<string>(
    defaultValue || tabs[0].value || ''
  )

  return (
    <Grid container spacing={2} sx={{ p: 2 }}>
      {header && (
        <Grid item xs={12}>
          {header}
        </Grid>
      )}

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <form onSubmit={onSubmit}>
              <TabContext value={tabContextValue}>
                <TabList
                  onChange={(_: any, newValue: string) => setTabContextValue(newValue)}
                  variant='scrollable'
                >
                  {tabs.map(tab => (
                    <Tab
                      disabled={tab.disabled}
                      key={tab.value}
                      label={
                        // Only displays the tab error when the tab is not currently selected
                        tab.error && tabContextValue !== tab.value ? (
                          <Grid container spacing={1} alignItems='center'>
                            <Grid item color={theme => theme.palette.error.main}>
                              {tab.label}
                            </Grid>
                            <Grid item>
                              <ErrorOutline color='error' />
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container spacing={1} alignItems='center'>
                            <Grid item color={theme => theme.palette.text.secondary}>
                              {tab.label}
                            </Grid>
                          </Grid>
                        )
                      }
                      value={tab.value}
                    />
                  ))}
                </TabList>

                {children}
              </TabContext>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
