import { createTheme } from '@mui/material/styles'
import { baseTheme } from './baseTheme'

export const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'light',
    background: {
      default: ' #F4F5FA'
    },
    primary: {
      main: '#2780e3',
      light: '#009bff',
      dark: '#000'
    },
    secondary: {
      main: '#f50057',
      light: '#ab003c',
      dark: '#000'
    }
  }
})
