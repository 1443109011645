import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField
} from '@mui/material'
import { UploadDropzone } from 'components'
import {
  LearningMaterialAnswerDto,
  LearningMaterialDto,
  LearningMaterialQuestionDto,
  LearningMaterialTypeDto
} from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { Fragment } from 'react'
import * as yup from 'yup'
import { showFormErrorsPrompt } from './_template/form/FormErrorsPrompt'

interface LearningMaterialAddEditDialogProps {
  learningMaterialTypes: LearningMaterialTypeDto[]
  learningMaterial: LearningMaterialDto
  open: boolean
  onClose: () => void
  onSave: (values: LearningMaterialDto) => void
}

export default function LearningMaterialAddEditDialog({
  learningMaterial,
  learningMaterialTypes,
  onClose,
  onSave,
  open
}: LearningMaterialAddEditDialogProps) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={learningMaterial}
        validateOnBlur
        validateOnChange
        validationSchema={yup.object({})}
        onSubmit={values => {
          onSave(values)
        }}
      >
        {({
          dirty,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Add Learning Material</DialogTitle>

                  <Grid item xs={12}>
                    <TextField
                      // error={Boolean(touched.learningMaterialType && errors.learningMaterialType)}
                      // helperText={touched.learningMaterialType && errors.learningMaterialType}
                      fullWidth
                      select
                      label='Type'
                      name='learningMaterialType'
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue('learningMaterialType', JSON.parse(e.target.value))
                      }}
                      value={
                        values?.learningMaterialType
                          ? JSON.stringify(values.learningMaterialType)
                          : ''
                      }
                    >
                      {learningMaterialTypes
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(learningMaterialType => (
                          <MenuItem
                            value={JSON.stringify(learningMaterialType)}
                            key={learningMaterialType.id}
                          >
                            {learningMaterialType.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  {(values.learningMaterialType?.name === 'Document' ||
                    values.learningMaterialType?.name === 'Video') && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label='Title'
                          name='name'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label='Description'
                          maxRows={4}
                          minRows={4}
                          multiline
                          name='description'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.description}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <UploadDropzone />
                      </Grid>
                    </>
                  )}

                  {values.learningMaterialType?.name === 'Quiz' && (
                    <>
                      {values.learningMaterialQuestions
                        ?.sort((a, b) => a.order - b.order)
                        .map((_, questionIndex) => (
                          <Fragment key={questionIndex}>
                            {questionIndex > 0 && (
                              <Grid item xs={12}>
                                <Divider sx={{ mt: 1, mb: 1 }} />
                              </Grid>
                            )}

                            <Grid item xs={12} container spacing={1} alignItems='center'>
                              <Grid item xs={12} sm={9}>
                                <TextField
                                  fullWidth
                                  label={'Question ' + (questionIndex + 1)}
                                  value={
                                    values.learningMaterialQuestions![questionIndex].title
                                  }
                                  name={`learningMaterialQuestions[${questionIndex}].title`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  // error={Boolean(touched.)}
                                />
                              </Grid>

                              <Grid item xs={12} sm={3}>
                                <Button
                                  onClick={() => {
                                    setFieldValue(
                                      `learningMaterialQuestions[${questionIndex}].learningMaterialAnswers`,
                                      [
                                        ...values.learningMaterialQuestions![
                                          questionIndex
                                        ].learningMaterialAnswers,
                                        {
                                          ...new LearningMaterialAnswerDto(),
                                          order:
                                            values.learningMaterialQuestions![
                                              questionIndex
                                            ].learningMaterialAnswers.length
                                        }
                                      ]
                                    )
                                  }}
                                >
                                  ADD ANSWER
                                </Button>
                              </Grid>
                            </Grid>

                            {values
                              .learningMaterialQuestions![
                                questionIndex
                              ].learningMaterialAnswers?.sort((a, b) => a.order - b.order)
                              .map((_, answerIndex) => (
                                <>
                                  <Grid item xs={12} sm={9}>
                                    <TextField
                                      // error={}
                                      fullWidth
                                      // helperText={}
                                      label={'Answer ' + (answerIndex + 1)}
                                      name={`learningMaterialQuestions[${questionIndex}].learningMaterialAnswers[${answerIndex}].description`}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      size='small'
                                      value={
                                        values.learningMaterialQuestions![questionIndex]
                                          .learningMaterialAnswers[answerIndex]
                                          .description || ''
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={3}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            values.learningMaterialQuestions![
                                              questionIndex
                                            ].learningMaterialAnswers[answerIndex]
                                              .isCorrect
                                          }
                                          name={`learningMaterialQuestions[${questionIndex}].learningMaterialAnswers[${answerIndex}].isCorrect`}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={
                                            values.learningMaterialQuestions![
                                              questionIndex
                                            ].learningMaterialAnswers[answerIndex]
                                              .isCorrect
                                          }
                                        />
                                      }
                                      label='Correct'
                                    />
                                  </Grid>
                                </>
                              ))}
                          </Fragment>
                        ))}

                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          variant='outlined'
                          onClick={() => {
                            setFieldValue('learningMaterialQuestions', [
                              ...(values.learningMaterialQuestions ?? []),
                              {
                                ...new LearningMaterialQuestionDto(),
                                order: values.learningMaterialQuestions?.length
                              }
                            ])
                          }}
                        >
                          ADD QUESTION
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button variant='text' color='secondary' onClick={onClose}>
                  CLOSE
                </Button>

                <Button
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
