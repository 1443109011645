import {
  Logout,
  ArrowBack as MenuCloseIcon,
  ArrowForward as MenuOpenIcon
} from '@mui/icons-material'
import {
  Avatar,
  Badge,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
  styled,
  useMediaQuery
} from '@mui/material'
import { useAuthContext, useThemeModeContext } from 'context'
import { Fragment, SyntheticEvent, useState } from 'react'
import { baseTheme } from 'theme/baseTheme'

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  }
}

// https://mui.com/components/switches/#CustomizedSwitches.tsx
const ThemeSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 9.6,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be'
      }
    }
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff'
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`
    }
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2
  }
}))

const UserProfile = () => {
  const { signOut, user } = useAuthContext()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const onClickUserProfile = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const onCloseUserProfileMenu = () => {
    setAnchorEl(null)
  }

  return (
    <Fragment>
      <Badge overlap='circular' sx={{ cusor: 'pointer' }}>
        <Avatar
          alt='Avatar'
          onClick={onClickUserProfile}
          // sx={{ width: 40, height: 40 }}
          src='/images/Avatar.png'
          {...stringAvatar(
            (user?.firstName.trim() ?? 'John') + ' ' + (user?.lastName.trim() ?? 'Doe')
          )}
        />
      </Badge>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onCloseUserProfileMenu}>
        <MenuItem onClick={signOut}>
          <Logout />
          &nbsp;&nbsp;Sign Out
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

type AppBarProps = {
  isMenuOpen: boolean
  onToggleMenu: () => void
}

export default function AppBar({ isMenuOpen, onToggleMenu }: AppBarProps) {
  const isMobileWidth = useMediaQuery(baseTheme.breakpoints.down('sm'))
  const { themeMode, setMode } = useThemeModeContext()

  const onChangeThemeSwitch = () => {
    if (themeMode === 'dark') {
      setMode('light')
    } else {
      setMode('dark')
    }
  }

  return (
    <MuiAppBar
      position={isMobileWidth ? 'relative' : 'fixed'}
      elevation={0}
      sx={{ px: 2, zIndex: theme => theme.zIndex.appBar }}
      color='primary'
    >
      <Toolbar disableGutters>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item container alignItems='center' xs={12} sm={6}>
            <Grid item>
              <Tooltip title={isMenuOpen ? 'Close menu' : 'Open menu'}>
                <IconButton
                  color='inherit'
                  aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
                  // edge='start'
                  onClick={onToggleMenu}
                  sx={{
                    padding: 0
                  }}
                >
                  {isMenuOpen ? <MenuCloseIcon /> : <MenuOpenIcon />}
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid item>
              <Typography variant='h6' noWrap sx={{ fontWeight: '600', ml: '24px' }}>
                Spud ERP
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            container
            spacing={2}
            justifyContent='flex-end'
            alignItems='center'
          >
            <Grid item>
              <Tooltip
                title={
                  themeMode === 'dark' ? 'Turn on the lights...' : 'Turn off the lights!'
                }
              >
                <ThemeSwitch
                  checked={themeMode === 'dark'}
                  onClick={onChangeThemeSwitch}
                />
              </Tooltip>
            </Grid>

            <Grid item sx={{ cursor: 'pointer' }}>
              <UserProfile />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </MuiAppBar>
  )
}
