import { ApiResponse, ForkliftToDoDto } from 'dtos'
import apiClient from '.'
import { ForkliftToDosParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getForkliftToDos = (parameters: ForkliftToDosParameters) =>
  apiClient
    .get<ApiResponse<ForkliftToDoDto[]>>(
      '/api/forklift-to-dos' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getForkliftToDoById = (id: string) =>
  apiClient
    .get<ApiResponse<ForkliftToDoDto>>('/api/forklift-to-dos/' + id)
    .then(({ data }) => data)

export const updateForkliftToDo = (values: ForkliftToDoDto) =>
  apiClient
    .put<ApiResponse<ForkliftToDoDto>>('/api/forklift-to-dos', values)
    .then(({ data }) => data)
