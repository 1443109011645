import apiClient from 'api'
import { ApiResponse, JobDto } from 'dtos'
import {
  BigJackToDosParameters,
  JobsParameters,
  LittleJackToDosParameters
} from 'parameters'
import { URIQuery } from 'utils'

export const getJobs = (parameters: JobsParameters) =>
  apiClient
    .get<ApiResponse<JobDto[]>>('/api/jobs' + URIQuery({ ...parameters }))
    .then(({ data }) => data)

export const getBigJackToDos = (parameters: BigJackToDosParameters) =>
  apiClient
    .get<ApiResponse<JobDto[]>>('/api/jobs/big-jack-to-dos' + URIQuery({ ...parameters }))
    .then(({ data }) => data)

export const getLittleJackToDos = (parameters: LittleJackToDosParameters) =>
  apiClient
    .get<ApiResponse<JobDto[]>>(
      '/api/jobs/little-jack-to-dos' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getJobPriorityListByDateAndId = (id: string, scheduleDate: string) =>
  apiClient
    .get<ApiResponse<JobDto[]>>(
      '/api/jobs/priority-list' + URIQuery({ id, scheduleDate })
    )
    .then(({ data }) => data)

export const getJobById = (id: string) =>
  apiClient.get<ApiResponse<JobDto>>('/api/jobs/' + id).then(({ data }) => data)

export const getBigJackToDoById = (id: string) =>
  apiClient
    .get<ApiResponse<JobDto>>('/api/jobs/big-jack-to-dos/' + id)
    .then(({ data }) => data)

export const getLittleJackToDoById = (id: string) =>
  apiClient
    .get<ApiResponse<JobDto>>('/api/jobs/little-jack-to-dos/' + id)
    .then(({ data }) => data)

export const createJob = (values: JobDto) =>
  apiClient.post<ApiResponse<JobDto>>('/api/jobs', values).then(({ data }) => data)

export const updateJob = (values: JobDto) =>
  apiClient.put<ApiResponse<JobDto>>('/api/jobs', values).then(({ data }) => data)
