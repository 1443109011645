import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View // Import the Image component from @react-pdf/renderer
} from '@react-pdf/renderer'
import {
  CompanyDto,
  JobDto,
  OpportunityDto,
  QuoteLineItemDto,
  VendorOrderDto
} from 'dtos'
import JsBarcode from 'jsbarcode'
import moment from 'moment'
import { format, formats } from 'theme'

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    fontSize: 10
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  horizontalLine: {
    borderBottomWidth: 1,
    borderBottomColor: 'black',
    marginTop: 5,
    marginBottom: 5
  },
  boldText: {
    fontWeight: 'bold',
    textAlign: 'center'
  },
  textBlock: {
    marginBottom: 10,
    fontSize: 12 // Adjust the font size as needed
  },

  cell: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    textAlign: 'left',
    fontSize: 20
  }
})

const generateBarcodeImage = (barcodeValue: string) => {
  const barcodeCanvas = document.createElement('canvas')
  // "displayValue: false" causes the number to not display below the barcode
  // "width" is the width, in pixels of 1 bar in the barcode
  JsBarcode(barcodeCanvas, barcodeValue, { displayValue: false, width: 1 })
  return (
    <Image
      src={barcodeCanvas.toDataURL()}
      style={{
        height: 100,
        width: barcodeCanvas.width,
        maxWidth: '100%'
      }}
    />
  )
}

interface ReportTravelerLastPageProps {
  opportunity: OpportunityDto
  vendorOrderId: string
  jobId: string
  lastBandingQuantity?: number
  stopCount: number
}

const ReportTravelerLastPage = ({
  opportunity,
  vendorOrderId,
  jobId,
  lastBandingQuantity,
  stopCount
}: ReportTravelerLastPageProps) => {
  class PageDto {
    customerName: string = ''
    skuNumber: string = ''
    jobNumber: string = ''
    quantity: string = ''
    bundleNumber: string = ''
    date: string = ''
  }

  let dateGenerated = format(moment(), formats.date)
  let opportunityQuoteRevisionLineItem =
    opportunity.quote?.quoteRevision?.quoteLineItem ?? new QuoteLineItemDto()
  let vendorOrder =
    opportunityQuoteRevisionLineItem?.vendorOrders?.find(
      vendorOrder => vendorOrder.id === vendorOrderId
    ) ?? new VendorOrderDto()
  let job = vendorOrder?.jobs?.find(job => job.id === jobId) ?? new JobDto()
  let company = opportunity.targetCompany ?? new CompanyDto()

  var page: PageDto = {
    customerName: company.name.toUpperCase(),
    skuNumber: opportunityQuoteRevisionLineItem.skuNumber ?? '',
    jobNumber: job.jobNumber?.toString() ?? '',
    quantity: lastBandingQuantity?.toString() ?? '',
    // stopCount is the number of times the job has been marked stopped or completed, including the time that generated this report
    // Subtract 1 so that we don't increment past the expected last pallet number unless marked stopped or compelted more than once
    bundleNumber:
      vendorOrder?.projectedBundles !== undefined
        ? (vendorOrder?.projectedBundles + stopCount - 1).toString()
        : '',
    date: dateGenerated
  }

  return (
    <PDFViewer style={{ width: '100%', height: '100%' }}>
      <Document>
        <Page>
          <Text style={styles.textBlock}>{''}</Text>
          <Text style={styles.textBlock}>{''}</Text>
          <View style={styles.horizontalLine} />
          <Text style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 12 }}>
            FOLD HERE
          </Text>
          <Text style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 36 }}>
            JACKPACK
          </Text>
          <Text style={{ fontWeight: 'bold', textAlign: 'center', fontSize: 20 }}>
            Powered by NorthGate
          </Text>
          <Text style={styles.textBlock}>{''}</Text>
          <Text style={styles.textBlock}>{''}</Text>
          <Text style={styles.textBlock}>
            <Text style={{ ...styles.cell, textAlign: 'left', fontSize: 20 }}>
              CUSTOMER:
            </Text>
            <Text style={{ ...styles.cell, textAlign: 'left', fontSize: 28 }}>
              {page.customerName}
            </Text>
          </Text>
          <View style={styles.horizontalLine} />
          <Text style={{ ...styles.cell, textAlign: 'left', fontSize: 20 }}>PART #:</Text>
          {generateBarcodeImage(page.skuNumber)}
          <Text style={{ ...styles.cell, textAlign: 'left', fontSize: 28 }}>
            {page.skuNumber}
          </Text>
          <Text style={{ ...styles.cell, textAlign: 'right', fontSize: 20 }}>JOB #:</Text>
          <View style={{ flexDirection: 'row-reverse' }}>
            {generateBarcodeImage(page.jobNumber)}
          </View>
          <Text style={{ ...styles.cell, textAlign: 'right', fontSize: 28 }}>
            {page.jobNumber}
          </Text>
          <Text style={{ ...styles.cell, textAlign: 'left', fontSize: 20 }}>
            QUANTITY:
          </Text>
          {generateBarcodeImage(page.quantity)}
          <Text style={{ ...styles.cell, textAlign: 'left', fontSize: 28 }}>
            {page.quantity}
          </Text>
          <Text style={{ ...styles.cell, textAlign: 'right', fontSize: 20 }}>
            BUNDLE #:
          </Text>
          <View style={{ flexDirection: 'row-reverse' }}>
            {generateBarcodeImage(page.bundleNumber)}
          </View>
          <Text style={{ ...styles.cell, textAlign: 'right', fontSize: 28 }}>
            {page.bundleNumber}
          </Text>
          <Text style={styles.textBlock}>
            <Text style={{ ...styles.cell, textAlign: 'left', fontSize: 20 }}>DATE:</Text>
            <Text style={{ ...styles.cell, textAlign: 'left', fontSize: 20 }}>
              {format(page.date, formats.date)}
            </Text>
          </Text>
        </Page>
      </Document>
    </PDFViewer>
  )
}

export default ReportTravelerLastPage
