import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import {
  CompanyDto,
  RatesPaperAdderDto,
  RatesScoringAdderDto,
  ratesScoringAdderValidationSchema
} from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { showFormErrorsPrompt } from './_template/form/FormErrorsPrompt'

interface RatesScoringAddersAddEditDialogProps {
  onClose: () => void
  onSave: (paper: RatesScoringAdderDto) => Promise<void | RatesPaperAdderDto>
  open: boolean
  values: RatesScoringAdderDto
  vendors: CompanyDto[]
}

export default function RatesScoringAddersAddEditDialog({
  onClose,
  onSave,
  open,
  values,
  vendors
}: RatesScoringAddersAddEditDialogProps) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={values}
        validateOnBlur
        validateOnChange
        validationSchema={ratesScoringAdderValidationSchema}
        onSubmit={async (values, submitProps) => {
          await onSave(values).finally(() => {
            submitProps.setSubmitting(false)
          })
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Add Scoring Adders</DialogTitle>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={isSubmitting}
                        error={Boolean(touched.company && errors.company)}
                        fullWidth
                        helperText={touched.company && errors.company}
                        label='Vendor'
                        name='name'
                        onBlur={handleBlur}
                        onChange={e => {
                          setFieldValue('company', JSON.parse(e.target.value))
                        }}
                        select
                        value={
                          values?.company
                            ? JSON.stringify(
                                vendors.filter(
                                  vendor => vendor.id === values.company!.id
                                )[0]
                              )
                            : ''
                        }
                      >
                        {vendors
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          .map(vendor => (
                            <MenuItem value={JSON.stringify(vendor)} key={vendor.id}>
                              {vendor.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.ect && errors.ect)}
                      fullWidth
                      helperText={touched.ect && errors.ect}
                      label='ECT / Mullen'
                      name='ect'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ect}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.adder && errors.adder)}
                      fullWidth
                      helperText={touched.adder && errors.adder}
                      label='Adder'
                      name='adder'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.adder || ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.adderSetup && errors.adderSetup)}
                      fullWidth
                      helperText={touched.adderSetup && errors.adderSetup}
                      label='Adder Setup'
                      name='adderSetup'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.adderSetup}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(touched.adderPerMsf && errors.adderPerMsf)}
                      fullWidth
                      helperText={touched.adderPerMsf && errors.adderPerMsf}
                      label='Adder Per MSF'
                      name='adderPerMsf'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.adderPerMsf}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isActive}
                          disabled={isSubmitting}
                          name='isActive'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.isActive}
                        />
                      }
                      label='Active'
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button variant='text' color='secondary' onClick={onClose}>
                  CLOSE
                </Button>

                <Button
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
