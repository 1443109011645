import { Card, CardContent } from '@mui/material'
import React from 'react'

export default function DetailsForm({ children }: React.PropsWithChildren) {
  return (
    <Card variant='outlined' elevation={0}>
      <CardContent>{children}</CardContent>
    </Card>
  )
}
