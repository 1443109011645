import { Grid, Hidden, Paper, Typography } from '@mui/material'
import React, { Children } from 'react'

export type GuestLayoutProps = {
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  title: string
  subtitle?: string
}

export default function GuestLayout({
  children,
  title,
  subtitle,
  handleSubmit
}: React.PropsWithChildren<GuestLayoutProps>) {
  return (
    <form onSubmit={handleSubmit}>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <Hidden xsDown>
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundColor: t =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              backgroundImage: `url(${process.env.PUBLIC_URL + '/NGWhenItMatters.png'})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '80%'
            }}
          />
        </Hidden>

        <Grid item xs={12} sm={8} md={5} component={Paper} square sx={{ p: 2, pl: 3 }}>
          <Grid container spacing={1} alignItems='center' justifyContent='center'>
            <Grid item height={150} />

            <Grid item xs={12}>
              <Typography variant='h5' align='center'>
                {title}
              </Typography>

              {subtitle && (
                <Typography variant='caption' align='center' sx={{ color: '#7F7F7F' }}>
                  {subtitle}
                </Typography>
              )}
            </Grid>

            {Children.map(children, child => (
              <Grid item xs={12}>
                {child}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}
