import { Chip, Grid } from '@mui/material'
import React, { Children } from 'react'

interface Applied {
  label: string
  when: boolean
  onDelete: ((event: any) => void) | undefined
}

interface ListFilterProps {
  applied?: Applied[]
}

export default function ListFilters({
  applied,
  children
}: React.PropsWithChildren<ListFilterProps>) {
  return (
    <Grid item xs={12}>
      <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
        {Children.map(children, (child, index) => (
          <Grid item xs={12} key={index}>
            {child}
          </Grid>
        ))}
      </Grid>

      {applied && applied.some(filter => filter.when) && (
        <Grid alignItems='center' container spacing={1} sx={{ mt: 0.2 }}>
          {applied
            .filter(filter => filter.when)
            .map(filter => (
              <Grid item xs={12} sm='auto'>
                <Chip
                  color='primary'
                  label={filter.label}
                  onDelete={filter.onDelete}
                  variant='outlined'
                />
              </Grid>
            ))}
        </Grid>
      )}
    </Grid>
  )
}
