import { TabPanel } from '@mui/lab'
import { Card, CardContent, Grid } from '@mui/material'
import React from 'react'

type DetailsTabProps = {
  value: string
}

export default function DetailsTab({
  children,
  value
}: React.PropsWithChildren<DetailsTabProps>) {
  return <TabPanel value={value}>{children}</TabPanel>
}
