import * as yup from 'yup'
import {
  fieldTypeMessage,
  maxIntMessage,
  maxIntValue,
  maxString200Message,
  maxString200Value,
  minIntMessage,
  minIntValue,
  requiredFieldMessage
} from '../constants'
import { JobDto } from './job'
import { JobPauseReasonTypeDto } from './jobPauseReasonType'
import { JobStatusTypeDto } from './jobStatusType'
import { JobStopReasonTypeDto } from './jobStopReasonType'

export class JobStatusDto {
  id?: string = undefined
  job?: JobDto = undefined
  jobStatusType?: JobStatusTypeDto = undefined
  note?: string = undefined
  jobPauseReasonType?: JobPauseReasonTypeDto = undefined
  jobStopReasonType?: JobStopReasonTypeDto = undefined
  lastBandingQuantity?: number = undefined
  totalQuantityProcessed?: number = undefined
  quantityToReturnToRawInventory?: number = undefined
  scrappedQuantity?: number = undefined
  customerOrderQuantity?: number = undefined // This number is only used on the front-end for the Job Is Complete dialog validation
}

export const jobStatusValidationSchema = yup.object<JobStatusDto>({
  jobPauseReasonType: yup.object().when('jobStatusType', (values, schema) => {
    const specialIdentifier = values[0]?.specialIdentifier
    if ('PAUSED' === specialIdentifier) {
      return schema.required(requiredFieldMessage)
    }

    return schema.nullable()
  }),
  jobStopReasonType: yup.object().when('jobStatusType', (values, schema) => {
    const specialIdentifier = values[0]?.specialIdentifier
    if ('STOPPED' === specialIdentifier) {
      return schema.required(requiredFieldMessage)
    }

    return schema.nullable()
  }),
  lastBandingQuantity: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .min(minIntValue, minIntMessage)
    .max(maxIntValue, maxIntMessage)
    .when('jobStatusType', (values, schema) => {
      const specialIdentifier = values[0]?.specialIdentifier
      if (specialIdentifier === 'STOPPED' || specialIdentifier === 'COMPLETED') {
        return schema.required(requiredFieldMessage)
      }

      return schema.nullable()
    }),
  totalQuantityProcessed: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .min(minIntValue, minIntMessage)
    .max(maxIntValue, maxIntMessage)
    .when('jobStatusType', (values, schema) => {
      const specialIdentifier = values[0]?.specialIdentifier
      if (specialIdentifier === 'COMPLETED') {
        return schema.required(requiredFieldMessage)
      }

      return schema.nullable()
    }),
  quantityToReturnToRawInventory: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .min(minIntValue, minIntMessage)
    .max(maxIntValue, maxIntMessage)
    .when('jobStatusType', (values, schema) => {
      const specialIdentifier = values[0]?.specialIdentifier
      if (specialIdentifier === 'COMPLETED') {
        return schema.required(requiredFieldMessage)
      }

      return schema.nullable()
    }),
  scrappedQuantity: yup
    .number()
    .integer(fieldTypeMessage('Whole Number'))
    .typeError(fieldTypeMessage('Whole Number'))
    .min(minIntValue, minIntMessage)
    .max(maxIntValue, maxIntMessage)
    .when('jobStatusType', (values, schema) => {
      const specialIdentifier = values[0]?.specialIdentifier
      if (specialIdentifier === 'COMPLETED') {
        return schema.required(requiredFieldMessage)
      }

      return schema.nullable()
    }),
  note: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .when(
      ['jobStatusType', 'totalQuantityProcessed', 'customerOrderQuantity'],
      (values, schema) => {
        const specialIdentifier = values[0]?.specialIdentifier
        if (
          specialIdentifier === 'PAUSED' ||
          specialIdentifier === 'STOPPED' ||
          (specialIdentifier === 'COMPLETED' && values[1] < values[2])
        ) {
          return schema.required(requiredFieldMessage)
        }

        return schema.nullable()
      }
    )
})
