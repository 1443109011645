import { ApiResponse, OpportunityDto } from 'dtos'
import apiClient from '.'
import { OpportunitiesParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getOpportunities = (parameters: OpportunitiesParameters) =>
  apiClient
    .get<ApiResponse<OpportunityDto[]>>(
      '/api/opportunities' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getOpportunityById = (id: string) =>
  apiClient
    .get<ApiResponse<OpportunityDto>>('/api/opportunities/' + id)
    .then(({ data }) => data)

export const createOpportunity = (values: OpportunityDto) =>
  apiClient
    .post<ApiResponse<OpportunityDto>>('/api/opportunities', values)
    .then(({ data }) => data)

export const updateOpportunity = (values: OpportunityDto) =>
  apiClient
    .put<ApiResponse<OpportunityDto>>('/api/opportunities', values)
    .then(({ data }) => data)
