import { LoadingButton } from '@mui/lab'
import { Button, Divider, TextField, Typography } from '@mui/material'
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import Popper from '@mui/material/Popper'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import apiClient, * as api from 'api'
import { IntFormat } from 'components'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import {
  Details,
  DetailsActions,
  DetailsForm,
  DetailsHeaderCard,
  DetailsTab
} from 'components/_template/details'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import { CompanyDto, ShippingDto, shippingValidationSchema } from 'dtos'
import { Formik, getIn, setNestedObjectValues } from 'formik'
import { enqueueSnackbar } from 'notistack'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { useNavigate, useParams } from 'react-router-dom'
import { ListChildComponentProps, VariableSizeList } from 'react-window'
import { format } from 'theme'
import { errorHandling } from '../constants'
import ReportBillOfLading from './ReportBillOfLading'

const LISTBOX_PADDING = 8
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING
  }
  return (
    <Typography component='li' {...dataSet[0]} noWrap style={inlineStyle}>
      {`${dataSet[1].name}`}
    </Typography>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props
  const itemData: React.ReactChild[] = []
  ;(children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item)
      itemData.push(...(item.children || []))
    }
  )

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true
  })
  const itemCount = itemData.length
  // Make items 36 pixels tall on a "small" or larger screen, and 48 piels tall otherwise
  const itemSize = smUp ? 36 : 48

  // Only show a maximum of 8 items in the dropdown
  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemCount * itemSize
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          // Every item is the same height, so don't set the itemSize individually for each index
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
})

export default function OpportunityDetails() {
  const navigate = useNavigate()
  const [initialValues, setInitialValues] = useState<ShippingDto>(new ShippingDto())
  const [companies, setCompanies] = useState<CompanyDto[]>([])
  const { id } = useParams()

  useEffect(() => {
    api
      .getCompanyShippingCompanies()
      .then(({ value }) => {
        setCompanies(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
  }, [])

  useEffect(() => {
    if (id && id.toLowerCase() !== 'new' && id.toLowerCase() !== 'undefined') {
      api
        .getShippingById(id)
        .then(response => {
          setInitialValues(response.value)
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
        .finally(() => {})
    } else {
      setInitialValues(new ShippingDto())
    }
  }, [id])

  const openReportBillOfLadingWindow = () => {
    // setIsLoading(true)
    api
      .getBillOfLadingReportByShippingId(initialValues.id!)
      .then(res => {
        const newWindow = window.open('', '_blank', 'width=800,height=600')
        if (newWindow) {
          newWindow.document.body.innerHTML =
            '<div id="bill-of-lading-report-container"></div>'
          const root = createRoot(
            newWindow.document.getElementById(
              'bill-of-lading-report-container'
            ) as HTMLElement
          )

          root.render(<ReportBillOfLading reportBillOfLadingDto={res.value} />)
        } else {
          console.error('Failed to open a new window.')
        }
      })
      .finally(() => {})
  }

  return (
    <Layout title='Shipping Details'>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        validationSchema={shippingValidationSchema}
        onSubmit={async (values, submitProps) => {
          await (values.id ? apiClient.put : apiClient.post)('/api/shipping', values)
            .then(res => {
              enqueueSnackbar('Shipping Has Been Saved!', {
                variant: 'success'
              })

              setInitialValues(res.data.value)
              submitProps.resetForm({ values: res.data.value })
            })
            .catch((errors: string[]) => {
              errorHandling(errors)
            })
        }}
      >
        {({
          dirty,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          const clearWhenDirty = (value: any) => {
            return dirty ? 'TBD' : value
          }

          const clearLaterSections = (section: 'jobDetails' | 'bundles') => {
            if (section === 'jobDetails') {
              setFieldValue('shippingJobs', [])
            }

            if (section === 'jobDetails' || 'bundles') {
              setFieldValue('trailerNumber', undefined)
              setFieldValue('door', undefined)
              setFieldValue('sealNumber', undefined)
            }
          }

          const isSectionDisabled = (section: 'bundles' | 'loading' | 'billOfLading') => {
            // If values have been changed (I.e. values is not equal to initialValues,) OR if there
            // are missing values, disable the section.
            if (section === 'bundles') {
              return (
                initialValues.company?.id !== values.company?.id ||
                initialValues.scheduleDate !== values.scheduleDate ||
                initialValues.scheduleTime !== values.scheduleTime ||
                initialValues.carrier !== values.carrier ||
                initialValues.movementNumber !== values.movementNumber ||
                !values.company ||
                !values.scheduleDate ||
                !values.scheduleTime ||
                !values.carrier ||
                !values.movementNumber
              )
            }

            if (section === 'loading') {
              return (
                initialValues.shippingJobs?.length !== values.shippingJobs?.length ||
                values.shippingJobs?.length === 0 ||
                !values.shippingJobs?.some(
                  shippingJob =>
                    shippingJob.bundlesToShip !== 0 ||
                    shippingJob.bundlesToShip.toString() !== '0'
                )
              )
            }

            if (section === 'billOfLading') {
              return (
                initialValues.trailerNumber !== values.trailerNumber ||
                initialValues.door !== values.door ||
                initialValues.sealNumber !== values.sealNumber ||
                !values.trailerNumber ||
                !values.door ||
                !values.sealNumber
              )
            }
          }

          return (
            <Details
              header={<DetailsHeaderCard title='Shipping Details' />}
              tabs={[{ value: 'tab1', label: 'Details' }]}
              onSubmit={e => e.preventDefault()}
            >
              <DetailsTab value='tab1'>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Job Details
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            Total Bundles To Ship:{' '}
                            {clearWhenDirty(values.totalBundles ?? 'TBD')}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            Total Weight: {clearWhenDirty(values.totalWeight ?? 'TBD')}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            disabled={isSubmitting}
                            disableListWrap
                            getOptionLabel={option => option.name}
                            onChange={(_e, value) => {
                              setFieldValue('company', value)
                              clearLaterSections('jobDetails')
                            }}
                            options={companies}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label='Customer'
                                error={Boolean(touched.company && errors.company)}
                                helperText={touched.company && errors.company}
                              />
                            )}
                            // The Autocomplete is only blank when the value is null, NOT undefined, so this must be "values.company ?? null"
                            value={values.company ?? null}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(touched.scheduleDate && errors.scheduleDate)}
                            fullWidth
                            helperText={touched.scheduleDate && errors.scheduleDate}
                            InputLabelProps={{ shrink: true }}
                            label='Service Date'
                            name='scheduleDate'
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e)
                              clearLaterSections('jobDetails')
                            }}
                            type='date'
                            value={values.scheduleDate}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(touched.scheduleTime && errors.scheduleTime)}
                            fullWidth
                            helperText={touched.scheduleTime && errors.scheduleTime}
                            InputLabelProps={{ shrink: true }}
                            label='Service Time'
                            name='scheduleTime'
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e)
                              clearLaterSections('jobDetails')
                            }}
                            type='time'
                            value={values.scheduleTime}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(touched.carrier && errors.carrier)}
                            fullWidth
                            helperText={touched.carrier && errors.carrier}
                            label='Carrier'
                            name='carrier'
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e)
                              clearLaterSections('jobDetails')
                            }}
                            value={values.carrier || ''}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting}
                            error={Boolean(
                              touched.movementNumber && errors.movementNumber
                            )}
                            fullWidth
                            helperText={touched.movementNumber && errors.movementNumber}
                            label='Movement Number'
                            name='movementNumber'
                            onBlur={handleBlur}
                            onChange={e => {
                              handleChange(e)
                              clearLaterSections('jobDetails')
                            }}
                            value={values.movementNumber || ''}
                          />
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Bundles
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            Job Number
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            Available Bundles
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            Bundles on Truck
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <Typography
                            variant='body1'
                            sx={{ color: '#7F7F7F', fontWeight: 600 }}
                          >
                            Bundles to Ship
                          </Typography>
                        </Grid>

                        {values.shippingJobs?.map((shippingJob, index) => (
                          <React.Fragment key={index}>
                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', mt: '16px' }}
                              >
                                {shippingJob.job?.jobNumber}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', mt: '16px' }}
                              >
                                {/* Available Bundles only counts bundles that do not have a forklift pallet location of Truck that are not on another Shipping Details Bundles section */}
                                {format(
                                  shippingJob.job?.finishedGoodsInventory?.filter(
                                    finishedGoodsInventory =>
                                      finishedGoodsInventory.forkliftPalletLocation
                                        ?.specialIdentifier !== 'TRUCK' &&
                                      (finishedGoodsInventory.shippingItem == null ||
                                        shippingJob.shippingItems?.some(
                                          shippingItem =>
                                            shippingItem.id ==
                                            finishedGoodsInventory.shippingItem?.id
                                        ))
                                  ).length
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <Typography
                                variant='body1'
                                sx={{ color: '#7F7F7F', mt: '16px' }}
                              >
                                {/* Bundles on Truck only counts bundles that have a forklift pallet location of Truck that are not on another Shipping Details Bundles section */}
                                {format(
                                  shippingJob.shippingItems?.filter(
                                    shippingItem =>
                                      shippingItem.finishedGoodsInventory
                                        ?.forkliftPalletLocation?.specialIdentifier ==
                                      'TRUCK'
                                  ).length
                                )}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                              <TextField
                                disabled={isSubmitting || isSectionDisabled('bundles')}
                                error={Boolean(
                                  getIn(
                                    touched,
                                    `shippingJobs[${index}].bundlesToShip`
                                  ) &&
                                    getIn(errors, `shippingJobs[${index}].bundlesToShip`)
                                )}
                                fullWidth
                                helperText={
                                  getIn(
                                    touched,
                                    `shippingJobs[${index}].bundlesToShip`
                                  ) &&
                                  getIn(errors, `shippingJobs[${index}].bundlesToShip`)
                                }
                                label='Bundles to Ship'
                                name={`shippingJobs[${index}].bundlesToShip`}
                                size='small'
                                margin='dense'
                                onBlur={handleBlur}
                                onChange={e => {
                                  clearLaterSections('bundles')
                                  handleChange(e)
                                }}
                                InputProps={{ inputComponent: IntFormat as any }}
                                value={values.shippingJobs![index].bundlesToShip || 0}
                              />
                            </Grid>
                          </React.Fragment>
                        ))}
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Loading
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting || isSectionDisabled('loading')}
                            error={Boolean(touched.trailerNumber && errors.trailerNumber)}
                            fullWidth
                            helperText={touched.trailerNumber && errors.trailerNumber}
                            label='Trailer Number'
                            name='trailerNumber'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.trailerNumber || ''}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting || isSectionDisabled('loading')}
                            error={Boolean(touched.door && errors.door)}
                            fullWidth
                            helperText={touched.door && errors.door}
                            label='Door'
                            name='door'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.door || ''}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <TextField
                            disabled={isSubmitting || isSectionDisabled('loading')}
                            error={Boolean(touched.sealNumber && errors.sealNumber)}
                            fullWidth
                            helperText={touched.sealNumber && errors.sealNumber}
                            label='Seal Number'
                            name='sealNumber'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.sealNumber || ''}
                          />
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          justifyContent='space-between'
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant='body1'
                              sx={{ color: '#2780E3', fontWeight: 600 }}
                            >
                              Bill of Lading
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Button
                            disabled={isSubmitting || isSectionDisabled('billOfLading')}
                            variant='outlined'
                            onClick={openReportBillOfLadingWindow}
                          >
                            Print Bill of Lading
                          </Button>
                        </Grid>
                      </Grid>
                    </DetailsForm>
                  </Grid>

                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      The Shipping Details form has four sections, which are filled out
                      separately, in order.||The first section, "Job Details", contains
                      the Customer, Service Date, Service Time, Carrier, and Movement
                      Number fields. All fields in this section are required.||The second
                      section, "Bundles", lists all open jobs for the selected customer
                      where there are bundles in finished goods or staging that are not
                      already scheduled for another shipment. It contains a Quantity to
                      Ship field for each job. All Quantity to Ship fields default to
                      0.||The third section, "Loading", contains the Trailer Number field
                      and the Seal Number field.||The fourth section, "Bill of Lading",
                      contains the Print Bill of Lading button.||Users must enter in the
                      Job Details section and then save which enables the Bundles
                      section.||Users must enter in the Bundles section and then save
                      which enables the Loading section.||Users must enter in the Loading
                      section and then save which enables the Print Bill of Lading
                      section.||In a future phase, users will be prevented from changing
                      Customer on Shipments. Users should not change customers on
                      shipments after a shipment has been created.||After the Bundles
                      section is filled in and the form is saved, weight is calculated for
                      the selected bundles as (Basis Weight value on the quote x (Customer
                      Order Quantity value on the quote / Outs value on the quote)) +
                      (weight for unit type value on the quote x Quantity to Ship) for
                      each job and a Forklift To Do is created for each bundle to be put
                      in staging.||After the Trailer Number field is filled in and the
                      form is saved, any remaining Forklift To Dos for the bundles to be
                      put in staging are removed and a Forklift To Do is created for each
                      bundle to be put on the truck.||The user can save the Trailer Number
                      field without the Seal Number field filled in, but the Seal Number
                      field must be filled in to enable the Print Bill of Lading
                      section.||Unit type weights are not editable, set as Bale=0 lbs.,
                      Large Pallet=50 lbs., Small Pallet=25 lbs., Cores=5 lbs.||Clicking
                      the Print Bill of Lading button generates a PDF. The user can open
                      the PDF to print if needed.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DetailsTab>

              <DetailsActions>
                <Button
                  color='secondary'
                  onClick={() => {
                    navigate('/shipping')
                  }}
                  variant='text'
                >
                  BACK
                </Button>

                <LoadingButton
                  color='primary'
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </LoadingButton>
              </DetailsActions>
            </Details>
          )
        }}
      </Formik>
    </Layout>
  )
}
