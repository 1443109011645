import { enqueueSnackbar } from 'notistack'

export const errorHandling = (errors: string[]) => {
  if (Array.isArray(errors) && errors.length > 0) {
    errors?.forEach(error => {
      enqueueSnackbar(error, { variant: 'error' })
    })
  } else {
    enqueueSnackbar('There Was An Error.', { variant: 'error' })
  }
}

export const fieldTypeMessage = (titleCaseFieldType: string) => {
  return `Must Be A Valid ${titleCaseFieldType}`
}

/**
 * For UTC date/time stamps. Field name must be suffixed by Utc (E.g., createdDateTimeUtc)
 * SQL: datetime2(7) or date
 * C#: DateTime or DateOnly
 * TS: Date
 */
export const maxDateValue: Date = new Date('9999/12/31')
export const maxDateMessage = `Cannot Be More Than ${maxDateValue}`

/**
 * For any number that is not *always* a whole number:
 * SQL: decimal(19, 4)
 * C#: decimal
 * TS: number
 */
export const maxDecimal194Value: number = 999999999999999.9999
export const maxDecimal194Message = `Cannot Be More Than ${maxDecimal194Value}`

/**
 * For whole numbers:
 * SQL: int
 * C#: int
 * TS: number
 */
export const maxIntValue: number = 99999
export const maxLargeIntValue: number = 99999999
export const maxIntMessage = `Cannot Be More Than ${maxIntValue}`
export const maxLargeIntMessage = `Cannot Be More Than ${maxLargeIntValue}`

/**
 * For strings up to 2000 chars, like multi-line text fields:
 * SQL: nvarchar(2000)
 * C#: string
 * TS: string
 * This is spefically defined so that we can change it here for it to take effect everywhere if necessary
 */
export const maxString2000Value: number = 2000
export const maxString2000Message = `Cannot Be More Than ${maxString2000Value} Characters`

/**
 * For strings up to 200 chars, like single-line text fields:
 * SQL: nvarchar(200)
 * C#: string
 * TS: string
 * This is spefically defined so that we can change it here for it to take effect everywhere if necessary
 */
export const maxString200Value: number = 200
export const maxString200Message = `Cannot Be More Than ${maxString200Value} Characters`

/**
 * For strings up to 50 chars, like short text fields:
 * SQL: nvarchar(50)
 * C#: string
 * TS: string
 * This is spefically defined so that we can change it here for it to take effect everywhere if necessary
 */
export const maxString50Value: number = 50
export const maxString50Message = `Cannot Be More Than ${maxString50Value} Characters`

export const maxString100Value: number = 100
export const maxString100Message = `Cannot Be More Than ${maxString100Value} Characters`

export const maxTextFieldMessage = (textFieldLength: number) => {
  return textFieldLength === 1
    ? `Cannot Be More Than ${textFieldLength} Character`
    : `Cannot Be More Than ${textFieldLength} Characters`
}

/**
 * For UTC date/time stamps. Field name must be suffixed by Utc (E.g., createdDateTimeUtc)
 * SQL: datetime2(7) or date
 * C#: DateTime or DateOnly
 * TS: Date
 */
export const minDateValue: Date = new Date('1000/1/1')
export const minDateMessage = `Cannot Be Less Than ${maxDateValue}`

/**
 * For whole numbers:
 * SQL: int
 * C#: int
 * TS: number
 */
export const minIntValue: number = 0
export const minIntMessage = `Cannot Be Less Than ${minIntValue}`

/**
 * For money / currency up to 4 decimals
 * SQL: decimal(19, 4)
 * C#: decimal
 * TS: number
 */
export const minNegativeDecimal194Value: number = -999999999999999.9999
export const minNegativeDecimal194Message = `Cannot Be Less Than ${minNegativeDecimal194Value}`

/**
 * For money / currency up to 4 decimals
 * SQL: decimal(19, 4)
 * C#: decimal
 * TS: number
 */
export const minPositiveDecimal194Value: number = 0.0
export const minPositiveDecimal194Message = `Cannot Be Less Than ${minPositiveDecimal194Value}`

export const minTextFieldMessage = (textFieldLength: number) => {
  return textFieldLength === 1
    ? `Cannot Be Less Than ${textFieldLength} Character`
    : `Cannot Be Less Than ${textFieldLength} Characters`
}

export const requiredFieldMessage = 'Required'
