import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'
import {
  AddressDto,
  CompanyDto,
  PersonDto,
  QuoteDto,
  QuoteLineItemDto,
  QuoteRevisionDto,
  ReportPurchaseOrderDto,
  VendorOrderDto
} from 'dtos'
import moment from 'moment'
import { format, formats } from 'theme'
import { getPaperLabel } from './QuoteDetails'

const styles = StyleSheet.create({
  cell: {
    textAlign: 'left',
    width: '33%'
  },
  column: {
    flex: 1,
    padding: 5 // Adjust as needed
  },
  fullWidthCell: {
    textAlign: 'left',
    width: '100%'
  },
  headerCellWhite: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
    width: '33%'
  },
  headerCellGreen: {
    color: 'green',
    fontWeight: 'bold',
    textAlign: 'left',
    width: '33%'
  },
  horizontalLine: {
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    marginBottom: 5,
    marginTop: 5
  },
  logo: {
    height: 75,
    width: 250
  },
  page: {
    backgroundColor: '#E4E4E4',
    flexDirection: 'row',
    fontSize: 10
  },
  section: {
    flexGrow: 1,
    margin: 10,
    padding: 10
  },
  table: {
    alignItems: 'center',
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    flexDirection: 'row'
  },
  tableGreen: {
    alignItems: 'center',
    backgroundColor: 'green',
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    flexDirection: 'row'
  },
  textBlock: {
    marginBottom: 10
  }
})

interface ReportPurchaseOrderProps {
  reportPurchaseOrderDto: ReportPurchaseOrderDto
  vendorOrderId: string
}

const TableWithPDFButton = ({
  reportPurchaseOrderDto,
  vendorOrderId
}: ReportPurchaseOrderProps) => {
  let vendorAddress =
    format(
      reportPurchaseOrderDto.vendor?.addresses.find(
        address => address.addressType?.name == 'Physical'
      ),
      formats.address
    ) ?? new AddressDto()
  let dateGenerated = format(moment(), formats.date)
  let opportunityQuote = reportPurchaseOrderDto.opportunity?.quote ?? new QuoteDto()
  let opportunityQuoteRevision = opportunityQuote.quoteRevision ?? new QuoteRevisionDto()
  let opportunityQuoteRevisionLineItem =
    opportunityQuoteRevision.quoteLineItem ?? new QuoteLineItemDto()
  let vendorOrder =
    opportunityQuoteRevisionLineItem.vendorOrders?.find(
      vendorOrder => (vendorOrder.id = vendorOrderId)
    ) ?? new VendorOrderDto()
  let opportunitySalesperson =
    reportPurchaseOrderDto.opportunity?.salesperson ?? new PersonDto()
  let scoringArray = [
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring1 &&
      `(1) ${opportunityQuoteRevisionLineItem.scoringCorrugatorScoring1}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring2 &&
      `(2) ${opportunityQuoteRevisionLineItem.scoringCorrugatorScoring2}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring3 &&
      `(3) ${opportunityQuoteRevisionLineItem.scoringCorrugatorScoring3}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring4 &&
      `(4) ${opportunityQuoteRevisionLineItem.scoringCorrugatorScoring4}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring5 &&
      `(5) ${opportunityQuoteRevisionLineItem.scoringCorrugatorScoring5}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring6 &&
      `(6) ${opportunityQuoteRevisionLineItem.scoringCorrugatorScoring6}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring7 &&
      `(7) ${opportunityQuoteRevisionLineItem.scoringCorrugatorScoring7}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring8 &&
      `(8) ${opportunityQuoteRevisionLineItem.scoringCorrugatorScoring8}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring9 &&
      `(9) ${opportunityQuoteRevisionLineItem.scoringCorrugatorScoring9}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring10 &&
      `(10) ${opportunityQuoteRevisionLineItem.scoringCorrugatorScoring10}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring11 &&
      `(11) ${opportunityQuoteRevisionLineItem.scoringCorrugatorScoring11}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring12 &&
      `(12) ${opportunityQuoteRevisionLineItem.scoringCorrugatorScoring12}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring13 &&
      `(13) ${opportunityQuoteRevisionLineItem.scoringCorrugatorScoring13}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring14 &&
      `(14) ${opportunityQuoteRevisionLineItem.scoringCorrugatorScoring14}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring15 &&
      `(15) ${opportunityQuoteRevisionLineItem.scoringCorrugatorScoring15}`,
    opportunityQuoteRevisionLineItem.scoringCorrugatorScoring16 &&
      `(16) ${opportunityQuoteRevisionLineItem.scoringCorrugatorScoring16}`
  ]
  // Only use ".join()" on scoring values that exist
  let scoringString = scoringArray.filter(scoringValue => scoringValue).join(', ')

  return (
    <div>
      <PDFViewer style={{ width: '100%', height: '100%' }}>
        <Document>
          <Page size='LETTER' style={styles.page}>
            <View style={styles.section}>
              {/* Render the information */}
              {/* Use the Image component from @react-pdf/renderer */}
              <Image
                src={process.env.PUBLIC_URL + '/nglogo.jpg'}
                style={{ ...styles.logo }}
              />
              {/* Add the provided code snippet */}

              <Text style={styles.textBlock}>{''}</Text>
              <Text style={styles.textBlock}>
                <Text style={{ ...styles.headerCellGreen }}>Purchase Order</Text>
                {'\n'}
                {vendorOrder.poNumberToVendor}
              </Text>

              <Text style={styles.textBlock}>
                <Text style={{ ...styles.headerCellGreen }}>Date</Text>
                {'\n'}
                {dateGenerated}
              </Text>
              <View style={styles.horizontalLine} />

              <View style={styles.table}>
                <View style={styles.column}>
                  <Text
                    style={{
                      ...styles.headerCellGreen
                    }}
                  >
                    Vendor Name
                  </Text>
                  <Text style={{ ...styles.fullWidthCell }}>
                    {reportPurchaseOrderDto.vendor?.name}
                  </Text>
                  <Text style={{ ...styles.fullWidthCell }}>{vendorAddress}</Text>
                </View>
                <View style={styles.column}>
                  <Text
                    style={{
                      ...styles.headerCellGreen
                    }}
                  >
                    Ship To
                  </Text>
                  <Text style={{ ...styles.fullWidthCell }}>{'JackPack'}</Text>
                  <Text style={{ ...styles.fullWidthCell }}>
                    {'2100 T A Mansour Blvd'}
                  </Text>
                  <Text style={{ ...styles.fullWidthCell }}>{'Flint, MI 48532'}</Text>
                  <Text style={{ ...styles.fullWidthCell }}>{''}</Text>
                </View>
              </View>
              <View style={styles.horizontalLine} />

              <Text style={styles.textBlock}>
                <Text style={{ ...styles.headerCellGreen }}>Instructions</Text>
                {'\n'}
                {vendorOrder.additionalInstructions}
              </Text>

              <View style={{ ...styles.tableGreen }}>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Payment Terms
                </Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Item Number
                </Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Gross Width
                </Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Gross Length
                </Text>
              </View>
              <View style={styles.table}>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevision.paymentTerms}
                </Text>
                <Text style={{ ...styles.cell }}>{vendorOrder.vendorItemNumber}</Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.grossWidth}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.grossLength}
                </Text>
              </View>

              <Text style={styles.textBlock}>{''}</Text>
              <View style={{ ...styles.tableGreen }}>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Board
                </Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  ECT
                </Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Flute
                </Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                ></Text>
              </View>
              <View style={styles.table}>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.boardType?.name}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.ectMullen}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.flute}
                </Text>
                <Text style={{ ...styles.cell }}></Text>
              </View>

              <Text style={styles.textBlock}>{''}</Text>
              <View style={{ ...styles.tableGreen }}>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Vendor Order Quantity
                </Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Ordered MSF
                </Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Cost Per Piece
                </Text>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Extended Cost
                </Text>
              </View>
              <View style={styles.table}>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.checkValuesVendorOrderQuantity}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.assemblyOrderMsf}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.checkValuesOriginalCostPerPiece ??
                    opportunityQuoteRevisionLineItem.checkValuesCostPerPiece}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.checkValuesOriginalExtendedCost ??
                    opportunityQuoteRevisionLineItem.checkValuesExtendedCost}
                </Text>
              </View>

              <Text style={styles.textBlock}>{''}</Text>
              <View style={{ ...styles.tableGreen }}>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Scores
                </Text>
              </View>
              <View style={styles.table}>
                <Text style={{ ...styles.fullWidthCell }}>{scoringString}</Text>
              </View>

              <Text style={styles.textBlock}>{''}</Text>
              <View style={{ ...styles.tableGreen }}>
                <Text
                  style={{
                    ...styles.headerCellWhite
                  }}
                >
                  Cost MSF
                </Text>
              </View>

              <View style={{ ...styles.table }}>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Paper Cost
                </Text>
              </View>
              <Text style={styles.textBlock}>{''}</Text>
              <View style={{ ...styles.table }}>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Item
                </Text>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Setup Fee
                </Text>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Adder per MSF
                </Text>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Cost per MSF
                </Text>
              </View>

              <View style={styles.table}>
                <Text style={{ ...styles.cell }}>
                  {`Paper (MSF range: ${getPaperLabel(
                    opportunityQuoteRevisionLineItem.assemblyOrderMsf ?? -1
                  )})`}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.paperCostSetupFee}
                </Text>
                <Text style={{ ...styles.cell }}>{'N/A'}</Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.paperCostPerMsf}
                </Text>
              </View>
              {opportunityQuoteRevisionLineItem.quotePaperAdders?.map(paperAdder => (
                <View style={styles.table}>
                  <Text style={{ ...styles.cell }}>{paperAdder.adder}</Text>
                  <Text style={{ ...styles.cell }}>{paperAdder.adderSetup}</Text>
                  <Text style={{ ...styles.cell }}>{paperAdder.costPerMsf}</Text>
                </View>
              ))}
              <View style={styles.table}>
                <Text style={{ ...styles.cell }}>{'Subtotal'}</Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.paperCostSubtotalSetupFee}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.paperCostSubtotalCostPerMsf}
                </Text>
              </View>

              <Text style={styles.textBlock}>{''}</Text>

              <View style={{ ...styles.table }}>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Blend Cost
                </Text>
              </View>
              <Text style={styles.textBlock}>{''}</Text>
              <View style={{ ...styles.table }}>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Item
                </Text>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Setup Fee
                </Text>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Adder per MSF
                </Text>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Cost per MSF
                </Text>
              </View>
              {opportunityQuoteRevisionLineItem.quoteBlendAdders?.map(blend => (
                <View style={styles.table}>
                  <Text style={{ ...styles.cell }}>{blend.adder}</Text>
                  <Text style={{ ...styles.cell }}>{blend.adderSetup}</Text>
                  <Text style={{ ...styles.cell }}>{blend.costPerMsf}</Text>
                </View>
              ))}
              <View style={styles.table}>
                <Text style={{ ...styles.cell }}>{'Subtotal'}</Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.blendCostSubtotalSetupFee}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.blendCostSubtotalCostPerMsf}
                </Text>
              </View>

              <Text style={styles.textBlock}>{''}</Text>

              <View style={{ ...styles.table }}>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Assembly Cost
                </Text>
              </View>
              <Text style={styles.textBlock}>{''}</Text>
              <View style={{ ...styles.table }}>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Item
                </Text>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Setup Fee
                </Text>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Adder per MSF
                </Text>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Cost per MSF
                </Text>
              </View>
              {opportunityQuoteRevisionLineItem.quoteAssemblyAdders?.map(
                assemblyAdder => (
                  <View style={styles.table}>
                    <Text style={{ ...styles.cell }}>{assemblyAdder.adder}</Text>
                    <Text style={{ ...styles.cell }}>{assemblyAdder.adderSetup}</Text>
                    <Text style={{ ...styles.cell }}>{assemblyAdder.costPerMsf}</Text>
                  </View>
                )
              )}
              <View style={styles.table}>
                <Text style={{ ...styles.cell }}>{'Subtotal'}</Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.assemblyCostSubtotalSetupFee}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.assemblyCostSubtotalCostPerMsf}
                </Text>
              </View>

              <Text style={styles.textBlock}>{''}</Text>

              <View style={{ ...styles.table }}>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Scoring Cost
                </Text>
              </View>
              <Text style={styles.textBlock}>{''}</Text>
              <View style={{ ...styles.table }}>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Item
                </Text>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Setup Fee
                </Text>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Adder per MSF
                </Text>
                <Text
                  style={{
                    ...styles.cell
                  }}
                >
                  Cost per MSF
                </Text>
              </View>

              <View style={styles.table}>
                <Text style={{ ...styles.cell }}>{'Subtotal'}</Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.scoringCostSubtotalSetupFee}
                </Text>
                <Text style={{ ...styles.cell }}>
                  {opportunityQuoteRevisionLineItem.scoringCostSubtotalCostPerMsf}
                </Text>
              </View>

              <View style={styles.horizontalLine} />

              <Text style={styles.textBlock}>
                For additional information about this purchase order, please contact:
              </Text>
              <Text style={styles.fullWidthCell}>
                {opportunitySalesperson.firstName} {opportunitySalesperson.lastName}
              </Text>
              <Text style={styles.fullWidthCell}>{opportunitySalesperson.title}</Text>
              <Text style={styles.fullWidthCell}>
                Email: {opportunitySalesperson.email}
              </Text>
              <Text style={styles.fullWidthCell}>
                Mobile: {opportunitySalesperson.mobilePhone}
              </Text>
              <Text style={styles.fullWidthCell}>
                Office: {opportunitySalesperson.workPhone}
              </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </div>
  )
}

export default TableWithPDFButton
