import { LoadingButton } from '@mui/lab'
import { Button, Checkbox, FormControlLabel, TextField } from '@mui/material'
import Grid from '@mui/material/Grid'
import { Layout } from 'components/_template'
import {
  Details,
  DetailsActions,
  DetailsForm,
  DetailsHeaderCard,
  DetailsTab
} from 'components/_template/details'
import { SyntheticEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function OpportunityDetails() {
  const navigate = useNavigate()
  const [value, setValue] = useState<string>('tab1')

  const handleTabsChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Layout title='Vechicle details'>
      <Details
        header={
          <DetailsHeaderCard title='Vehicle Details - Chevy Van - VIN:JH4CC2660RC002031 ' />
        }
        tabs={[{ value: 'tab1', label: 'Details' }]}
        onSubmit={e => e.preventDefault()}
      >
        {/* TAB 1 */}
        {/* TAB 1 */}
        {/* TAB 1 */}

        <DetailsTab value='tab1'>
          <DetailsForm>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='Make' placeholder='Leonard' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='Model' placeholder='Carter' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='Year' placeholder='Carter' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='VIN' placeholder='Carter' />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Next Maintenance Date'
                  placeholder='6/1/2023'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Maintenance Description'
                  placeholder='Maintenance Description'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='Notes' placeholder='Notes' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel control={<Checkbox />} label='Inactive' />
              </Grid>
            </Grid>
          </DetailsForm>
        </DetailsTab>

        <DetailsActions>
          <Button
            color='secondary'
            onClick={() => {
              navigate('/vehicles')
            }}
            variant='text'
          >
            BACK
          </Button>

          <LoadingButton
            color='primary'
            onClick={() => {
              navigate('/vehicles')
            }}
            type='submit'
            variant='contained'
          >
            <span>{'Create Vehicle'}</span>
          </LoadingButton>
        </DetailsActions>
      </Details>
    </Layout>
  )
}
