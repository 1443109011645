import { ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography
} from '@mui/material'

interface Props {
  development?: boolean
  children: string
}

export default function ExplanationAccordion({ development, children }: Props) {
  const childrenStringArray = children?.toString().split('||') ?? []
  const color = development ? '#964B00' : '#6e9c8a'
  const fontWeight = 500
  const fontStyle = 'italic'
  const textDecoration = development ? 'underline' : 'none'

  // If development is true and the application is deployed to QA or Production, do not show this component.
  if (development && process.env.NODE_ENV !== 'development') {
    return <></>
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant='body1' sx={{ color, fontWeight, fontStyle, textDecoration }}>
          {development
            ? 'Development Notes (will not appear in QA or Prod)'
            : 'Helpful Information'}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        {childrenStringArray.map((child, index) => (
          <>
            <Typography variant='body1' sx={{ color }}>
              {child}
            </Typography>

            {/* Only have divider between sentences. No leading or trailing dividers. */}
            {index !== childrenStringArray.length - 1 && (
              <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
            )}
          </>
        ))}
      </AccordionDetails>
    </Accordion>
  )
}
