import { ApiResponse, RatesInkDto } from 'dtos'
import { RatesInksParameters } from 'parameters'
import { URIQuery } from 'utils'
import apiClient from '.'

export const getRatesInks = (parameters: RatesInksParameters) =>
  apiClient
    .get<ApiResponse<RatesInkDto[]>>('/api/rates-inks' + URIQuery({ ...parameters }))
    .then(({ data }) => data)

export const getRatesInkById = (id: string) =>
  apiClient
    .get<ApiResponse<RatesInkDto>>('/api/rates-inks/' + id)
    .then(({ data }) => data)

export const createRatesInk = (values: RatesInkDto) =>
  apiClient
    .post<ApiResponse<RatesInkDto>>('/api/rates-inks', values)
    .then(({ data }) => data)

export const updateRatesInk = (values: RatesInkDto) =>
  apiClient
    .put<ApiResponse<RatesInkDto>>('/api/rates-inks', values)
    .then(({ data }) => data)
