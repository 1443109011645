export function onlyUnique(value: unknown, index: number, array: unknown[]) {
  return array.indexOf(value) === index
}

export function unique(value: unknown, index: number, array: unknown[]) {
  return array.indexOf(value) === index
}

/**
 * @param array array
 * @param key item key unique constraint
 * @returns array where items are unique by key param
 */
export function toUniqueArrayByKey(array: any[], key: string) {
  return [...new Map(array.map(item => [item[key], item])).values()]
}

export function uniqueByKey(key: string, value: any, index: number, array: any[]) {
  array.filter((value, index, self) => self.map(x => x.age).indexOf(value.age) == index)
  return /*!value[key] ||*/ array[array.indexOf(value)][key] === array[index][key]
}
