import apiClient from 'api'
import { ApiResponse, RawInventoryDto, VendorOrderDto } from 'dtos'
import { RawInventoryParameters } from 'parameters'
import { URIQuery } from 'utils'

export const getRawInventory = (parameters: RawInventoryParameters) =>
  apiClient
    .get<ApiResponse<VendorOrderDto[]>>(
      '/api/raw-inventory' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getRawInventoryByVendorOrderId = (vendorOrderId: string) =>
  apiClient
    .get<ApiResponse<VendorOrderDto>>('/api/raw-inventory/' + vendorOrderId)
    .then(({ data }) => data)

export const createRawInventory = (values: RawInventoryDto) =>
  apiClient
    .post<ApiResponse<boolean>>('/api/raw-inventory', values)
    .then(({ data }) => data)

export const updateRawInventory = (values: VendorOrderDto) =>
  apiClient
    .put<ApiResponse<VendorOrderDto>>('/api/raw-inventory', values)
    .then(({ data }) => data)
