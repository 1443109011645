import { LoadingButton } from '@mui/lab'
import { Button, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import {
  Details,
  DetailsActions,
  DetailsForm,
  DetailsHeaderCard,
  DetailsTab
} from 'components/_template/details'
import { SyntheticEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function MaterialCoordinatorDetails() {
  const navigate = useNavigate()
  const [value, setValue] = useState<string>('tab1')

  const handleTabsChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Layout title='Material coordinator details'>
      <Details
        header={
          <DetailsHeaderCard title='Material Coordinator  Details - ACME, LLC - #45678' />
        }
        tabs={[
          { value: 'tab1', label: 'Details' }
          // { value: 'tab2', label: 'Tab 2' },
          // { value: 'tab3', label: 'Tab 3' }
        ]}
        onSubmit={e => e.preventDefault()}
      >
        {/* TAB 1 */}
        {/* TAB 1 */}
        {/* TAB 1 */}

        <DetailsTab value='tab1'>
          {/* LINE ITEM CARD */}
          {/* LINE ITEM CARD */}
          {/* LINE ITEM CARD */}
          <Grid item xs={12}>
            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              .
            </Typography>
          </Grid>
          <DetailsForm>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                  Order information
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                  Customer part number: T-1000 RP 8675309
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                  Test run quantity (pieces): 10
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                  Minimum order run quantity (pieces): 10,000
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                  Committed quantity (pieces): 240,000
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                  Contract duration (months): 24
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                  Job center: Big Jack
                </Typography>
              </Grid>
            </Grid>
          </DetailsForm>

          {/* Vendor Order and Work Order Creator CARD */}
          {/* Vendor Order and Work Order Creator CARD */}
          {/* Vendor Order and Work Order Creator CARD */}
          <Grid item xs={12}>
            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              .
            </Typography>
          </Grid>
          <DetailsForm>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                container
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item xs={12}>
                  <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                    Vendor Order and Work Order Creator
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant='body1' sx={{ color: '#7F7F7F', fontWeight: 600 }}>
                  Line Item
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='body1' sx={{ color: '#7F7F7F', fontWeight: 600 }}>
                  Vendor
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='body1' sx={{ color: '#7F7F7F', fontWeight: 600 }}>
                  Quantity
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='body1' sx={{ color: '#7F7F7F', fontWeight: 600 }}>
                  Per Bundle (Count)
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                  SKU#1 - Spec Date 1
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                  GEOPAC
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                  10,000
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField size='small' fullWidth label='' placeholder='' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                  SKU#2 - Spec Date 1
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                  GEOPAC
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                  10,000
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField size='small' fullWidth label='' placeholder='' />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                  SKU#3 - Spec Date 1
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                  GEOPAC
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                  10,000
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField size='small' fullWidth label='' placeholder='' />
              </Grid>
            </Grid>
          </DetailsForm>
        </DetailsTab>

        <DetailsActions>
          <Button
            color='secondary'
            onClick={() => {
              navigate('/material-coordinator')
            }}
            variant='text'
          >
            BACK
          </Button>

          <LoadingButton
            color='primary'
            onClick={() => {
              navigate('/material-coordinator')
            }}
            type='submit'
            variant='contained'
          >
            <span>{'Create Orders'}</span>
          </LoadingButton>
        </DetailsActions>
        {/* ACCORDION NOTES CARD */}
        {/* ACCORDION NOTES CARD */}
        {/* ACCORDION NOTES CARD */}
        <Grid item xs={12}>
          <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
            .
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ExplanationAccordion>
            A vendor order is created for each line item. The vendor order quantity is
            based on the committed quantity (pieces) value on the quote (shown
            above).||Per bundle count is used to let the job center operator know the size
            of each bundle and where to insert the travelers.||The line item from the
            quote is the job number.||All fields on this card are required.
          </ExplanationAccordion>
          <ExplanationAccordion development>
            Creates Work Order Record and Vendor Order Record.||Generates work order
            number in the WorkOrders table based on highest work order number plus
            1.||Creates a job record for each line item record (linking to the
            QuoteLineItems table) in the Jobs table for the most recent quote revision.
          </ExplanationAccordion>
        </Grid>
      </Details>
    </Layout>
  )
}
