import * as yup from 'yup'
import { fieldTypeMessage, requiredFieldMessage } from '../constants'

export class SignInDto {
  email: string = ''
  password: string = ''
}

export const signInValidationSchema = yup.object({
  email: yup.string().email(fieldTypeMessage('Email')).required(requiredFieldMessage),
  password: yup.string().required(requiredFieldMessage)
})
