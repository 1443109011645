export class LearningMaterialTypeDto {
  id: string = ''
  name: string = ''
}

export class LearningMaterialAnswerDto {
  id?: string = undefined
  description: string = ''
  isCorrect: boolean = false
  order: number = 0
}

export class LearningMaterialQuestionDto {
  id?: string = undefined
  title: string = ''
  order: number = 0
  learningMaterialAnswers: LearningMaterialAnswerDto[] = []
}

export class LearningMaterialDto {
  id?: string = undefined
  name: string = ''
  description: string = ''
  fileName?: string = undefined
  videoLength?: number = undefined
  order: number = 0
  learningMaterialQuestions?: LearningMaterialQuestionDto[] = []
  learningMaterialType: LearningMaterialTypeDto = new LearningMaterialTypeDto()

  learningCourseSectionId: string = ''
}

export class LearningCourseSectionDto {
  id?: string = undefined
  title: string = ''

  // TODO!!!! NEED TO ADD TO BACKEND
  description?: string = ''

  order: number = 0
  learningMaterials: LearningMaterialDto[] = []
}

export class LearningCourseDto {
  id?: string = undefined
  name: string = ''
  description?: string = ''
  learningCourseSections: LearningCourseSectionDto[] = []
}
