import {
  ButtonProps,
  Card,
  CardContent,
  Chip,
  ChipProps,
  Grid,
  Typography
} from '@mui/material'
import React, { Children, PropsWithChildren, ReactElement } from 'react'

export type ListItemCardProps = {
  chips?: React.ReactNode
  avatar?: ReactElement<ChipProps>
  title?: string
  titleColor?: 'inherit' | 'primary' | 'secondary' | 'error'
  subtitle?: string
  actions?: ReactElement<ButtonProps>[]
  variant?: 'elevation' | 'outlined'
}

export default function ListItemCard({
  actions,
  children,
  chips,
  avatar,
  title,
  titleColor = 'inherit',
  subtitle,
  variant = 'elevation'
}: PropsWithChildren<ListItemCardProps>) {
  return (
    <Card variant={variant} elevation={variant === 'outlined' ? 0 : 4}>
      <CardContent>
        <Grid container spacing={1} alignItems='center'>
          <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
            <Grid item>
              {(avatar || title) && (
                <Grid item xs={12} container spacing={1} alignItems='center'>
                  {title && (
                    <Grid item>
                      <Typography
                        variant='h6'
                        sx={{ fontWeight: 600 }}
                        color={titleColor}
                      >
                        {title}
                      </Typography>
                    </Grid>
                  )}

                  {avatar && <Grid item>{avatar}</Grid>}
                </Grid>
              )}
            </Grid>

            <Grid item xs={6} container spacing={1} justifyContent='flex-end'>
              {Children.map(chips, chip => (
                <Grid item>{chip}</Grid>
              ))}
              {/* <Grid item>
                <Chip label='Inactive' color='default' variant='outlined' />
              </Grid>

              <Grid item>
                <Chip label='Prospect' color='success' variant='outlined' />
              </Grid>

              <Grid item>
                <Chip label='User' color='primary' variant='outlined' />
              </Grid> */}
            </Grid>
          </Grid>

          {subtitle && (
            <Grid item xs={12}>
              <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                {subtitle}
              </Typography>
            </Grid>
          )}

          {Children.map(
            children,
            child =>
              child && (
                <Grid item xs={12}>
                  {child}
                </Grid>
              )
          )}

          {actions && (
            <Grid
              alignItems='center'
              container
              item
              justifyContent='flex-end'
              spacing={2}
              xs={12}
            >
              {actions.map((action, index) => (
                <Grid item key={index}>
                  {action}
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
