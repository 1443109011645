import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import {
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  Tooltip,
  Typography
} from '@mui/material'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import * as api from 'api'
import {
  RatesAssemblyAddersAddEditDialog,
  RatesBlendAddersAddEditDialog,
  RatesInksAddEditDialog,
  RatesPaperAddEditDialog,
  RatesPaperAddersAddEditDialog,
  RatesScoringAddersAddEditDialog
} from 'components'
import RatesGlobalAddEditDialog from 'components/RatesGlobalAddEditDialog'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import { Details, DetailsHeaderCard, DetailsTab } from 'components/_template/details'
import {
  ApiResponse,
  BoardTypeDto,
  CompanyDto,
  RatesAssemblyAdderDto,
  RatesBlendAdderDto,
  RatesEndCostAdderDto,
  RatesGlobalDto,
  RatesInkDto,
  RatesPaperAdderDto,
  RatesPaperDto,
  RatesScoringAdderDto
} from 'dtos'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { format, formats } from 'theme'
import { errorHandling } from '../constants'

export default function RateDetails() {
  // #region hooks
  const { enqueueSnackbar } = useSnackbar()
  // #endregion

  // #region useState
  const [boardTypes, setBoardTypes] = useState<BoardTypeDto[]>([])
  // #region LoadingState
  const [isGettingBoardTypes, setIsGettingBoardTypes] = useState<boolean>(false)
  const [isGettingRatesAssemblyAdders, setIsGettingRatesAssemblyAdders] =
    useState<boolean>(false)
  const [isGettingRatesBlendAdders, setIsGettingRatesBlendAdders] =
    useState<boolean>(false)
  const [isGettingRatesEndCostAdders, setIsGettingRatesEndCostAdders] =
    useState<boolean>(false)
  const [isGettingRatesGlobal, setIsGettingRatesGlobal] = useState<boolean>(false)
  const [isGettingRatesInks, setIsGettingRatesInks] = useState<boolean>(false)
  const [isGettingRatesPapers, setIsGettingRatesPapers] = useState<boolean>(false)
  const [isGettingRatesPaperAdders, setIsGettingRatesPaperAdders] =
    useState<boolean>(false)
  const [isGettingRatesScoringAdders, setIsGettingRatesScoringAdders] =
    useState<boolean>(false)
  const [isGettingVendors, setIsGettingVendors] = useState<boolean>(false)
  // #endregion

  const [ratesAssemblyAddersAddEditDialogOpen, setRatesAssemblyAddersAddEditDialogOpen] =
    useState<boolean>(false)
  const [ratesAssemblyAddersList, setRatesAssemblyAddersList] = useState<
    RatesAssemblyAdderDto[]
  >([])
  const [ratesAssemblyAddersValues, setRatesAssemblyAddersValues] =
    useState<RatesAssemblyAdderDto>(new RatesAssemblyAdderDto())

  const [ratesBlendAddersAddEditDialogOpen, setRatesBlendAddersAddEditDialogOpen] =
    useState<boolean>(false)
  const [ratesBlendAddersList, setRatesBlendAddersList] = useState<RatesBlendAdderDto[]>(
    []
  )
  const [ratesBlendAddersValues, setRatesBlendAddersValues] =
    useState<RatesBlendAdderDto>(new RatesBlendAdderDto())

  const [ratesEndCostAddersList, setRatesEndCostAddersList] =
    useState<RatesEndCostAdderDto[]>()

  const [ratesGlobalAddEditDialogOpen, setRatesGlobalAddEditDialogOpen] =
    useState<boolean>(false)
  // There is no ratesGlobalList because there is only ever one set of Global data
  const [ratesGlobalValues, setRatesGlobalValues] = useState<RatesGlobalDto>(
    new RatesGlobalDto()
  )

  const [ratesInksAddEditDialogOpen, setRatesInksAddEditDialogOpen] =
    useState<boolean>(false)
  const [ratesInksList, setRatesInksList] = useState<RatesInkDto[]>([])
  const [ratesInksValues, setRatesInksValues] = useState<RatesInkDto>(new RatesInkDto())

  const [ratesPaperAddersAddEditDialogOpen, setRatesPaperAddersAddEditDialogOpen] =
    useState<boolean>(false)
  const [ratesPaperAddersList, setRatesPaperAddersList] = useState<RatesPaperAdderDto[]>(
    []
  )
  const [ratesPaperAddersValues, setRatesPaperAddersValues] =
    useState<RatesPaperAdderDto>(new RatesPaperAdderDto())

  const [ratesPaperAddEditDialogOpen, setRatesPaperAddEditDialogOpen] =
    useState<boolean>(false)
  const [ratesPapersList, setRatesPapersList] = useState<RatesPaperDto[]>([])
  const [ratesPaperValues, setRatesPaperValues] = useState<RatesPaperDto>(
    new RatesPaperDto()
  )

  const [ratesScoringAddersAddEditDialogOpen, setRatesScoringAddersAddEditDialogOpen] =
    useState<boolean>(false)
  const [ratesScoringAddersList, setRatesScoringAddersList] = useState<
    RatesScoringAdderDto[]
  >([])
  const [ratesScoringAddersValues, setRatesScoringAddersValues] =
    useState<RatesScoringAdderDto>(new RatesScoringAdderDto())
  const [vendorsList, setVendorsList] = useState<CompanyDto[]>([])
  // #endregion

  // #region Methods (Alphabetical by method name)
  // #region Get Methods (Alphabetical by method name)
  const getBoardTypes = () => {
    setIsGettingBoardTypes(true)
    api
      .getBoardTypes()
      .then(({ value }) => {
        setBoardTypes(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingBoardTypes(false)
      })
  }

  const getRatesAssemblyAdders = () => {
    setIsGettingRatesAssemblyAdders(true)
    api
      .getRatesAssemblyAdders({
        page: 0,
        pageSize: 999,
        includeInactive: includeInactive.assemblyAdders
      })
      .then(({ value }) => {
        setRatesAssemblyAddersList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingRatesAssemblyAdders(false)
      })
  }

  const getRatesBlendAdders = () => {
    setIsGettingRatesBlendAdders(true)
    api
      .getRatesBlendAdders({
        page: 0,
        pageSize: 999,
        includeInactive: includeInactive.blendAdders
      })
      .then(({ value }) => {
        setRatesBlendAddersList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingRatesBlendAdders(false)
      })
  }

  const getRatesEndCostAdders = () => {
    setIsGettingRatesEndCostAdders(true)
    api
      .getRatesEndCostAdders({ page: 0, pageSize: 999 })
      .then(({ value }) => {
        setRatesEndCostAddersList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingRatesEndCostAdders(false)
      })
  }

  const getRatesGlobal = () => {
    setIsGettingRatesGlobal(true)
    api
      .getRatesGlobal()
      .then(({ value }) => {
        setRatesGlobalValues(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingRatesGlobal(false)
      })
  }

  const getRatesInks = () => {
    setIsGettingRatesInks(true)
    api
      .getRatesInks({ page: 0, pageSize: 999, includeInactive: includeInactive.inks })
      .then(({ value }) => {
        setRatesInksList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingRatesInks(false)
      })
  }

  const [includeInactive, _setIncludeInactive] = useState<{
    paper: boolean
    paperAdders: boolean
    blendAdders: boolean
    assemblyAdders: boolean
    scoringAdders: boolean
    inks: boolean
  }>({
    paper: false,
    paperAdders: false,
    blendAdders: false,
    assemblyAdders: false,
    scoringAdders: false,
    inks: false
  })

  const setIncludeInactive = (
    key:
      | 'paper'
      | 'paperAdders'
      | 'blendAdders'
      | 'assemblyAdders'
      | 'scoringAdders'
      | 'inks',
    value: boolean
  ) => {
    _setIncludeInactive({
      ...includeInactive,
      [key]: value
    })
  }

  useEffect(() => {
    getVendors()
    getBoardTypes()
    getRatesEndCostAdders()
    getRatesGlobal()
  }, [])

  useEffect(() => {
    getRatesPapers()
  }, [includeInactive.paper])

  useEffect(() => {
    getRatesPaperAdders()
  }, [includeInactive.paperAdders])

  useEffect(() => {
    getRatesBlendAdders()
  }, [includeInactive.blendAdders])

  useEffect(() => {
    getRatesAssemblyAdders()
  }, [includeInactive.assemblyAdders])

  useEffect(() => {
    getRatesScoringAdders()
  }, [includeInactive.scoringAdders])

  useEffect(() => {
    getRatesInks()
  }, [includeInactive.inks])

  const getRatesPaperAdders = () => {
    setIsGettingRatesPaperAdders(true)
    api
      .getRatesPaperAdders({
        page: 0,
        pageSize: 999,
        includeInactive: includeInactive.paperAdders
      })
      .then(({ value }) => {
        setRatesPaperAddersList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingRatesPaperAdders(false)
      })
  }

  const getRatesPapers = () => {
    setIsGettingRatesPapers(true)
    api
      .getRatesPaper({ page: 0, pageSize: 999, includeInactive: includeInactive.paper })
      .then(({ value }) => {
        setRatesPapersList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingRatesPapers(false)
      })
  }

  const getRatesScoringAdders = () => {
    setIsGettingRatesScoringAdders(true)
    api
      .getRatesScoringAdders({
        page: 0,
        pageSize: 999,
        includeInactive: includeInactive.scoringAdders
      })
      .then(({ value }) => {
        setRatesScoringAddersList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingRatesScoringAdders(false)
      })
  }

  const getVendors = () => {
    setIsGettingVendors(true)
    api
      .getCompanyVendors()
      .then(({ value }) => {
        setVendorsList(value)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsGettingVendors(false)
      })
  }
  // #endregion

  // #region Save Methods (Alphabetical by method name)
  const onSaveAssemblyAddersAddEditDialog = async (
    assemblyAdder: RatesAssemblyAdderDto
  ) => {
    if (assemblyAdder.id) {
      api
        .updateRatesAssemblyAdder(assemblyAdder)
        .then(({ value }) => {
          enqueueSnackbar(`Assembly Adder Has Been Saved!`, {
            variant: 'success'
          })

          // Because sorting is primarily handled on the back-end get the whole list again upon saving
          getRatesAssemblyAdders()

          setRatesAssemblyAddersAddEditDialogOpen(false)
          return value
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
    } else {
      api
        .createRatesAssemblyAdder(assemblyAdder)
        .then(({ value }) => {
          enqueueSnackbar(`Assembly Adder Has Been Saved!`, {
            variant: 'success'
          })

          // Because sorting is primarily handled on the back-end get the whole list again upon saving
          getRatesAssemblyAdders()

          setRatesAssemblyAddersAddEditDialogOpen(false)
          return value
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
    }
  }

  const onSaveBlendAddersAddEditDialog = async (blendAdder: RatesBlendAdderDto) => {
    if (blendAdder.id) {
      api
        .updateRatesBlendAdder(blendAdder)
        .then(({ value }) => {
          enqueueSnackbar(`Blend Adder Has Been Saved!`, {
            variant: 'success'
          })

          // Because sorting is primarily handled on the back-end get the whole list again upon saving
          getRatesBlendAdders()

          setRatesBlendAddersAddEditDialogOpen(false)
          return value
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
    } else {
      api
        .createRatesBlendAdder(blendAdder)
        .then(({ value }) => {
          enqueueSnackbar(`Blend Adder Has Been Saved!`, {
            variant: 'success'
          })

          // Because sorting is primarily handled on the back-end get the whole list again upon saving
          getRatesBlendAdders()

          setRatesBlendAddersAddEditDialogOpen(false)
          return value
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
    }
  }

  const onSaveGlobalAddEditDialog = async (global: RatesGlobalDto) => {
    if (global.id) {
      api
        .updateRatesGlobal(global)
        .then(({ value }) => {
          enqueueSnackbar(`Global Rates Have Been Saved!`, {
            variant: 'success'
          })

          getRatesGlobal()

          setRatesGlobalAddEditDialogOpen(false)
          return value
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
    } else {
      api
        .updateRatesGlobal(global)
        .then(({ value }) => {
          enqueueSnackbar(`Global Rates Have Been Saved!`, {
            variant: 'success'
          })

          getRatesGlobal()

          setRatesGlobalAddEditDialogOpen(false)
          return value
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
    }
  }

  const onSaveInksAddEditDialog = async (ink: RatesInkDto) => {
    if (ink.id) {
      api
        .updateRatesInk(ink)
        .then(({ value }) => {
          enqueueSnackbar(`Ink Has Been Saved!`, {
            variant: 'success'
          })

          // Because sorting is primarily handled on the back-end get the whole list again upon saving
          getRatesInks()

          setRatesInksAddEditDialogOpen(false)
          return value
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
    } else {
      api
        .createRatesInk(ink)
        .then(({ value }) => {
          enqueueSnackbar(`Ink Has Been Saved!`, {
            variant: 'success'
          })

          // Because sorting is primarily handled on the back-end get the whole list again upon saving
          getRatesInks()

          setRatesInksAddEditDialogOpen(false)
          return value
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
    }
  }

  const onSavePaperAddersAddEditDialog = async (paperAdder: RatesPaperAdderDto) => {
    if (paperAdder.id) {
      api
        .updateRatesPaperAdder(paperAdder)
        .then(({ value }) => {
          enqueueSnackbar(`Paper Adder Has Been Saved!`, {
            variant: 'success'
          })

          // Because sorting is primarily handled on the back-end get the whole list again upon saving
          getRatesPaperAdders()

          setRatesPaperAddersAddEditDialogOpen(false)
          return value
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
    } else {
      api
        .createRatesPaperAdder(paperAdder)
        .then(({ value }) => {
          enqueueSnackbar(`Paper Adder Has Been Saved!`, {
            variant: 'success'
          })

          // Because sorting is primarily handled on the back-end get the whole list again upon saving
          getRatesPaperAdders()

          setRatesPaperAddersAddEditDialogOpen(false)
          return value
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
    }
  }

  const onSavePaperAddEditDialog = async (paper: RatesPaperDto) => {
    if (paper.id) {
      api
        .updateRatesPaper(paper)
        .then(({ value }) => {
          enqueueSnackbar(`Paper has been saved!`, {
            variant: 'success'
          })

          // Because sorting is primarily handled on the back-end get the whole list again upon saving
          getRatesPapers()

          setRatesPaperAddEditDialogOpen(false)
          return value
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
    } else {
      api
        .createRatesPaper(paper)
        .then(({ value }) => {
          enqueueSnackbar(`Paper has been saved!`, {
            variant: 'success'
          })

          // Because sorting is primarily handled on the back-end get the whole list again upon saving
          getRatesPapers()

          setRatesPaperAddEditDialogOpen(false)
          return value
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
    }
  }

  const onSaveScoringAddersAddEditDialog = async (scoringAdder: RatesScoringAdderDto) => {
    if (scoringAdder.id) {
      api
        .updateRatesScoringAdder(scoringAdder)
        .then(({ value }) => {
          enqueueSnackbar(`Scoring Adder Has Been Saved!`, {
            variant: 'success'
          })

          // Because sorting is primarily handled on the back-end get the whole list again upon saving
          getRatesScoringAdders()

          setRatesScoringAddersAddEditDialogOpen(false)
          return value
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
    } else {
      api
        .createRatesScoringAdder(scoringAdder)
        .then(({ value }) => {
          enqueueSnackbar(`Scoring Adder Has Been Saved!`, {
            variant: 'success'
          })

          // Because sorting is primarily handled on the back-end get the whole list again upon saving
          getRatesScoringAdders()

          setRatesScoringAddersAddEditDialogOpen(false)
          return value
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
    }
  }
  // #endregion
  // #endregion

  return (
    <Layout
      title='Rates'
      // Alphabetize this list of loading properties
      isLoading={
        isGettingBoardTypes ||
        isGettingRatesAssemblyAdders ||
        isGettingRatesBlendAdders ||
        isGettingRatesEndCostAdders ||
        isGettingRatesGlobal ||
        isGettingRatesInks ||
        isGettingRatesPaperAdders ||
        isGettingRatesPapers ||
        isGettingRatesScoringAdders ||
        isGettingVendors
      }
    >
      <Details
        header={<DetailsHeaderCard title='Rates' />}
        tabs={[
          { value: 'tab1', label: 'Paper' },
          { value: 'tab2', label: 'Paper Adders' },
          { value: 'tab3', label: 'Blend Adders' },
          { value: 'tab4', label: 'Assembly Adders' },
          { value: 'tab5', label: 'Scoring Adders' },
          { value: 'tab6', label: 'Inks' },
          { value: 'tab7', label: 'End Cost Adders' },
          { value: 'tab8', label: 'Global' }
        ]}
        onSubmit={e => e.preventDefault()}
      >
        {/* TAB 1 */}
        {/* TAB 1 */}
        {/* TAB 1 */}
        <RatesPaperAddEditDialog
          boardTypes={boardTypes}
          open={ratesPaperAddEditDialogOpen}
          onClose={() => {
            setRatesPaperAddEditDialogOpen(false)
          }}
          onSave={onSavePaperAddEditDialog}
          values={ratesPaperValues}
          vendors={vendorsList}
        />
        <DetailsTab value='tab1'>
          <Grid item xs={12} container alignItems='center' justifyContent='flex-end'>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={includeInactive.paper}
                    onChange={() => {
                      setIncludeInactive('paper', !includeInactive.paper)
                    }}
                  />
                }
                label='Include Inactive'
                sx={{ mb: 2 }}
              />
            </Grid>

            <Grid item>
              <Button
                variant='outlined'
                onClick={() => {
                  setRatesPaperValues(new RatesPaperDto())
                  setRatesPaperAddEditDialogOpen(true)
                }}
                sx={{ mb: 2 }}
              >
                Add Paper
              </Button>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Vendor</TableCell>
                  <TableCell align='center'>Paper Code</TableCell>
                  <TableCell align='center'>Board</TableCell>
                  <TableCell align='center'>Flute</TableCell>
                  <TableCell align='center'>Ect / Mullen</TableCell>
                  <TableCell align='center'>Basis Weight Value</TableCell>
                  <TableCell align='center'>Blend</TableCell>
                  <TableCell align='center'>Liner 1</TableCell>
                  <TableCell align='center'>Medium 1</TableCell>
                  <TableCell align='center'>Medium 1 Take Up Factor</TableCell>
                  <TableCell align='center'>Liner 2</TableCell>
                  <TableCell align='center'>Medium 2</TableCell>
                  <TableCell align='center'>Medium 2 Take Up Factor</TableCell>
                  <TableCell align='center'>Liner 3</TableCell>
                  <TableCell align='center'>Medium 3</TableCell>
                  <TableCell align='center'>Medium 3 Take Up Factor</TableCell>
                  <TableCell align='center'>Liner 4</TableCell>
                  <TableCell align='center'>Starch Factor All Mediums Combined</TableCell>
                  <TableCell align='center'>Setup Fee</TableCell>
                  <TableCell align='center'>Msf&gt;0&lt;5</TableCell>
                  <TableCell align='center'>Msf&gt;=5&lt;10</TableCell>
                  <TableCell align='center'>Msf&gt;=10&lt;15</TableCell>
                  <TableCell align='center'>Msf&gt;=15&lt;30</TableCell>
                  <TableCell align='center'>Msf&gt;=30&lt;45</TableCell>
                  <TableCell align='center'>Msf&gt;=45&lt;60</TableCell>
                  <TableCell align='center'>Msf&gt;=60</TableCell>
                  <TableCell align='center'>Active</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ratesPapersList.map(ratesPaper => (
                  <TableRow
                    key={ratesPaper.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='ratesPaper'>
                      {ratesPaper?.company?.name ?? ''}
                    </TableCell>
                    <TableCell align='center'>{ratesPaper.paperCode}</TableCell>
                    <TableCell align='center'>{ratesPaper.boardType?.name}</TableCell>
                    <TableCell align='center'>{ratesPaper.flute}</TableCell>
                    <TableCell align='center'>{ratesPaper.ect}</TableCell>
                    <TableCell align='center'>{ratesPaper.basisWeightValue}</TableCell>
                    <TableCell align='center'>{ratesPaper.blend}</TableCell>
                    <TableCell align='center'>{ratesPaper.liner1}</TableCell>
                    <TableCell align='center'>{ratesPaper.medium1}</TableCell>
                    <TableCell align='center'>{ratesPaper.medium1TakeUpFactor}</TableCell>
                    <TableCell align='center'>{ratesPaper.liner2}</TableCell>
                    <TableCell align='center'>{ratesPaper.medium2}</TableCell>
                    <TableCell align='center'>{ratesPaper.medium2TakeUpFactor}</TableCell>
                    <TableCell align='center'>{ratesPaper.liner3}</TableCell>
                    <TableCell align='center'>{ratesPaper.medium3}</TableCell>
                    <TableCell align='center'>{ratesPaper.medium3TakeUpFactor}</TableCell>
                    <TableCell align='center'>{ratesPaper.liner4}</TableCell>
                    <TableCell align='center'>
                      {ratesPaper.starchFactorAllMediumsCombined}
                    </TableCell>
                    <TableCell align='center'>
                      {format(ratesPaper.setupFee, formats.currency)}
                    </TableCell>
                    <TableCell align='center'>
                      {format(ratesPaper.msf0to5, formats.currency)}
                    </TableCell>
                    <TableCell align='center'>
                      {format(ratesPaper.msf5to10, formats.currency)}
                    </TableCell>
                    <TableCell align='center'>
                      {format(ratesPaper.msf10to15, formats.currency)}
                    </TableCell>
                    <TableCell align='center'>
                      {format(ratesPaper.msf15to30, formats.currency)}
                    </TableCell>
                    <TableCell align='center'>
                      {format(ratesPaper.msf30to45, formats.currency)}
                    </TableCell>
                    <TableCell align='center'>
                      {format(ratesPaper.msf45to60, formats.currency)}
                    </TableCell>
                    <TableCell align='center'>
                      {format(ratesPaper.msf60to75, formats.currency)}
                    </TableCell>
                    <TableCell align='center'>
                      {ratesPaper.isActive ? <CheckIcon /> : null}
                    </TableCell>
                    <TableCell align='center'>
                      <Tooltip title={'Edit'}>
                        <Grid item>
                          <IconButton
                            aria-label={'Edit'}
                            onClick={() => {
                              setRatesPaperValues(ratesPaper)
                              setRatesPaperAddEditDialogOpen(true)
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Grid>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DetailsTab>

        {/* TAB 2 */}
        {/* TAB 2 */}
        {/* TAB 2 */}
        {ratesPaperAddersAddEditDialogOpen && (
          <RatesPaperAddersAddEditDialog
            open={ratesPaperAddersAddEditDialogOpen}
            onClose={() => {
              setRatesPaperAddersAddEditDialogOpen(false)
            }}
            onSave={onSavePaperAddersAddEditDialog}
            values={ratesPaperAddersValues}
            vendors={vendorsList}
          />
        )}

        <DetailsTab value='tab2'>
          <Grid item xs={12} container alignItems='center' justifyContent='flex-end'>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={includeInactive.paperAdders}
                    onChange={() => {
                      setIncludeInactive('paperAdders', !includeInactive.paperAdders)
                    }}
                  />
                }
                label='Include Inactive'
                sx={{ mb: 2 }}
              />
            </Grid>

            <Grid item>
              <Button
                variant='outlined'
                onClick={() => {
                  setRatesPaperAddersValues(new RatesPaperAdderDto())
                  setRatesPaperAddersAddEditDialogOpen(true)
                }}
                sx={{ mb: 2 }}
              >
                Add Paper Adders
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Vendor</TableCell>
                  <TableCell align='center'>ECT / Mullen</TableCell>
                  <TableCell align='center'>Adder</TableCell>
                  <TableCell align='center'>Adder Setup</TableCell>
                  <TableCell align='center'>Adder Per MSF</TableCell>
                  <TableCell align='center'>Active</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ratesPaperAddersList.map(ratesPaperAdder => (
                  <TableRow
                    key={ratesPaperAdder.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='ratesPaperAdder'>
                      {ratesPaperAdder?.company?.name ?? ''}
                    </TableCell>
                    <TableCell align='center'>{ratesPaperAdder.ect}</TableCell>
                    <TableCell align='center'>{ratesPaperAdder.adder}</TableCell>
                    <TableCell align='center'>
                      {format(ratesPaperAdder.adderSetup, formats.currency)}
                    </TableCell>
                    <TableCell align='center'>
                      {format(ratesPaperAdder.adderPerMsf, formats.currency)}
                    </TableCell>
                    <TableCell align='center'>
                      {ratesPaperAdder.isActive ? <CheckIcon /> : null}
                    </TableCell>
                    <TableCell align='center'>
                      <Tooltip title={'Edit'}>
                        <Grid item>
                          <IconButton
                            aria-label={'Edit'}
                            onClick={() => {
                              setRatesPaperAddersValues(ratesPaperAdder)
                              setRatesPaperAddersAddEditDialogOpen(true)
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Grid>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DetailsTab>
        {/* TAB 3 */}
        {/* TAB 3 */}
        {/* TAB 3 */}
        <RatesBlendAddersAddEditDialog
          open={ratesBlendAddersAddEditDialogOpen}
          onClose={() => {
            setRatesBlendAddersAddEditDialogOpen(false)
          }}
          onSave={onSaveBlendAddersAddEditDialog}
          values={ratesBlendAddersValues}
          vendors={vendorsList}
        />
        <DetailsTab value='tab3'>
          <Grid item xs={12} container alignItems='center' justifyContent='flex-end'>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={includeInactive.blendAdders}
                    onChange={() => {
                      setIncludeInactive('blendAdders', !includeInactive.blendAdders)
                    }}
                  />
                }
                label='Include Inactive'
                sx={{ mb: 2 }}
              />
            </Grid>

            <Grid item>
              <Button
                variant='outlined'
                onClick={() => {
                  setRatesBlendAddersValues(new RatesBlendAdderDto())
                  setRatesBlendAddersAddEditDialogOpen(true)
                }}
                sx={{ mb: 2 }}
              >
                Add Blend Adders
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Vendor</TableCell>
                  <TableCell align='center'>ECT / Mullen</TableCell>
                  <TableCell align='center'>Adder</TableCell>
                  <TableCell align='center'>Adder Setup</TableCell>
                  <TableCell align='center'>Adder Per MSF</TableCell>
                  <TableCell align='center'>Active</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ratesBlendAddersList.map(ratesBlendAdder => (
                  <TableRow
                    key={ratesBlendAdder.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='ratesBlendAdder'>
                      {ratesBlendAdder?.company?.name ?? ''}
                    </TableCell>
                    <TableCell align='center'>{ratesBlendAdder.ect}</TableCell>
                    <TableCell align='center'>{ratesBlendAdder.adder}</TableCell>
                    <TableCell align='center'>
                      {format(ratesBlendAdder.adderSetup, formats.currency)}
                    </TableCell>
                    <TableCell align='center'>
                      {format(ratesBlendAdder.adderPerMsf, formats.currency)}
                    </TableCell>
                    <TableCell align='center'>
                      {ratesBlendAdder.isActive ? <CheckIcon /> : null}
                    </TableCell>
                    <TableCell align='center'>
                      <Tooltip title={'Edit'}>
                        <Grid item>
                          <IconButton
                            aria-label={'Edit'}
                            onClick={() => {
                              setRatesBlendAddersValues(ratesBlendAdder)
                              setRatesBlendAddersAddEditDialogOpen(true)
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Grid>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DetailsTab>
        {/* TAB 4 */}
        {/* TAB 4 */}
        {/* TAB 4 */}
        <RatesAssemblyAddersAddEditDialog
          open={ratesAssemblyAddersAddEditDialogOpen}
          onClose={() => {
            setRatesAssemblyAddersAddEditDialogOpen(false)
          }}
          onSave={onSaveAssemblyAddersAddEditDialog}
          values={ratesAssemblyAddersValues}
          vendors={vendorsList}
        />
        <DetailsTab value='tab4'>
          <Grid item xs={12} container alignItems='center' justifyContent='flex-end'>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={includeInactive.assemblyAdders}
                    onChange={() => {
                      setIncludeInactive(
                        'assemblyAdders',
                        !includeInactive.assemblyAdders
                      )
                    }}
                  />
                }
                label='Include Inactive'
                sx={{ mb: 2 }}
              />
            </Grid>

            <Grid item>
              <Button
                variant='outlined'
                onClick={() => {
                  setRatesAssemblyAddersValues(new RatesAssemblyAdderDto())
                  setRatesAssemblyAddersAddEditDialogOpen(true)
                }}
                sx={{ mb: 2 }}
              >
                Add Assembly Adders
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Vendor</TableCell>
                  <TableCell align='center'>ECT / Mullen</TableCell>
                  <TableCell align='center'>Adder</TableCell>
                  <TableCell align='center'>Adder Setup</TableCell>
                  <TableCell align='center'>Adder Per MSF</TableCell>
                  <TableCell align='center'>Active</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ratesAssemblyAddersList.map(ratesAssemblyAdder => (
                  <TableRow
                    key={ratesAssemblyAdder.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='ratesAssemblyAdder'>
                      {ratesAssemblyAdder?.company?.name ?? ''}
                    </TableCell>
                    <TableCell align='center'>{ratesAssemblyAdder.ect}</TableCell>
                    <TableCell align='center'>{ratesAssemblyAdder.adder}</TableCell>
                    <TableCell align='center'>
                      {format(ratesAssemblyAdder.adderSetup, formats.currency)}
                    </TableCell>
                    <TableCell align='center'>
                      {format(ratesAssemblyAdder.adderPerMsf, formats.currency)}
                    </TableCell>
                    <TableCell align='center'>
                      {ratesAssemblyAdder.isActive ? <CheckIcon /> : null}
                    </TableCell>
                    <TableCell align='center'>
                      <Tooltip title={'Edit'}>
                        <Grid item>
                          <IconButton
                            aria-label={'Edit'}
                            onClick={() => {
                              setRatesAssemblyAddersValues(ratesAssemblyAdder)
                              setRatesAssemblyAddersAddEditDialogOpen(true)
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Grid>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DetailsTab>
        {/* TAB 5 */}
        {/* TAB 5 */}
        {/* TAB 5 */}
        <RatesScoringAddersAddEditDialog
          open={ratesScoringAddersAddEditDialogOpen}
          onClose={() => {
            setRatesScoringAddersAddEditDialogOpen(false)
          }}
          onSave={onSaveScoringAddersAddEditDialog}
          values={ratesScoringAddersValues}
          vendors={vendorsList}
        />
        <DetailsTab value='tab5'>
          <Grid item xs={12} container alignItems='center' justifyContent='flex-end'>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={includeInactive.scoringAdders}
                    onChange={() => {
                      setIncludeInactive('scoringAdders', !includeInactive.scoringAdders)
                    }}
                  />
                }
                label='Include Inactive'
                sx={{ mb: 2 }}
              />
            </Grid>

            <Grid item>
              <Button
                variant='outlined'
                onClick={() => {
                  setRatesScoringAddersValues(new RatesScoringAdderDto())
                  setRatesScoringAddersAddEditDialogOpen(true)
                }}
                sx={{ mb: 2 }}
              >
                Add Scoring Adders
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Vendor</TableCell>
                  <TableCell align='center'>ECT / Mullen</TableCell>
                  <TableCell align='center'>Adder</TableCell>
                  <TableCell align='center'>Adder Setup</TableCell>
                  <TableCell align='center'>Adder Per MSF</TableCell>
                  <TableCell align='center'>Active</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ratesScoringAddersList.map(ratesScoringAdder => (
                  <TableRow
                    key={ratesScoringAdder.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component='th' scope='ratesScoringAdder'>
                      {ratesScoringAdder?.company?.name ?? ''}
                    </TableCell>
                    <TableCell align='center'>{ratesScoringAdder.ect}</TableCell>
                    <TableCell align='center'>{ratesScoringAdder.adder}</TableCell>
                    <TableCell align='center'>
                      {format(ratesScoringAdder.adderSetup, formats.currency)}
                    </TableCell>
                    <TableCell align='center'>
                      {format(ratesScoringAdder.adderPerMsf, formats.currency)}
                    </TableCell>
                    <TableCell align='center'>
                      {ratesScoringAdder.isActive ? <CheckIcon /> : null}
                    </TableCell>
                    <TableCell align='center'>
                      <Tooltip title={'Edit'}>
                        <Grid item>
                          <IconButton
                            aria-label={'Edit'}
                            onClick={() => {
                              setRatesScoringAddersValues(ratesScoringAdder)
                              setRatesScoringAddersAddEditDialogOpen(true)
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Grid>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DetailsTab>
        {/* TAB 6 */}
        {/* TAB 6 */}
        {/* TAB 6 */}
        <RatesInksAddEditDialog
          open={ratesInksAddEditDialogOpen}
          onClose={() => {
            setRatesInksAddEditDialogOpen(false)
          }}
          onSave={onSaveInksAddEditDialog}
          values={ratesInksValues}
        />
        <DetailsTab value='tab6'>
          <Grid item xs={12} container alignItems='center' justifyContent='flex-end'>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={includeInactive.inks}
                    onChange={() => {
                      setIncludeInactive('inks', !includeInactive.inks)
                    }}
                  />
                }
                label='Include Inactive'
                sx={{ mb: 2 }}
              />
            </Grid>

            <Grid item>
              <Button
                variant='outlined'
                onClick={() => {
                  setRatesInksValues(new RatesInkDto())
                  setRatesInksAddEditDialogOpen(true)
                }}
                sx={{ mb: 2 }}
              >
                Add Inks
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>GCMI Colors</TableCell>
                  <TableCell align='center'>Pantone Equivalents</TableCell>
                  <TableCell align='center'>Hex</TableCell>
                  <TableCell align='center'>Active</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ratesInksList.map(ratesInk => (
                  <TableRow
                    key={ratesInk.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align='center'>{ratesInk.gcmiColors}</TableCell>
                    <TableCell align='center'>{ratesInk.pantoneEquivalents}</TableCell>
                    <TableCell align='center'>{ratesInk.hexCode}</TableCell>
                    <TableCell align='center'>
                      {ratesInk.isActive ? <CheckIcon /> : null}
                    </TableCell>
                    <TableCell align='center'>
                      <Tooltip title={'Edit'}>
                        <Grid item>
                          <IconButton
                            aria-label={'Edit'}
                            onClick={() => {
                              setRatesInksValues(ratesInk)
                              setRatesInksAddEditDialogOpen(true)
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Grid>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DetailsTab>
        {/* TAB 7 END COST ADDERS*/}
        {/* TAB 7 END COST ADDERS*/}
        {/* TAB 7 END COST ADDERS*/}
        <DetailsTab value='tab7'>
          <Grid container spacing={2}>
            {ratesEndCostAddersList?.map(ratesEndCostAdder => (
              <Grid item xs={12}>
                <Card variant='outlined'>
                  <CardContent>
                    <Typography
                      variant='body1'
                      sx={{ color: '#2780E3', fontWeight: 600 }}
                    >
                      {ratesEndCostAdder.adder}
                    </Typography>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item xs={12} sm={6}>
                        <Typography>
                          Adder Setup:{' '}
                          {format(ratesEndCostAdder?.adderSetup, formats.currency)}
                        </Typography>
                      </Grid>
                      {ratesEndCostAdder?.adderPerMsf && (
                        <Grid item xs={12} sm={6}>
                          <Typography>
                            Adder Per MSF:{' '}
                            {format(ratesEndCostAdder?.adderPerMsf, formats.currency)}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body1'>
                        {ratesEndCostAdder.description}
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </DetailsTab>
        {/* TAB 8 - GLOBAL */}
        {/* TAB 8 - GLOBAL */}
        {/* TAB 8 - GLOBAL */}
        <RatesGlobalAddEditDialog
          open={ratesGlobalAddEditDialogOpen}
          onClose={() => {
            setRatesGlobalAddEditDialogOpen(false)
          }}
          onSave={onSaveGlobalAddEditDialog}
          values={ratesGlobalValues}
        />
        <DetailsTab value='tab8'>
          <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={6}>
              <Typography variant='h6' sx={{ color: '#7F7F7F' }}></Typography>
            </Grid>

            <Grid item>
              <Button
                variant='outlined'
                onClick={() => {
                  setRatesGlobalAddEditDialogOpen(true)
                }}
                sx={{ mb: 2 }}
              >
                Edit Global
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                    Ink Cost
                  </Typography>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Setup Cost:{' '}
                        {format(ratesGlobalValues?.inkCostSetupCost, formats.currency)}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography>Value: {ratesGlobalValues?.inkCostValue}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                    Big Jack (RDC 86)
                  </Typography>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Piece Per Hour: {ratesGlobalValues?.bigJackRdc86PiecePerHour}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Cost Per Hour:{' '}
                        {format(
                          ratesGlobalValues?.bigJackRdc86CostPerHour,
                          formats.currency
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Setup Cost:{' '}
                        {format(
                          ratesGlobalValues?.bigJackRdc86SetupCost,
                          formats.currency
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                    Little Jack (RDC 66)
                  </Typography>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Piece Per Hour: {ratesGlobalValues?.littleJackRdc66PiecePerHour}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Cost Per Hour:{' '}
                        {format(
                          ratesGlobalValues?.littleJackRdc66CostPerHour,
                          formats.currency
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Setup Cost:{' '}
                        {format(
                          ratesGlobalValues?.littleJackRdc66SetupCost,
                          formats.currency
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                    Sesame Tape
                  </Typography>

                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Coastal Container Sesame Tape 1 Side Cost Per Inch:{' '}
                        {format(
                          ratesGlobalValues?.coastalContainerSesameTape1SideCostPerInch,
                          formats.currencyFourDecimalPlaces
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Coastal Container Sesame Tape 2 Side Cost Per Inch:{' '}
                        {format(
                          ratesGlobalValues?.coastalContainerSesameTape2SideCostPerInch,
                          formats.currencyFourDecimalPlaces
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Georgia Pacific Sesame Tape 1 Side Cost Per Inch:{' '}
                        {format(
                          ratesGlobalValues?.georgiaPacificSesameTape1SideCostPerInch,
                          formats.currencyFourDecimalPlaces
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Georgia Pacific Sesame Tape 2 Side Cost Per Inch:{' '}
                        {format(
                          ratesGlobalValues?.georgiaPacificSesameTape2SideCostPerInch,
                          formats.currencyFourDecimalPlaces
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Viking 1 Sesame Tape 1 Side Cost Per Inch:{' '}
                        {format(
                          ratesGlobalValues?.viking1SesameTape1SideCostPerInch,
                          formats.currencyFourDecimalPlaces
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Viking 1 Sesame Tape 2 Side Cost Per Inch:{' '}
                        {format(
                          ratesGlobalValues?.viking1SesameTape2SideCostPerInch,
                          formats.currencyFourDecimalPlaces
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Viking 2 Sesame Tape 1 Side Cost Per Inch:{' '}
                        {format(
                          ratesGlobalValues?.viking2SesameTape1SideCostPerInch,
                          formats.currencyFourDecimalPlaces
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Typography>
                        Viking 2 Sesame Tape 2 Side Cost Per Inch:{' '}
                        {format(
                          ratesGlobalValues?.viking2SesameTape2SideCostPerInch,
                          formats.currencyFourDecimalPlaces
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                    Stitcher
                  </Typography>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Piece Per Hour: {ratesGlobalValues?.stitcherPiecePerHour}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Piece Per Hour:{' '}
                        {format(ratesGlobalValues?.stitcherCostPerHour, formats.currency)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Setup Cost:{' '}
                        {format(ratesGlobalValues?.stitcherSetupCost, formats.currency)}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                    Bander
                  </Typography>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Cost: {format(ratesGlobalValues?.banderCost, formats.currency)}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                    Unitized Cost
                  </Typography>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Bale:{' '}
                        {format(ratesGlobalValues?.unitizedCostBale, formats.currency)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Small Wood Pallet:{' '}
                        {format(
                          ratesGlobalValues?.unitizedCostSmallWoodPallet,
                          formats.currency
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Large Wood Pallet:{' '}
                        {format(
                          ratesGlobalValues?.unitizedCostLargeWoodPallet,
                          formats.currency
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Cores:{' '}
                        {format(ratesGlobalValues?.unitizedCostCores, formats.currency)}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                    Waste %
                  </Typography>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Percentage:{' '}
                        {format(ratesGlobalValues?.wastePercentage, formats.percent)}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                    Material Handling
                  </Typography>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Cost:{' '}
                        {format(
                          ratesGlobalValues?.materialHandlingCost,
                          formats.currency
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                    Warehousing
                  </Typography>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Cost:{' '}
                        {format(ratesGlobalValues?.warehousingCost, formats.currency)}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card variant='outlined'>
                <CardContent>
                  <Typography variant='body1' sx={{ color: '#2780E3', fontWeight: 600 }}>
                    SGA %
                  </Typography>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={12} sm={4}>
                      <Typography>
                        Percentage:{' '}
                        {format(ratesGlobalValues?.sgaPercent, formats.percent)}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/* ACCORDION NOTES CARD */}
            {/* ACCORDION NOTES CARD */}
            {/* ACCORDION NOTES CARD */}
            <Grid item xs={12}>
              <ExplanationAccordion>
                || The dollar amount entered for "Bander" cost is a flat fee per Finished
                Good bundle added to the quote on the Variable Costs card. || For
                percentage fields enter in 25 for 25%, not 0.25. || Global fields are hard
                coded, only the values can be changed (no additional items may be added
                without code updates). || Stitcher is a piece of plant machinery that will
                not exist until a future project, but we are storing these values now. ||
                Ink cost is calculated without considering ink color, cost per pound
                (weight), etc. "setup cost" is based on time needed per Job center to load
                and prepare the print plates and inks. "value" is based on an average cost
                of ink and ink consumption per job.
              </ExplanationAccordion>
              {/* <ExplanationAccordion development>
                    xxxxxxxxxxxxxxxxxxx||xxxxxxxxxxxxxxxxxxx 
                  </ExplanationAccordion> */}
            </Grid>
          </Grid>
        </DetailsTab>
      </Details>
    </Layout>
  )
}
