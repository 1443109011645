import { ApiResponse, QuoteAssemblyStyleDto } from 'dtos'
import { QuoteAssemblyStylesParameters } from 'parameters'
import { URIQuery } from 'utils'
import apiClient from '.'

export const getQuoteAssemblyStyles = (parameters: QuoteAssemblyStylesParameters) =>
  apiClient
    .get<ApiResponse<QuoteAssemblyStyleDto[]>>(
      '/api/quote-assembly-styles' + URIQuery({ ...parameters })
    )
    .then(res => res.data)
