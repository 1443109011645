import * as yup from 'yup'
import {
  maxString200Message,
  maxString200Value,
  requiredFieldMessage
} from '../constants'
import { AddressDto, addressValidationSchema } from './address'

export class CompanyDto {
  id?: string = undefined
  name: string = ''
  howTheyFoundUs?: string = undefined
  industry?: string = undefined
  internalExternal: boolean = false
  customerNumber?: string = undefined
  vendorNumber?: string = undefined
  phone?: string = undefined
  website?: string = undefined
  logoFileName?: string = undefined
  notes?: string = undefined
  isActive: boolean = true
  isVendor: boolean = false
  addresses: AddressDto[] = []
}

export const companyValidationSchema = yup.object<CompanyDto>({
  id: yup.string().nullable(),
  name: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .required(requiredFieldMessage),
  howTheyFoundUs: yup.string().max(maxString200Value, maxString200Message).nullable(),
  industry: yup.string().max(maxString200Value, maxString200Message).nullable(),
  internalExternal: yup.bool(),
  customerId: yup.string().max(maxString200Value, maxString200Message).nullable(),
  vendorId: yup.string().max(maxString200Value, maxString200Message).nullable(),
  notes: yup.string().max(maxString200Value, maxString200Message).nullable(),
  phone: yup.string().max(maxString200Value, maxString200Message).nullable(),
  website: yup.string().max(maxString200Value, maxString200Message).nullable(),
  addresses: yup.array().of(addressValidationSchema)
})
