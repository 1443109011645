import { ApiResponse, RawInventoryAdjustmentDto } from 'dtos'
import apiClient from '.'

export const createRawInventoryReconciliationAdjustment = (
  values: RawInventoryAdjustmentDto
) =>
  apiClient
    .post<ApiResponse<boolean>>('/api/raw-inventory-adjustments/reconcile', values)
    .then(({ data }) => data)

export const createRawInventoryScrapAdjustment = (values: RawInventoryAdjustmentDto) =>
  apiClient
    .post<ApiResponse<boolean>>('/api/raw-inventory-adjustments/scrap', values)
    .then(({ data }) => data)
