import { Box, Container, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AppBar from './AppBar'
import Loading from './Loading'
import Menu from './Menu'
import { baseTheme } from 'theme/baseTheme'
import { useLocation } from 'react-router-dom'

export type LayoutProps = {
  title?: string
  isLoading?: boolean
}

export default function Layout({
  title,
  children,
  isLoading
}: React.PropsWithChildren<LayoutProps>) {
  const [isLearningNavigationOpen, setIsLearningNavigationOpen] = useState<boolean>(
    window.localStorage.getItem('isLearningNavigationOpen') === 'true'
  )
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(
    window.localStorage.getItem('isMenuOpen') === 'true'
  )

  const isMobileWidth = useMediaQuery(baseTheme.breakpoints.down('sm'))
  const { pathname } = useLocation()

  useEffect(() => {
    setIsLearningNavigationOpen(
      window.localStorage.getItem('isLearningNavigationOpen') !== 'false'
    )
    setIsMenuOpen(window.localStorage.getItem('isMenuOpen') !== 'false') // Change this to === 'true' if you would like the menu collapsed by default.
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    document.title = (title ? title + ' | ' : '') + 'Spud ERP'
  }, [title])

  const onToggleLearningNavigation = () => {
    isLearningNavigationOpen ? onCloseLearningNavigation() : onOpenLearningNavigation()
  }

  const onCloseLearningNavigation = () => {
    setIsLearningNavigationOpen(false)
    window.localStorage.setItem('isLearningNavigationOpen', 'false')
  }

  const onOpenLearningNavigation = () => {
    setIsLearningNavigationOpen(true)
    window.localStorage.setItem('isLearningNavigationOpen', 'true')
  }

  const onToggleMenu = () => {
    isMenuOpen ? onCloseMenu() : onOpenMenu()
  }

  const onCloseMenu = () => {
    // When closing the navigation menu, close all collapsible navigation
    onCloseLearningNavigation()
    setIsMenuOpen(false)
    window.localStorage.setItem('isMenuOpen', 'false')
  }

  const onOpenMenu = () => {
    setIsMenuOpen(true)
    window.localStorage.setItem('isMenuOpen', 'true')
  }

  return (
    <Box sx={{ display: !isMobileWidth ? 'flex' : undefined, width: '100%' }}>
      <AppBar isMenuOpen={isMenuOpen} onToggleMenu={onToggleMenu} />

      <Menu
        isLearningNavigationOpen={isLearningNavigationOpen}
        isMenuOpen={isMenuOpen}
        onCloseMenu={onCloseMenu}
        onOpenMenu={onOpenMenu}
        onToggleLearningNavigation={onToggleLearningNavigation}
      />

      <Box
        component='main'
        sx={{
          padding: {
            xs: 2
          },
          width: '100%'
        }}
      >
        {/* This Box prevents the body content from overlapping with the header (AppBar) */}
        {!isMobileWidth && <Box sx={theme => ({ ...theme.mixins.toolbar })} />}

        {isLoading && <Loading />}

        <Container maxWidth={false} disableGutters>
          {children}
        </Container>
      </Box>
    </Box>
  )
}
