import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ExplanationAccordion } from 'components/_template/accordion'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import {
  RawInventoryAdjustmentDto,
  RawInventoryDto,
  JobDto,
  rawInventoryReconcileValidationSchema,
  VendorOrderDto
} from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { IntFormat } from './NumberFields'

interface RawInventoryReconciliationDialogProps {
  rawInventory?: RawInventoryDto
  vendorOrder?: VendorOrderDto
  onClose: () => void
  open: boolean
  onSave: (values: RawInventoryAdjustmentDto) => Promise<void | RawInventoryAdjustmentDto>
}

export default function RawInventoryReconciliationDialog({
  rawInventory,
  vendorOrder,
  onClose,
  open,
  onSave
}: RawInventoryReconciliationDialogProps) {
  return (
    <Dialog open={open}>
      <Formik
        enableReinitialize
        initialValues={{
          ...new RawInventoryAdjustmentDto(),
          rawInventory: rawInventory
        }}
        validateOnBlur
        validateOnChange
        validationSchema={rawInventoryReconcileValidationSchema}
        onSubmit={values => {
          onSave(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          const onSubmitForm = () => {
            // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
            // Otherwise, sometimes the field errors won't display even though the validation throws them
            validateForm().then(errors => {
              const errorKeys = Object.keys(errors)
              if (errorKeys.length === 0) {
                submitForm()
              } else {
                setTouched(setNestedObjectValues(errors, true))
                showFormErrorsPrompt(errors)
              }
            })
          }

          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Reconcile Raw Inventory</DialogTitle>
                  <Grid item xs={12}>
                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                      Vendor Item #: {vendorOrder?.vendorItemNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                      Vendor Order #: {vendorOrder?.vendorOrderNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                      Pallet #: {rawInventory?.palletNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                      Quantity: {rawInventory?.quantityReceived}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      error={Boolean(
                        touched.quantityFormValue && errors.quantityFormValue
                      )}
                      fullWidth
                      helperText={touched.quantityFormValue && errors.quantityFormValue}
                      label='Actual Quantity'
                      name='quantityFormValue'
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue('quantityFormValue', parseInt(e.target.value))
                        setFieldValue(
                          'quantityChange',
                          parseInt(e.target.value) - (rawInventory?.quantityReceived ?? 0)
                        )
                      }}
                      InputProps={{
                        inputComponent: IntFormat as any
                      }}
                      value={values.quantityFormValue + ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      label='Notes'
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes || ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      All fields are required.||Actual Quantity replaces current quantity
                      value.||If the entire pallet is no longer considered raw inventory,
                      enter 0 for the Actual Quantity.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button
                  disabled={isSubmitting}
                  color='secondary'
                  onClick={() => {
                    onClose()
                  }}
                  tabIndex={6}
                  variant='text'
                >
                  CLOSE
                </Button>

                <Button
                  disabled={isSubmitting}
                  onClick={() => {
                    onSubmitForm()
                  }}
                  tabIndex={5}
                  variant='contained'
                >
                  SAVE
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
