import * as yup from 'yup'
import {
  fieldTypeMessage,
  maxDecimal194Message,
  maxDecimal194Value,
  maxString200Message,
  maxString200Value,
  maxString50Message,
  maxString50Value,
  minNegativeDecimal194Message,
  minNegativeDecimal194Value,
  minPositiveDecimal194Message,
  minPositiveDecimal194Value,
  requiredFieldMessage
} from '.././constants'
import { BoardTypeDto } from './boardTypes'
import { CompanyDto } from './companies'

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class RatesAssemblyAdderDto {
  id?: string = undefined
  company?: CompanyDto = undefined
  ect?: string = undefined
  adder?: string = undefined
  adderSetup?: number = undefined
  adderPerMsf?: number = undefined
  isActive?: boolean = true
}

export const ratesAssemblyAdderValidationSchema = yup.object<RatesAssemblyAdderDto>({
  id: yup.string().nullable(),
  company: yup.object().required(requiredFieldMessage),
  ect: yup.string().max(maxString200Value, maxString200Message).nullable(),
  adder: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  adderSetup: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  adderPerMsf: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
})

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class RatesBlendAdderDto {
  id?: string = undefined
  company?: CompanyDto = undefined
  ect?: string = undefined
  adder?: string = undefined
  adderSetup?: number = undefined
  adderPerMsf?: number = undefined
  isActive?: boolean = true
}

export const ratesBlendAdderValidationSchema = yup.object<RatesBlendAdderDto>({
  id: yup.string().nullable(),
  company: yup.object().required(requiredFieldMessage),
  ect: yup.string().max(maxString200Value, maxString200Message).nullable(),
  adder: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  adderSetup: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  adderPerMsf: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
})

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class RatesEndCostAdderDto {
  id?: string = undefined
  company?: CompanyDto = undefined
  ect?: string = undefined
  adder?: string = undefined
  adderSetup?: number = undefined
  adderPerMsf?: number = undefined
  description?: string = undefined
  sortOrder?: number = undefined
  isActive?: boolean = true
}

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class RatesGlobalDto {
  id?: string = undefined
  inkCostSetupCost?: number = undefined
  inkCostValue?: number = undefined
  bigJackRdc86PiecePerHour?: number = undefined
  bigJackRdc86CostPerHour?: number = undefined
  bigJackRdc86SetupCost?: number = undefined
  littleJackRdc66PiecePerHour?: number = undefined
  littleJackRdc66CostPerHour?: number = undefined
  littleJackRdc66SetupCost?: number = undefined
  stitcherPiecePerHour?: number = undefined
  stitcherCostPerHour?: number = undefined
  stitcherSetupCost?: number = undefined
  banderCost?: number = undefined
  unitizedCostBale?: number = undefined
  unitizedCostSmallWoodPallet?: number = undefined
  unitizedCostLargeWoodPallet?: number = undefined
  unitizedCostCores?: number = undefined
  wastePercentage?: number = undefined
  materialHandlingCost?: number = undefined
  warehousingCost?: number = undefined
  sgaPercent?: number = undefined
  coastalContainerSesameTape1SideCostPerInch?: number = undefined
  coastalContainerSesameTape2SideCostPerInch?: number = undefined
  georgiaPacificSesameTape1SideCostPerInch?: number = undefined
  georgiaPacificSesameTape2SideCostPerInch?: number = undefined
  viking1SesameTape1SideCostPerInch?: number = undefined
  viking1SesameTape2SideCostPerInch?: number = undefined
  viking2SesameTape1SideCostPerInch?: number = undefined
  viking2SesameTape2SideCostPerInch?: number = undefined
  isActive?: boolean = true
}

export const ratesGlobalValidationSchema = yup.object<RatesGlobalDto>({
  id: yup.string().nullable(),
  inkCostSetupCost: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  inkCostValue: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  bigJackRdc86PiecePerHour: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  bigJackRdc86CostPerHour: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  bigJackRdc86SetupCost: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  littleJackRdc66PiecePerHour: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  littleJackRdc66CostPerHour: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  littleJackRdc66SetupCost: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  stitcherPiecePerHour: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  stitcherCostPerHour: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  stitcherSetupCost: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  banderCost: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  unitizedCostBale: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  unitizedCostSmallWoodPallet: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  unitizedCostLargeWoodPallet: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  unitizedCostCores: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  wastePercentage: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  materialHandlingCost: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  warehousingCost: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  sgaPercent: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  coastalContainerSesameTape1SideCostPerInch: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  coastalContainerSesameTape2SideCostPerInch: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  georgiaPacificSesameTape1SideCostPerInch: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  georgiaPacificSesameTape2SideCostPerInch: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  viking1SesameTape1SideCostPerInch: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  viking1SesameTape2SideCostPerInch: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  viking2SesameTape1SideCostPerInch: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  viking2SesameTape2SideCostPerInch: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
})

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class RatesInkDto {
  id?: string = undefined
  gcmiColors?: string = undefined
  pantoneEquivalents?: string = undefined
  hexCode?: string = undefined
  isActive?: boolean = true
}

export const ratesInkValidationSchema = yup.object<RatesInkDto>({
  id: yup.string().nullable(),
  gcmiColors: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString50Value, maxString50Message),
  pantoneEquivalents: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString50Value, maxString50Message),
  hexCode: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString50Value, maxString50Message)
})

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class RatesPaperDto {
  id?: string = undefined
  company?: CompanyDto = undefined
  paperCode?: string = undefined
  boardType?: BoardTypeDto = undefined
  flute?: string = undefined
  ect?: string = undefined
  blend?: string = undefined
  basisWeightValue?: number = undefined
  liner1?: number = undefined
  medium1?: number = undefined
  medium1TakeUpFactor?: number = undefined
  liner2?: number = undefined
  medium2?: number = undefined
  medium2TakeUpFactor?: number = undefined
  liner3?: number = undefined
  medium3?: number = undefined
  medium3TakeUpFactor?: number = undefined
  liner4?: number = undefined
  starchFactorAllMediumsCombined?: number = undefined
  setupFee?: number = undefined
  msf0to5?: number = undefined
  msf5to10?: number = undefined
  msf10to15?: number = undefined
  msf15to30?: number = undefined
  msf30to45?: number = undefined
  msf45to60?: number = undefined
  msf60to75?: number = undefined
  isActive?: boolean = true
}

export const ratesPaperValidationSchema = yup.object<RatesPaperDto>({
  id: yup.string().nullable(),
  company: yup.object().required(requiredFieldMessage),
  paperCode: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  boardType: yup.object().required(requiredFieldMessage),
  flute: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  ect: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  blend: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  basisWeightValue: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  liner1: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  medium1: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  medium1TakeUpFactor: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  liner2: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  medium2: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  medium2TakeUpFactor: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  liner3: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  medium3: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  medium3TakeUpFactor: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  liner4: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  starchFactorAllMediumsCombined: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  setupFee: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  msf0to5: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  msf5to10: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  msf10to15: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  msf15to30: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  msf30to45: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  msf45to60: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  msf60to75: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
})

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class RatesPaperAdderDto {
  id?: string = undefined
  company?: CompanyDto = undefined
  ect?: string = undefined
  adder?: string = undefined
  adderSetup?: number = undefined
  adderPerMsf?: number = undefined
  requiresSesameTapeInches?: boolean = false
  isActive?: boolean = true
}

export const ratesPaperAdderValidationSchema = yup.object<RatesPaperAdderDto>({
  id: yup.string().nullable(),
  company: yup.object().required(requiredFieldMessage),
  ect: yup.string().max(maxString200Value, maxString200Message).nullable(),
  adder: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  adderSetup: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  adderPerMsf: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    // This is the only currently specified Rates field that allows negative values
    .min(minNegativeDecimal194Value, minNegativeDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  requiresSesameTapeInches: yup.boolean(),
  isActive: yup.boolean()
})

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class RatesScoringAdderDto {
  id?: string = undefined
  company?: CompanyDto = undefined
  ect?: string = undefined
  adder?: string = undefined
  adderSetup?: number = undefined
  adderPerMsf?: number = undefined
  isActive?: boolean = true
}

export const ratesScoringAdderValidationSchema = yup.object<RatesScoringAdderDto>({
  id: yup.string().nullable(),
  company: yup.object().required(requiredFieldMessage),
  ect: yup.string().max(maxString200Value, maxString200Message).nullable(),
  adder: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  adderSetup: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message),
  adderPerMsf: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage)
    .min(minPositiveDecimal194Value, minPositiveDecimal194Message)
    .max(maxDecimal194Value, maxDecimal194Message)
})
