import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  TextField
} from '@mui/material'
import {
  CompanyDto,
  OpportunityActivityDto,
  OpportunityActivityTypeDto,
  OpportunityDto,
  OpportunityNextActivityTypeDto,
  OpportunityProbabilityDto,
  OpportunityStatusDto,
  PersonDto
} from 'dtos'
import { opportunityActivityValidationSchema } from 'dtos/opportunityActivity'
import { Formik, setNestedObjectValues } from 'formik'
import { useEffect, useState } from 'react'
import { showFormErrorsPrompt } from './_template/form/FormErrorsPrompt'

interface Props {
  companies?: CompanyDto[]
  onClose: () => void
  onSave: (values: OpportunityActivityDto) => void
  open: boolean
  opportunity: OpportunityDto
  opportunityNextActivityTypes: OpportunityNextActivityTypeDto[]
  opportunityActivityTypes: OpportunityActivityTypeDto[]
  opportunityStatuses: OpportunityStatusDto[]
  opportunityProbabilities: OpportunityProbabilityDto[]
  people?: PersonDto[]
}

export default function OpportunityAddDialog({
  onClose,
  opportunityNextActivityTypes,
  onSave,
  open,
  opportunityActivityTypes,
  opportunity,
  opportunityStatuses,
  opportunityProbabilities
}: Props) {
  const [initialValues, setInitialValues] = useState<OpportunityActivityDto>(
    new OpportunityActivityDto()
  )

  useEffect(() => {
    setInitialValues({ ...new OpportunityActivityDto(), opportunity })
  }, [opportunity])

  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={values => {
          onSave(values)
        }}
        validateOnBlur
        validateOnChange
        validationSchema={opportunityActivityValidationSchema}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Add Activity</DialogTitle>

                  {/* <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.activityDate && errors.activityDate)}
                      fullWidth
                      helperText={touched.activityDate && errors.activityDate}
                      InputLabelProps={{ shrink: true }}
                      label='Activity Date'
                      name='activityDate'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      type='date'
                      value={values.activityDate}
                      disabled
                    />
                  </Grid> */}

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(
                        touched.opportunityActivityType && errors.opportunityActivityType
                      )}
                      fullWidth
                      helperText={
                        touched.opportunityActivityType && errors.opportunityActivityType
                      }
                      label='Activity Type'
                      name='opportunityActivityType'
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue(
                          'opportunityActivityType',
                          JSON.parse(e.target.value)
                        )
                      }}
                      select
                      size='small'
                      value={
                        values.opportunityActivityType
                          ? JSON.stringify(values.opportunityActivityType)
                          : ''
                      }
                    >
                      {opportunityActivityTypes
                        ?.sort((a, b) => (a.sequence ?? 0) - (b.sequence ?? 0))
                        .map(opportunityActivityType => (
                          <MenuItem
                            key={opportunityActivityType.id}
                            value={JSON.stringify(opportunityActivityType)}
                          >
                            {opportunityActivityType.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.activityNote && errors.activityNote)}
                      fullWidth
                      helperText={touched.activityNote && errors.activityNote}
                      label='Activity Note'
                      name='activityNote'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.activityNote}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(
                        touched.opportunityStatus && errors.opportunityStatus
                      )}
                      fullWidth
                      helperText={touched.opportunityStatus && errors.opportunityStatus}
                      label='Status'
                      name='opportunityStatus'
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue('opportunityStatus', JSON.parse(e.target.value))
                      }}
                      select
                      size='small'
                      value={
                        values.opportunityStatus
                          ? JSON.stringify(values.opportunityStatus)
                          : ''
                      }
                    >
                      {opportunityStatuses
                        ?.sort((a, b) => (a.sequence ?? 0) - (b.sequence ?? 0))
                        .map(opportunityStatus => (
                          <MenuItem
                            key={opportunityStatus.id}
                            value={JSON.stringify(opportunityStatus)}
                          >
                            {opportunityStatus.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  {('OPPORTUNITY_STATUS_OPPORTUNITY' ===
                    values.opportunityStatus?.specialIdentifier ||
                    'OPPORTUNITY_STATUS_INTERNAL_REVIEW' ===
                      values.opportunityStatus?.specialIdentifier ||
                    'OPPORTUNITY_STATUS_SEND_QUOTE' ===
                      values.opportunityStatus?.specialIdentifier ||
                    'OPPORTUNITY_STATUS_QUOTE_APPROVED' ===
                      values.opportunityStatus?.specialIdentifier) && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(
                            touched.nextActivityDate && errors.nextActivityDate
                          )}
                          fullWidth
                          helperText={touched.nextActivityDate && errors.nextActivityDate}
                          InputLabelProps={{ shrink: true }}
                          label='Next Activity Date'
                          name='nextActivityDate'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          size='small'
                          type='date'
                          value={values.nextActivityDate}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(
                            touched.opportunityNextActivityType &&
                              errors.opportunityNextActivityType
                          )}
                          fullWidth
                          helperText={
                            touched.opportunityNextActivityType &&
                            errors.opportunityNextActivityType
                          }
                          label='Next Activity Type'
                          name='opportunityNextActivityType'
                          onBlur={handleBlur}
                          onChange={e => {
                            setFieldValue(
                              'opportunityNextActivityType',
                              JSON.parse(e.target.value)
                            )
                          }}
                          select
                          size='small'
                          value={
                            values.opportunityNextActivityType
                              ? JSON.stringify(values.opportunityNextActivityType)
                              : ''
                          }
                        >
                          {opportunityNextActivityTypes
                            ?.sort((a, b) => (a.sequence ?? 0) - (b.sequence ?? 0))
                            .map(opportunityNextActivityType => (
                              <MenuItem
                                key={opportunityNextActivityType.id}
                                value={JSON.stringify(opportunityNextActivityType)}
                              >
                                {opportunityNextActivityType.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                    </>
                  )}

                  {('OPPORTUNITY_STATUS_SEND_QUOTE' ===
                    values.opportunityStatus?.specialIdentifier ||
                    'OPPORTUNITY_STATUS_QUOTE_APPROVED' ===
                      values.opportunityStatus?.specialIdentifier) && (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(
                            touched.opportunityProbability &&
                              errors.opportunityProbability
                          )}
                          fullWidth
                          helperText={
                            touched.opportunityProbability &&
                            errors.opportunityProbability
                          }
                          label='Probability of Closing Sale'
                          name='opportunityProbability'
                          onBlur={handleBlur}
                          onChange={e => {
                            setFieldValue(
                              'opportunityProbability',
                              JSON.parse(e.target.value)
                            )
                          }}
                          select
                          size='small'
                          value={
                            values.opportunityProbability
                              ? JSON.stringify(values.opportunityProbability)
                              : ''
                          }
                        >
                          {opportunityProbabilities
                            ?.sort((a, b) => (a.sequence ?? 0) - (b.sequence ?? 0))
                            .map(opportunityNextActivityType => (
                              <MenuItem
                                key={opportunityNextActivityType.id}
                                value={JSON.stringify(opportunityNextActivityType)}
                              >
                                {opportunityNextActivityType.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(
                            touched.projectedCloseDate && errors.projectedCloseDate
                          )}
                          fullWidth
                          helperText={
                            touched.projectedCloseDate && errors.projectedCloseDate
                          }
                          InputLabelProps={{ shrink: true }}
                          label='Projected Sold/Close Date'
                          name='projectedCloseDate'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          size='small'
                          type='date'
                          value={values.projectedCloseDate}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(
                            touched.projectedSaleAmount && errors.projectedSaleAmount
                          )}
                          fullWidth
                          helperText={
                            touched.projectedSaleAmount && errors.projectedSaleAmount
                          }
                          label='Projected Sale Amount'
                          name='projectedSaleAmount'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start'>$</InputAdornment>
                            )
                          }}
                          size='small'
                          type='number'
                          value={values.projectedSaleAmount}
                        />
                      </Grid>
                    </>
                  )}

                  {'OPPORTUNITY_STATUS_SOLD' ===
                    values.opportunityStatus?.specialIdentifier && (
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          touched.purchaseOrderNumber && errors.purchaseOrderNumber
                        )}
                        fullWidth
                        helperText={
                          touched.purchaseOrderNumber && errors.purchaseOrderNumber
                        }
                        label='Purchase Authorization #'
                        name='purchaseOrderNumber'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        value={values.purchaseOrderNumber}
                      />
                    </Grid>
                  )}

                  {'OPPORTUNITY_STATUS_LOST' ===
                    values.opportunityStatus?.specialIdentifier && (
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(
                          touched.reasonForLosingOpportunity &&
                            errors.reasonForLosingOpportunity
                        )}
                        fullWidth
                        helperText={
                          touched.reasonForLosingOpportunity &&
                          errors.reasonForLosingOpportunity
                        }
                        label='Reason for Losing Opportunity'
                        name='reasonForLosingOpportunity'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        size='small'
                        value={values.reasonForLosingOpportunity}
                      />
                    </Grid>
                  )}
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button variant='text' color='secondary' onClick={onClose}>
                  CLOSE
                </Button>

                <Button
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
