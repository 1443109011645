import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { ExplanationAccordion } from 'components/_template/accordion'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import { VendorOrderDto } from 'dtos'
import { VendorReorderDto, vendorReorderValidationSchema } from 'dtos/vendorOrder'
import { Formik, setNestedObjectValues } from 'formik'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { formats } from 'theme'
import { IntFormat } from './NumberFields'

interface VendorReorderDialogProps {
  onClose: () => void
  onSave: (
    values: VendorReorderDto,
    setSubmitting: (isSubmitting: boolean) => void
  ) => void
  open: boolean
  vendorOrder: VendorOrderDto
}

export default function VendorReorderDialog({
  onClose,
  onSave,
  open,
  vendorOrder
}: VendorReorderDialogProps) {
  const [initialValues, setInitialValues] = useState<VendorReorderDto>(
    new VendorReorderDto()
  )

  useEffect(() => {
    if (vendorOrder?.id) {
      setInitialValues({ ...initialValues, vendorOrderId: vendorOrder?.id })
    }
  }, [vendorOrder])

  return (
    <Dialog open={open} maxWidth='lg'>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        validationSchema={vendorReorderValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSave(values, setSubmitting)
        }}
      >
        {({
          dirty,
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Vendor Reorder</DialogTitle>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(
                        touched.assemblyCustomerOrderQuantity &&
                          errors.assemblyCustomerOrderQuantity
                      )}
                      fullWidth
                      helperText={
                        touched.assemblyCustomerOrderQuantity &&
                        errors.assemblyCustomerOrderQuantity
                      }
                      label='Customer Order Quantity'
                      name='assemblyCustomerOrderQuantity'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      InputProps={{
                        inputComponent: IntFormat as any
                      }}
                      value={values.assemblyCustomerOrderQuantity || ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(
                        touched.quoteValidUntilDate && errors.quoteValidUntilDate
                      )}
                      fullWidth
                      helperText={
                        touched.quoteValidUntilDate && errors.quoteValidUntilDate
                      }
                      InputLabelProps={{ shrink: true }}
                      label='Quote Valid Until'
                      name='quoteValidUntilDate'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='date'
                      value={
                        values?.quoteValidUntilDate
                          ? moment
                              .utc(values.quoteValidUntilDate)
                              .format(formats.dateOnlyField)
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant='body1'
                      sx={{ color: '#2780E3', fontWeight: 600 }}
                    >
                      Line Item Information
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                      SKU#: {vendorOrder.quoteLineItem?.skuNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                      Quotation Ref#: {vendorOrder.quoteLineItem?.quotationRefNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                      Spec Date: {vendorOrder.quoteLineItem?.specDate}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                      Customer Part Number:{' '}
                      {vendorOrder.quoteLineItem?.customerPartNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                      Test Run Quantity (Pieces):{' '}
                      {vendorOrder.quoteLineItem?.testRunQuantity}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                      Minimum Order Run Quantity (Pieces):{' '}
                      {vendorOrder.quoteLineItem?.minimumOrderRunQuantity}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                      Committed Quantity (Pieces):{' '}
                      {vendorOrder.quoteLineItem?.committedQuantity}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                      Contract Duration (Months):{' '}
                      {vendorOrder.quoteLineItem?.contractDuration}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                      Line Item Notes {vendorOrder.quoteLineItem?.lineItemNotes}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant='body1'
                      sx={{ color: '#2780E3', fontWeight: 600 }}
                    >
                      Vendor Order# {vendorOrder.vendorOrderNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                      Customer Order Quantity:{' '}
                      {vendorOrder.quoteLineItem?.assemblyCustomerOrderQuantity}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                      Outs: {vendorOrder.quoteLineItem?.assemblyOuts}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                      Vendor Order Quantity:{' '}
                      {vendorOrder.quoteLineItem?.checkValuesVendorOrderQuantity}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                      Vendor Item Number: {vendorOrder.vendorItemNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                      PO Number to Vendor: {vendorOrder.poNumberToVendor}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                      Date Ordered: {vendorOrder.dateOrdered}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                      Expected Received Date: {vendorOrder.expectedReceivedDate}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
                      Additional Instructions: {vendorOrder.additionalInstructions}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <ExplanationAccordion>
                    When the user clicks the "SAVE" button the opportunity and quote
                    related to the vendor order is automatically duplicated and a new
                    vendor order is created based off of the duplicated opportunity and
                    quote. The opportunity number on the duplicate quote is
                    auto-incremented. The quote number on the duplicate quote is
                    auto-incremented by 1. ||The new Opportunity Number on the Opportunity
                    List Screen and Opportunity Details Screen is followed by (Reorder
                    created from Opportunity # XXXXX-X) || The new quote number on the
                    Quote Details Screen is followed by (Reorder created from Quote #
                    XXXXX-X) ||All input values on the original quote are copied to the
                    duplicate quote, except for the Customer Order Quantity and the Quote
                    Valid Until date, which are set based on the values in this dialog.
                    The calculated cost values on the duplicate quote are re-calculated
                    based on the current values in Rates and the Customer Order Quantity
                    input values value on the duplicate quote, to show the total cost of
                    the quote with the current rates. However, the Original Cost Per Piece
                    and Original Extended Cost fields are populated from the Cost Per
                    Piece and Extended Cost values from the original quote and used to
                    populate the cost for the quote report sent to the customer. || The
                    user is navigated to the Vendor Orders List screen after clicking
                    Save.||The new vendor order can then immediately be filled out as
                    normal to send a new PO to the vendor.
                  </ExplanationAccordion>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button
                  disabled={isSubmitting}
                  variant='text'
                  color='secondary'
                  onClick={onClose}
                >
                  CLOSE
                </Button>

                <Button
                  disabled={isSubmitting}
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
