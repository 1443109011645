import * as yup from 'yup'
import {
  fieldTypeMessage,
  maxIntMessage,
  maxIntValue,
  maxString200Message,
  maxString200Value,
  minIntMessage,
  minIntValue,
  requiredFieldMessage
} from '../constants'
import { FinishedGoodsInventoryDto } from './finishedGoodsInventory'

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class FinishedGoodsInventoryAdjustmentDto {
  id?: string = undefined
  quantityChange?: number = undefined
  // This value is only used on the front-end and is used to calculate the quantityChange value that is saved to the database
  quantityFormValue?: number = undefined
  notes?: string = undefined
  finishedGoodsInventory?: FinishedGoodsInventoryDto = undefined
  isActive?: boolean = true
}

export const finishedGoodsInventoryScrapValidationSchema =
  yup.object<FinishedGoodsInventoryAdjustmentDto>({
    quantityFormValue: yup
      .number()
      .typeError(fieldTypeMessage('Number'))
      .required(requiredFieldMessage)
      .when('finishedGoodsInventory.quantity', (values, schema) => {
        // For scrapping, the quantity change field is saved as a negative number,
        // It must be between (current quantity * -1) and -1.
        if (values[0] || values[0] == 0) {
          return schema
            .min(minIntValue, minIntMessage)
            .max(values[0], 'Cannot Be More Than Quantity')
        } else {
          return schema
        }
      }),
    notes: yup
      .string()
      .max(maxString200Value, maxString200Message)
      .required(requiredFieldMessage)
  })

export const finishedGoodsInventoryReconcileValidationSchema =
  yup.object<FinishedGoodsInventoryAdjustmentDto>({
    quantityFormValue: yup
      .number()
      .typeError(fieldTypeMessage('Number'))
      .required(requiredFieldMessage)
      .min(minIntValue, minIntMessage)
      .max(maxIntValue, maxIntMessage),
    notes: yup
      .string()
      .max(maxString200Value, maxString200Message)
      .required(requiredFieldMessage)
  })
