import {
  PictureAsPdf,
  ReadMoreOutlined,
  RequestQuote,
  SearchOutlined
} from '@mui/icons-material'
import {
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import * as api from 'api'
import { VendorReorderDialog } from 'components'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import { List, ListHeaderCard, ListItemCard } from 'components/_template/list'
import { VendorOrderDto, VendorReorderDto } from 'dtos'
import { VendorOrdersParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'utils'
import { errorHandling } from '../constants'
import ReportQuote from './ReportQuote'

export default function VendorOrders() {
  const navigate = useNavigate()

  const [count, setCount] = useState<number>(0)
  const [isVendorOrderLoading, setIsVendorOrderLoading] = useState<boolean>(false)
  const [isVendorOrdersLoading, setIsVendorOrdersLoading] = useState<boolean>(false)
  const [parameters, setParameters] = useState<VendorOrdersParameters>({
    page: 0,
    pageSize: 10,
    search: ''
  })
  const [vendorOrder, setVendorOrder] = useState<VendorOrderDto>(new VendorOrderDto())
  const [vendorOrders, setVendorOrders] = useState<VendorOrderDto[]>([])
  const [vendorReorderDialogOpen, setVendorReorderDialogOpen] = useState<boolean>(false)

  const getVendorOrders = useDebounce((parameters: VendorOrdersParameters) => {
    setIsVendorOrdersLoading(true)
    api
      .getVendorOrders(parameters)
      .then(res => {
        setVendorOrders(res.value)
        setCount(res.totalCount!)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsVendorOrdersLoading(false)
      })
  }, 300)

  useEffect(() => {
    getVendorOrders(parameters)
  }, [parameters])

  const onOpenVendorReorderDialog = (id: string) => {
    setIsVendorOrderLoading(true)
    api
      .getVendorOrderById(id)
      .then(({ value }) => {
        setVendorOrder(value)
        setVendorReorderDialogOpen(true)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsVendorOrderLoading(false)
      })
  }

  const onReorderVendorOrder = (
    vendorReorder: VendorReorderDto,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    setIsVendorOrderLoading(true)
    api
      .reorderVendorOrder(vendorReorder)
      .then(({ value }) => {
        getVendorOrders(parameters)
        setVendorReorderDialogOpen(false)
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {
        setIsVendorOrderLoading(false)
        setSubmitting(false)
      })
  }

  const openQuoteReportWindow = (quoteLineItemId: string) => {
    api
      .getQuoteReportByLineItemId(quoteLineItemId)
      .then(response => {
        const newWindow = window.open('', '_blank', 'width=800,height-600')
        if (newWindow) {
          newWindow.document.body.innerHTML =
            '<div id="print-quote-report-container"></div>'

          const root = createRoot(
            newWindow.document.getElementById(
              'print-quote-report-container'
            ) as HTMLElement
          )

          root.render(<ReportQuote reportQuoteDto={response.value} />)
        } else {
          console.error('Failed to open a new window.')
        }
      })
      .catch((errors: string[]) => {
        errorHandling(errors)
      })
      .finally(() => {})
  }

  return (
    <Layout
      title='Vendor Orders'
      isLoading={isVendorOrderLoading || isVendorOrdersLoading}
    >
      <VendorReorderDialog
        open={vendorReorderDialogOpen}
        onClose={() => {
          setVendorReorderDialogOpen(false)
        }}
        onSave={(vendorReorder, setSubmitting) => {
          onReorderVendorOrder(vendorReorder, setSubmitting)
        }}
        vendorOrder={vendorOrder}
      />
      <List
        pagination={{
          page: parameters.page,
          pageSize: parameters.pageSize,
          count,
          onPageChange: page => setParameters({ ...parameters, page }),
          onPageSizeChange: pageSize => setParameters({ ...parameters, pageSize })
        }}
        header={<ListHeaderCard title='Vendor Orders' actions={[]} />}
      >
        <ListItemCard>
          <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={4}>
              <TextField
                data-search
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchOutlined fontSize='small' />
                    </InputAdornment>
                  )
                }}
                label='Search'
                onChange={e => {
                  setParameters({ ...parameters, search: e.target.value })
                }}
                size='small'
                type='search'
                value={parameters.search}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={parameters.includeOrdered}
                    checked={parameters.includeOrdered}
                    onChange={e => {
                      setParameters({
                        ...parameters,
                        includeOrdered: e.target.checked
                      })
                    }}
                  />
                }
                label='Include Already Ordered'
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={parameters.includeReceivedPartial}
                    checked={parameters.includeReceivedPartial}
                    onChange={e => {
                      setParameters({
                        ...parameters,
                        includeReceivedPartial: e.target.checked
                      })
                    }}
                  />
                }
                label='Include Partially Received'
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={parameters.includeReceivedInFull}
                    checked={parameters.includeReceivedInFull}
                    onChange={e => {
                      setParameters({
                        ...parameters,
                        includeReceivedInFull: e.target.checked
                      })
                    }}
                  />
                }
                label='Include Fully Received'
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={parameters.includeNeedToOrder}
                    checked={parameters.includeNeedToOrder}
                    onChange={e => {
                      setParameters({
                        ...parameters,
                        includeNeedToOrder: e.target.checked
                      })
                    }}
                  />
                }
                label='Include Need To Order'
              />
            </Grid>
          </Grid>
        </ListItemCard>

        {vendorOrders.map((vendorOrder, index) => (
          <ListItemCard
            chips={[
              <Chip
                label={vendorOrder.vendorOrderStatus?.name}
                color='primary'
                variant='outlined'
              />
            ]}
            key={vendorOrder.id}
            title={
              (vendorOrder.vendorOrderStatus?.specialIdentifier === 'NEED_TO_ORDER'
                ? 'Need To Order'
                : 'Vendor Order #' + vendorOrder.vendorOrderNumber) +
              ' - ' +
              vendorOrder.company?.name
            }
            actions={[
              <Tooltip
                title={
                  vendorOrder?.readonly_isVendorReorder
                    ? 'Reorders Cannot Be Created From Other Reorders'
                    : 'Reorder'
                }
              >
                <span>
                  <IconButton
                    disabled={vendorOrder?.readonly_isVendorReorder}
                    onClick={() => {
                      onOpenVendorReorderDialog(vendorOrder.id ?? '')
                    }}
                    color='primary'
                  >
                    <RequestQuote fontSize='large' />
                  </IconButton>
                </span>
              </Tooltip>,
              <IconButton
                onClick={() => {
                  openQuoteReportWindow(vendorOrder?.quoteLineItem?.id ?? '')
                }}
                color='primary'
              >
                <PictureAsPdf fontSize='large' />
              </IconButton>,
              <Tooltip title='View Details'>
                <IconButton
                  onClick={() => navigate('/vendor-orders/' + vendorOrder.id)}
                  color='primary'
                >
                  <ReadMoreOutlined fontSize='large' />
                </IconButton>
              </Tooltip>
            ]}
          >
            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              SKU#: {vendorOrder.quoteLineItem?.skuNumber}
            </Typography>

            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              Vendor Item Number: {vendorOrder.vendorItemNumber}
            </Typography>

            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              Quantity: {vendorOrder.quoteLineItem?.checkValuesVendorOrderQuantity}
            </Typography>
          </ListItemCard>
        ))}
        <Grid item xs={12}>
          <ExplanationAccordion>
            Orders appear automatically based on a quote being marked as sold. || Prior to
            being ordered the card title shows "Need to Order - [Vendor]". Once ordered,
            the card title shows "Order# [Order #] - [Vendor]". || The card details shows
            "Vendor Item Number: [VENDOR ITEM NUMBER from the Vendor Order details]",
            "SKU#: [SKU# from the Quote details]", and "Quantity: [ORDER QUANTITY from the
            Quote details]". || Pressing the $ icon opens the Vendor Reorder modal. ||
            Pressing the PDF icon opens the Quote PDF (not vendor purchase order PDF) that
            the selected vendor order is based off. || Chips show the order status: Need
            to Order, Ordered, Partial Received, Received in Full.
          </ExplanationAccordion>
        </Grid>
      </List>
    </Layout>
  )
}
