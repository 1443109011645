import * as yup from 'yup'
import { maxString200Message, maxString200Value } from '../constants'
import { ForkliftPalletLocationDto } from './forkliftPalletLocation'
import { JobDto } from './job'
import { ShippingItemDto } from './shipping'

// Formik and Yup do not behave correctly when the fields are initialized to a value other than `undefined`
// so mark every non-boolean Dto property referenced in the validation as optional, initialized to undefined,
// and use Yup and the back-end logic for validation
export class FinishedGoodsInventoryDto {
  id?: string = undefined
  job?: JobDto = undefined
  bundleNumber?: string = undefined
  banderNumber?: string = undefined
  quantity?: number = undefined
  createdDateTimeUtc?: Date = undefined
  forkliftPalletLocation?: ForkliftPalletLocationDto = undefined
  shippingItem?: ShippingItemDto = undefined
  isActive?: boolean = true
}

export const finishedGoodsValidationSchema = yup.object<JobDto>({
  finishedGoodItemDescription: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .nullable(),
  finishedGoodGenericDescription: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .nullable(),
  finishedGoodItemType: yup.object().nullable(),
  dieSkuNumber: yup.string().max(maxString200Value, maxString200Message).nullable(),
  dieItemDescription: yup.string().max(maxString200Value, maxString200Message).nullable(),
  dieGenericDescription: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .nullable(),
  dieItemType: yup.object().nullable(),
  printPlateSkuNumber: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .nullable(),
  printPlateItemDescription: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .nullable(),
  printPlateGenericDescription: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .nullable(),
  printPlateItemType: yup.object().nullable(),
  inkSkuNumber: yup.string().max(maxString200Value, maxString200Message).nullable(),
  inkItemDescription: yup.string().max(maxString200Value, maxString200Message).nullable(),
  inkGenericDescription: yup
    .string()
    .max(maxString200Value, maxString200Message)
    .nullable(),
  inkItemType: yup.object().nullable()
})
