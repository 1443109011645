import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { Layout } from 'components/_template'
import { ExplanationAccordion } from 'components/_template/accordion'
import { SyntheticEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import RequestPageIcon from '@mui/icons-material/RequestPage'
import { Chip, Grid, Typography } from '@mui/material'
import Divider from '@mui/material/Divider'
import {
  WorkOrderInvoiceAddEditDialog,
  WorkOrderReleaseAddEditDialog,
  WorkOrderTaskAddEditDialog
} from 'components'
import {
  Details,
  DetailsActions,
  DetailsForm,
  DetailsHeaderCard,
  DetailsTab
} from 'components/_template/details'
import { ListItemCard } from 'components/_template/list'
import { LearningCourseSectionDto } from 'dtos'

export default function OpportunityDetails() {
  const navigate = useNavigate()
  const [value, setValue] = useState<string>('tab1')

  const [workOrderReleaseDialogOpen, setWorkOrderReleaseDialogOpen] =
    useState<boolean>(false)
  const [workOrderTaskDialogOpen, setWorkOrderTaskDialogOpen] = useState<boolean>(false)
  const [workOrderInvoiceDialogOpen, setWorkOrderInvoiceDialogOpen] =
    useState<boolean>(false)
  const [workOrderTaskAddEditDialogOpen, setWorkOrderTaskAddEditDialogOpen] =
    useState<boolean>(false)
  const [learningCourseSection, setLearningCourseSection] =
    useState<LearningCourseSectionDto>(new LearningCourseSectionDto())
  const handleTabsChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Layout title='Work order details'>
      <WorkOrderReleaseAddEditDialog
        learningCourseSection={learningCourseSection}
        open={workOrderReleaseDialogOpen}
        onClose={() => {
          setWorkOrderReleaseDialogOpen(false)
        }}
        onSave={savedLearningCourseSection => {
          setWorkOrderReleaseDialogOpen(false)
        }}
      />
      <WorkOrderTaskAddEditDialog
        learningCourseSection={learningCourseSection}
        open={workOrderTaskDialogOpen}
        onClose={() => {
          setWorkOrderTaskDialogOpen(false)
        }}
        onSave={savedLearningCourseSection => {
          setWorkOrderTaskDialogOpen(false)
        }}
      />
      <WorkOrderInvoiceAddEditDialog
        learningCourseSection={learningCourseSection}
        open={workOrderInvoiceDialogOpen}
        onClose={() => {
          setWorkOrderInvoiceDialogOpen(false)
        }}
        onSave={savedLearningCourseSection => {
          setWorkOrderInvoiceDialogOpen(false)
        }}
      />
      <Details
        header={<DetailsHeaderCard title='Work Order Details - ACME Motors' />}
        tabs={[
          { value: 'tab1', label: 'Details' }
          // { value: 'tab2', label: 'Jobs' }
        ]}
        onSubmit={e => e.preventDefault()}
      >
        {/* TAB 1 */}
        {/* TAB 1 */}
        {/* TAB 1 */}

        <DetailsTab value='tab1'>
          <DetailsForm>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                  Project manager: Jason Stone
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant='body1' sx={{ color: '#7F7F7F' }}>
                  Salesperson manager: Trevor Ross
                </Typography>
              </Grid>
            </Grid>
          </DetailsForm>
          {/* JOB INFO 1*/}
          {/* JOB INFO 1*/}
          {/* JOB INFO 1*/}
          <Grid item xs={12}>
            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              .
            </Typography>
          </Grid>

          <ListItemCard
            chips={[
              <Chip
                key='vendor'
                label='Waiting on inventory'
                color='error'
                variant='outlined'
              />
            ]}
            key={1}
            title={'Job#: 123-001-1'}
          >
            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              {'Sheet Z - Quantity 100'}
            </Typography>
          </ListItemCard>
          {/* JOB INFO 2*/}
          {/* JOB INFO 2*/}
          {/* JOB INFO 2*/}
          <Grid item xs={12}>
            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              .
            </Typography>
          </Grid>

          <ListItemCard
            chips={[
              <Chip key='vendor' label='Not scheduled' color='error' variant='outlined' />
            ]}
            key={1}
            title={'Job#: 123-001-2'}
          >
            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              {'Sheet Z - Quantity 100'}
            </Typography>
          </ListItemCard>
          {/* JOB INFO 3*/}
          {/* JOB INFO 3*/}
          {/* JOB INFO 3*/}
          <Grid item xs={12}>
            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              .
            </Typography>
          </Grid>

          <ListItemCard
            chips={[
              <Chip key='vendor' label='Scheduled' color='error' variant='outlined' />
            ]}
            key={1}
            title={'Job#: 123-001-3'}
          >
            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              {'Sheet Z - Quantity 100'}
            </Typography>
          </ListItemCard>

          {/* JOB INFO 4*/}
          {/* JOB INFO 4*/}
          {/* JOB INFO 4*/}
          <Grid item xs={12}>
            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              .
            </Typography>
          </Grid>

          <ListItemCard
            chips={[
              <Chip key='vendor' label='Complete' color='error' variant='outlined' />
            ]}
            key={1}
            title={'Job#: 123-001-3'}
          >
            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              {'Sheet Z - Quantity 100'}
            </Typography>
          </ListItemCard>
          {/* ACCORDION NOTES CARD */}
          {/* ACCORDION NOTES CARD */}
          {/* ACCORDION NOTES CARD */}
          <Grid item xs={12}>
            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              .
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {/* <ExplanationAccordion>
            	      xxxxxxxxxxxxxxxxxxx||xxxxxxxxxxxxxxxxxxx 
             	   </ExplanationAccordion> */}
            <ExplanationAccordion development>
              Chip options are Waiting on inventory, Not scheduled, Scheduled, Complete
            </ExplanationAccordion>
          </Grid>
        </DetailsTab>
        {/* TAB 2 */}
        {/* TAB 2 */}
        {/* TAB 2 */}

        <DetailsTab value='tab2'>
          <ListItemCard
            chips={[
              <Chip key='vendor' label='Not scheduled' color='error' variant='outlined' />
            ]}
            key={1}
            title={'Job#: 123-001-1'}
          >
            <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
              {'Sheet Z - Quantity 100'}
            </Typography>
          </ListItemCard>
          <Grid container spacing={2} alignItems='center'>
            {/* OLD STUFF */}
            {/* OLD STUFF */}
            {/* OLD STUFF */}
            {/* OLD STUFF */}
            {/* OLD STUFF */}
            {/* OLD STUFF */}
            {/* OLD STUFF */}
            {/* OLD STUFF */}
            {/* OLD STUFF */}
            {/* OLD STUFF */}

            {/* <Grid
              item
              xs={12}
              container
              alignItems='center'
              justifyContent='space-between'
            >
              <Grid item xs={12} sm={9}>
                <Typography variant='h6' sx={{ color: '#7F7F7F' }}>
                  Jobs / Bundles
                </Typography>
              </Grid>

              <Grid item>
                <Button
                  variant='outlined'
                  onClick={() => {
                    setWorkOrderReleaseDialogOpen(true)
                  }}
                >
                  Add Job
                </Button>
              </Grid>
            </Grid>

            <>
              <Grid
                item
                xs={12}
                container
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item xs={12} sm={6} container spacing={1}>
                  <Grid item>
                    <ListAlt color='primary' />
                  </Grid>

                  <Grid item>
                    <Typography variant='body1'>
                      {'MC4556677 - 8/15/2023 - 200/500 pieces'}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  alignItems='center'
                  spacing={1}
                  justifyContent='flex-end'
                >
                  <Grid item>
                    <Button
                      size='small'
                      onClick={() => {
                        setWorkOrderTaskDialogOpen(true)
                      }}
                    >
                      ADD Bundle
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      size='small'
                      color='primary'
                      onClick={() => {
                        setWorkOrderReleaseDialogOpen(true)
                      }}
                    >
                      EDIT
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button size='small' color='secondary'>
                      DELETE
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                container
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item xs={12} sm={6} container spacing={1}>
                  <Grid item>
                    <Typography variant='body1'>100 piece bundle</Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  alignItems='center'
                  spacing={1}
                  justifyContent='flex-end'
                >
                  <Grid item>
                    <Button
                      size='small'
                      color='primary'
                      onClick={() => {
                        setWorkOrderTaskDialogOpen(true)
                      }}
                    >
                      EDIT
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button size='small' color='secondary'>
                      DELETE
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item xs={12} sm={6} container spacing={1}>
                  <Grid item>
                    <Typography variant='body1'>100 piece bundle</Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  alignItems='center'
                  spacing={1}
                  justifyContent='flex-end'
                >
                  <Grid item>
                    <Button
                      size='small'
                      color='primary'
                      onClick={() => {
                        setWorkOrderTaskDialogOpen(true)
                      }}
                    >
                      EDIT
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button size='small' color='secondary'>
                      DELETE
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
            <>
              <Grid
                item
                xs={12}
                container
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item xs={12} sm={6} container spacing={1}>
                  <Grid item>
                    <ListAlt color='primary' />
                  </Grid>

                  <Grid item>
                    <Typography variant='body1'>
                      {'MC4556677 - Due: 8/15/2023 - 200/500 pieces'}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  alignItems='center'
                  spacing={1}
                  justifyContent='flex-end'
                >
                  <Grid item>
                    <Button
                      size='small'
                      onClick={() => {
                        setWorkOrderTaskDialogOpen(true)
                      }}
                    >
                      ADD Bundle
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      size='small'
                      color='primary'
                      onClick={() => {
                        setWorkOrderReleaseDialogOpen(true)
                      }}
                    >
                      EDIT
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button size='small' color='secondary'>
                      DELETE
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                container
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item xs={12} sm={6} container spacing={1}>
                  <Grid item>
                    <Typography variant='body1'>100 piece bundle</Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  alignItems='center'
                  spacing={1}
                  justifyContent='flex-end'
                >
                  <Grid item>
                    <Button
                      size='small'
                      color='primary'
                      onClick={() => {
                        setWorkOrderTaskDialogOpen(true)
                      }}
                    >
                      EDIT
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button size='small' color='secondary'>
                      DELETE
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item xs={12} sm={6} container spacing={1}>
                  <Grid item>
                    <Typography variant='body1'>100 piece bundle</Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  alignItems='center'
                  spacing={1}
                  justifyContent='flex-end'
                >
                  <Grid item>
                    <Button
                      size='small'
                      color='primary'
                      onClick={() => {
                        setWorkOrderTaskDialogOpen(true)
                      }}
                    >
                      EDIT
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button size='small' color='secondary'>
                      DELETE
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </> */}
          </Grid>
        </DetailsTab>

        {/* TAB 3 */}
        {/* TAB 3 */}
        {/* TAB 3 */}

        <DetailsTab value='tab3'>
          <Grid container spacing={2} alignItems='center'>
            <Grid
              item
              xs={12}
              container
              alignItems='center'
              justifyContent='space-between'
            >
              <Grid item xs={12} sm={6}>
                <Typography variant='h6' sx={{ color: '#7F7F7F' }}></Typography>
              </Grid>

              <Grid item>
                <Button
                  variant='outlined'
                  onClick={() => {
                    setWorkOrderInvoiceDialogOpen(true)
                  }}
                >
                  Add Invoice
                </Button>
              </Grid>
            </Grid>

            <>
              <Grid
                item
                xs={12}
                container
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item xs={12} sm={6} container spacing={1}>
                  <Grid item>
                    <RequestPageIcon color='primary' />
                  </Grid>

                  <Grid item>
                    <Typography variant='body1'>
                      {'Need to send invoice on 6/15/2023'}
                      <Typography variant='body1'>Includes 40 prepaid hours</Typography>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  alignItems='center'
                  spacing={1}
                  justifyContent='flex-end'
                >
                  <Grid item>
                    <Button
                      size='small'
                      color='primary'
                      onClick={() => {
                        setWorkOrderInvoiceDialogOpen(true)
                      }}
                    >
                      EDIT
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button size='small' color='secondary'>
                      DELETE
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Divider></Divider>
              <Grid
                item
                xs={12}
                container
                alignItems='center'
                justifyContent='space-between'
              >
                <Grid item xs={12} sm={6} container spacing={1}>
                  <Grid item>
                    <RequestPageIcon color='primary' />
                  </Grid>

                  <Grid item>
                    <Typography variant='body1'>
                      {'Invoice# 25507-2023 - Sent on 5/1/2023'}
                      <Typography variant='body1'>Received 5/1/2023</Typography>
                      <Typography variant='body1'>Includes 40 prepaid hours</Typography>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  alignItems='center'
                  spacing={1}
                  justifyContent='flex-end'
                >
                  <Grid item>
                    <Button
                      size='small'
                      color='primary'
                      onClick={() => {
                        setWorkOrderInvoiceDialogOpen(true)
                      }}
                    >
                      EDIT
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button size='small' color='secondary'>
                      DELETE
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </Grid>
        </DetailsTab>

        {/* BUTTONS */}
        {/* BUTTONS */}
        {/* BUTTONS */}

        <DetailsActions>
          <Button
            color='secondary'
            onClick={() => {
              navigate('/work-orders')
            }}
            variant='text'
          >
            BACK
          </Button>

          <LoadingButton
            color='primary'
            onClick={() => {
              navigate('/work-orders')
            }}
            type='submit'
            variant='contained'
          >
            <span>{'Save'}</span>
          </LoadingButton>
        </DetailsActions>
      </Details>
    </Layout>
  )
}
