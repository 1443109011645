import { createTheme } from '@mui/material/styles'
import { baseTheme } from './baseTheme'

// create dark theme
export const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'dark'
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        enableColorOnDark: true
      }
    }
  }
})
