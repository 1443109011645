import { ApiResponse, RatesPaperAdderDto } from 'dtos'
import { RatesPaperAddersParameters } from 'parameters'
import { URIQuery } from 'utils'
import apiClient from '.'

export const getRatesPaperAdders = (parameters: RatesPaperAddersParameters) =>
  apiClient
    .get<ApiResponse<RatesPaperAdderDto[]>>(
      '/api/rates-paper-adders' + URIQuery({ ...parameters })
    )
    .then(({ data }) => data)

export const getRatesPaperAdderById = (id: string) =>
  apiClient
    .get<ApiResponse<RatesPaperAdderDto>>('/api/rates-paper-adders/' + id)
    .then(({ data }) => data)

export const createRatesPaperAdder = (values: RatesPaperAdderDto) =>
  apiClient
    .post<ApiResponse<RatesPaperAdderDto>>('/api/rates-paper-adders', values)
    .then(({ data }) => data)

export const updateRatesPaperAdder = (values: RatesPaperAdderDto) =>
  apiClient
    .put<ApiResponse<RatesPaperAdderDto>>('/api/rates-paper-adders', values)
    .then(({ data }) => data)
