import { base64ToArrayBuffer } from 'utils'
import apiClient from '.'

export const downloadQuoteLineItemDesignFile = (fileName: string) =>
  apiClient
    .get('/api/quote-line-item-design-files/' + encodeURIComponent(fileName))
    .then(({ data }) => {
      const buffer = base64ToArrayBuffer(data)
      const file = new Blob([buffer])
      const anchor = document.createElement('a')
      anchor.href = window.URL.createObjectURL(file)
      anchor.download = fileName
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
    })
