import { CircularProgress, styled } from '@mui/material'

const LoadingContainer = styled('div')(() => ({
  position: 'fixed',
  zIndex: 1,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.6)'
}))

export default function Loading() {
  return (
    <LoadingContainer>
      <CircularProgress color='primary' size={70} />
    </LoadingContainer>
  )
}
