import * as yup from 'yup'
import { fieldTypeMessage, requiredFieldMessage } from '../constants'
import { JobDto } from './job'

export const littleJackToDoValidationSchema = yup.object<JobDto>({
  id: yup.string().nullable(),
  scheduleDate: yup.string().required(requiredFieldMessage),
  priority: yup
    .number()
    .typeError(fieldTypeMessage('Number'))
    .required(requiredFieldMessage),
  jobStatus: yup.object().required(requiredFieldMessage),
  isActive: yup.boolean().required(requiredFieldMessage)
})
