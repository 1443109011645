import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField
} from '@mui/material'
import { AddressDto, AddressTypeDto, addressValidationSchema } from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import React from 'react'
import { showFormErrorsPrompt } from './_template/form/FormErrorsPrompt'

type Props = {
  address?: AddressDto
  addressTypes: AddressTypeDto[]
  onClose: () => void
  onSave: (values: any) => void
  open: boolean
}

export default function AddressAddEditDialog({
  address = new AddressDto(),
  addressTypes,
  onClose,
  onSave,
  open
}: React.PropsWithChildren<Props>) {
  return (
    <Dialog open={open} onClose={onClose}>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...address }}
        validateOnChange={true}
        validateOnBlur={false}
        validationSchema={addressValidationSchema}
        onSubmit={values => {
          onSave(values)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>Address Details</DialogTitle>

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.addressType && errors.addressType)}
                      fullWidth
                      helperText={touched.addressType && errors.addressType}
                      label='Address Type'
                      name='addressType'
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue('addressType', JSON.parse(e.target.value))
                      }}
                      select
                      size='small'
                      value={
                        values?.addressType ? JSON.stringify(values.addressType) : ''
                      }
                    >
                      {addressTypes
                        ?.sort((a, b) => a.name.localeCompare(b.name))
                        .map(addressType => (
                          <MenuItem
                            value={JSON.stringify(addressType)}
                            key={addressType.id}
                          >
                            {addressType.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      label='Address Description'
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='HQ'
                      size='small'
                      value={values.notes}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={Boolean(touched.phone && errors.phone)}
                      fullWidth
                      helperText={touched.phone && errors.phone}
                      label='Phone No.'
                      name='phone'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='+1-123-456-8790'
                      size='small'
                      value={values.phone}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      label='Email'
                      name='email'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='company@company.com'
                      size='small'
                      value={values.email}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={Boolean(touched.address1 && errors.address1)}
                      fullWidth
                      helperText={touched.address1 && errors.address1}
                      label='Address 1'
                      name='address1'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='123 Some Street'
                      size='small'
                      value={values.address1}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={Boolean(touched.address2 && errors.address2)}
                      fullWidth
                      helperText={touched.address2 && errors.address2}
                      label='Address 2'
                      name='address2'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='123 Some Street'
                      size='small'
                      value={values.address2}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6}>
                    <TextField
                      error={Boolean(touched.city && errors.city)}
                      fullWidth
                      helperText={touched.city && errors.city}
                      label='City'
                      name='city'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='Hometown'
                      size='small'
                      value={values.city}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      error={Boolean(touched.state && errors.state)}
                      fullWidth
                      helperText={touched.state && errors.state}
                      label='State'
                      name='state'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.state}
                    />
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <TextField
                      error={Boolean(touched.postalCode && errors.postalCode)}
                      fullWidth
                      helperText={touched.postalCode && errors.postalCode}
                      label='Postal Code'
                      name='postalCode'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder='12345'
                      size='small'
                      value={values.postalCode}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      error={Boolean(touched.country && errors.country)}
                      fullWidth
                      helperText={touched.country && errors.country}
                      label='Country'
                      name='country'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      size='small'
                      value={values.country}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isActive}
                          name='isActive'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.isActive}
                          size='small'
                        />
                      }
                      label='Active'
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button variant='text' color='secondary' onClick={onClose}>
                  CLOSE
                </Button>

                <Button
                  onClick={() => {
                    // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                    // Otherwise, sometimes the field errors won't display even though the validation throws them
                    validateForm().then(errors => {
                      const errorKeys = Object.keys(errors)
                      if (errorKeys.length === 0) {
                        submitForm()
                      } else {
                        setTouched(setNestedObjectValues(errors, true))
                        showFormErrorsPrompt(errors)
                      }
                    })
                  }}
                  variant='contained'
                >
                  SAVE
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
