import { ReadMoreOutlined, SearchOutlined } from '@mui/icons-material'
import {
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import apiClient from 'api'
import { Layout } from 'components/_template'
import { List, ListHeaderCard, ListItemCard } from 'components/_template/list'
import { CompanyDto } from 'dtos'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { URIQuery, useDebounce } from 'utils'

import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import { Avatar, Box, Grid } from '@mui/material'
import { grey, orange } from '@mui/material/colors'
import { useSnackbar } from 'notistack'
import { errorHandling } from '../constants'

export default function WorkOrders() {
  // #region hooks
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const getCompanies = useDebounce(
    (page: number, pageSize: number, search: string, activeOnly: boolean) => {
      setIsGettingCompanies(true)
      apiClient
        .get('/api/companies' + URIQuery({ page, pageSize, search, activeOnly }))
        .then(res => {
          setCompanies(res.data.value)
          setCount(res.data.totalCount)
        })
        .catch((errors: string[]) => {
          errorHandling(errors)
        })
        .finally(() => {
          setIsGettingCompanies(false)
        })
    },
    300
  )
  // #endregion

  // #region useState
  const [companies, setCompanies] = useState<CompanyDto[]>([])
  const [count, setCount] = useState<number>(0)
  const [isGettingCompanies, setIsGettingCompanies] = useState<boolean>(false)
  const [parameters, setParameters] = useState<{
    page: number
    pageSize: number
    search: string
    activeOnly: boolean
  }>({ page: 0, pageSize: 10, search: '', activeOnly: true })
  // #endregion

  // #region useEffect
  useEffect(() => {
    getCompanies(
      parameters.page,
      parameters.pageSize,
      parameters.search,
      parameters.activeOnly
    )

    return () => {}
  }, [parameters])
  // #endregion

  return (
    <Layout title='WorkOrders' isLoading={isGettingCompanies}>
      <List
        pagination={{
          page: parameters.page,
          pageSize: parameters.pageSize,
          count,
          onPageChange: page => setParameters({ ...parameters, page }),
          onPageSizeChange: pageSize => setParameters({ ...parameters, pageSize })
        }}
        header={<ListHeaderCard title='Work Orders' />}
      >
        {/* FILTER CARD */}
        {/* FILTER CARD */}
        {/* FILTER CARD */}

        <ListItemCard>
          <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
            <Grid item xs={12} sm={3}>
              <TextField
                data-search
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchOutlined fontSize='small' />
                    </InputAdornment>
                  )
                }}
                label='Search'
                name='search'
                size='small'
                type='search'
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControlLabel checked={true} control={<Checkbox />} label='Open only' />
            </Grid>
          </Grid>
        </ListItemCard>
        {/* SUMMARY CARD */}
        {/* SUMMARY CARD */}
        {/* SUMMARY CARD */}

        <ListItemCard>
          <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
            {/* STAT 1 */}
            {/* STAT 1 */}
            {/* STAT 1 */}
            <Grid item>
              {' '}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  variant='rounded'
                  sx={{
                    bgcolor: 'white',
                    color: grey[400],
                    ml: -1,
                    mr: 1,
                    boxShadow: 3
                  }}
                >
                  <HomeRepairServiceIcon />
                </Avatar>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='caption'>Open</Typography>
                  <Typography variant='h6' sx={{ ml: 1 }}>
                    {14}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* STAT 2 */}
            {/* STAT 2 */}
            {/* STAT 2 */}
            <Grid item>
              {' '}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  variant='rounded'
                  sx={{
                    bgcolor: 'white',
                    color: orange[500],
                    ml: -1,
                    mr: 1,
                    boxShadow: 3
                  }}
                >
                  <HourglassEmptyIcon />
                </Avatar>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant='caption'>Waiting on inventory</Typography>
                  <Typography variant='h6' sx={{ ml: 1 }}>
                    {7}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ListItemCard>

        {/* DATA CARD */}
        {/* DATA CARD */}
        {/* DATA CARD */}

        <ListItemCard
          chips={[
            <Chip
              key='vendor'
              label='Waiting on inventory'
              color='primary'
              variant='outlined'
            />
          ]}
          key={1}
          title={'ACME Motors'}
          actions={[
            <Tooltip title={'Details'}>
              <IconButton
                onClick={() => navigate('/work-order/new')}
                color='primary'
                // size='large'
              >
                <ReadMoreOutlined fontSize='large' />
              </IconButton>
            </Tooltip>
          ]}
        >
          <Typography variant='body2' sx={{ color: '#7F7F7F', fontWeight: 600 }}>
            {' Job#: 123-001-1'}
          </Typography>
          <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
            {'Authorization# 123'}
          </Typography>
          <Typography variant='body2' sx={{ color: '#7F7F7F' }}>
            {'Sheet Z - Quantity 100'}
          </Typography>
        </ListItemCard>
      </List>
    </Layout>
  )
}
