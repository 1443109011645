import { ApiResponse, QuoteAssemblyJointDto } from 'dtos'
import { QuoteAssemblyJointsParameters } from 'parameters'
import { URIQuery } from 'utils'
import apiClient from '.'

export const getQuoteAssemblyJoints = (parameters: QuoteAssemblyJointsParameters) =>
  apiClient
    .get<ApiResponse<QuoteAssemblyJointDto[]>>(
      '/api/quote-assembly-joints' + URIQuery({ ...parameters })
    )
    .then(res => res.data)
