import { Button, TextField } from '@mui/material'
import { GuestLayout } from 'components/_template'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import { ApiResponse, ForgotPasswordDto, forgotPasswordValidationSchema } from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { errorHandling } from '../constants'
import apiClient from 'api'

export default function ForgotPassword() {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Forgot Password | Spud ERP'
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={new ForgotPasswordDto()}
      validateOnChange
      validateOnBlur
      validationSchema={forgotPasswordValidationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await apiClient
          .post<ApiResponse<string>>('/api/account/forgot-password', values)
          .then(({ data: { value } }) => {
            enqueueSnackbar(value, { variant: 'success' })
          })
          .catch((errors: string[]) => {
            errorHandling(errors)
          })
          .finally(() => navigate('/account/sign-in'))
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        setTouched,
        submitForm,
        touched,
        validateForm,
        values
      }) => {
        return (
          <GuestLayout handleSubmit={handleSubmit} title='Forgot Password'>
            <TextField
              autoComplete='email'
              autoFocus
              disabled={isSubmitting}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label='Email Address'
              margin='normal'
              name='email'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email || ''}
            />

            <Button
              onClick={() => {
                // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
                // Otherwise, sometimes the field errors won't display even though the validation throws them
                validateForm().then(errors => {
                  const errorKeys = Object.keys(errors)
                  if (errorKeys.length === 0) {
                    submitForm()
                  } else {
                    setTouched(setNestedObjectValues(errors, true))
                    showFormErrorsPrompt(errors)
                  }
                })
              }}
              fullWidth
              variant='contained'
              disabled={isSubmitting}
            >
              SEND PASSWORD RESET EMAIL
            </Button>
          </GuestLayout>
        )
      }}
    </Formik>
  )
}
