import { Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'

export type DetailsHeaderProps = {
  title: string
  // actions: React.ReactNode
}

export default function DetailsHeaderCard({ title }: DetailsHeaderProps) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Typography variant='h5'>{title}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
