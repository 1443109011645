import * as yup from 'yup'
import { CompanyDto } from './companies'
import { PermissionDto } from './permissions'
import {
  fieldTypeMessage,
  maxString200Message,
  maxString200Value,
  maxTextFieldMessage,
  requiredFieldMessage
} from '../constants'
import { DepartmentDto } from './departments'

export type PeopleWithCompany = {}

export class PersonDto {
  id?: string = undefined
  firstName: string = ''
  lastName: string = ''
  // CompanyId?: string = undefined
  title?: string = undefined
  email?: string = undefined
  mobilePhone?: string = undefined
  workPhone?: string = undefined
  allowUserToSignIn: boolean = false
  employmentStartDate?: Date | string = undefined
  employmentEndDate?: Date | string = undefined
  isActive: boolean = true
  isUser: boolean = false
  company?: CompanyDto = undefined
  landingPage?: string = '/companies'
  permissions: PermissionDto[] = []
  departments: DepartmentDto[] = []
  // LandingPage?: string = undefined;

  constructor(model?: any) {}
}

export const peopleValidationSchema = yup.object<PersonDto>({
  id: yup.string().nullable(),
  firstName: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  lastName: yup
    .string()
    .required(requiredFieldMessage)
    .max(maxString200Value, maxString200Message),
  company: yup.object().nullable(),
  title: yup.string().max(maxString200Value, maxString200Message).nullable(),
  email: yup
    .string()
    .email(fieldTypeMessage('Email'))
    .max(320, maxTextFieldMessage(320))
    .nullable(),
  mobilePhone: yup.string().max(maxString200Value, maxString200Message).nullable(),
  workPhone: yup.string().max(maxString200Value, maxString200Message).nullable(),
  allowUserToSignIn: yup.bool(),
  employmentStartDate: yup.string().nullable(),
  employmentEndDate: yup.string().nullable(),
  isActive: yup.bool()
})
