import axios from 'axios'
export { getBoardTypes } from './boardTypes'
export {
  getCompanies,
  getCompanyShippingCompanies,
  getCompanyTargetCompanies,
  getCompanyVendors
} from './companies'
export { getDepartments } from './departments'
export { getFinishedGoodItemTypes } from './finishedGoodItemTypes'
export {
  createFinishedGoodsFromBanding,
  getFinishedGoodsInventory,
  getFinishedGoodsInventoryByJobId,
  updateFinishedGoodsInventory
} from './finishedGoodsInventory'
export {
  createFinishedGoodsInventoryReconciliationAdjustment,
  createFinishedGoodsInventoryScrapAdjustment
} from './finishedGoodsInventoryAdjustment'
export { getForkliftPalletLocations } from './forkliftPalletLocations'
export {
  getForkliftToDoById,
  getForkliftToDos,
  updateForkliftToDo
} from './forkliftToDos'
export { getJobPauseReasonTypes } from './jobPauseReasonTypes'
export {
  createJob,
  getBigJackToDoById,
  getBigJackToDos,
  getJobById,
  getJobPriorityListByDateAndId,
  getJobs,
  getLittleJackToDoById,
  getLittleJackToDos,
  updateJob
} from './jobs'
export { createJobStatus } from './jobStatuses'
export { getJobStatusTypes } from './jobStatusTypes'
export { getJobStopReasonTypes } from './jobStopReasonTypes'
export {
  createOpportunity,
  getOpportunities,
  getOpportunityById,
  updateOpportunity
} from './opportunities'
export { addActivity } from './opportunityActivities'
export { getOpportunityActivityTypes } from './opportunityActivityTypes'
export { getOpportunityNextActivityTypes } from './opportunityNextActivityTypes'
export { getOpportunityProbabilities } from './opportunityProbabilities'
export { getOpportunityStatuses } from './opportunityStatuses'
export {
  getPeople,
  getPersonCompanyPeopleByCompanyId,
  getPersonSalespeople
} from './people'
export { getQuoteAssemblyJoints } from './quoteAssemblyJoints'
export { getQuoteAssemblyStyles } from './quoteAssemblyStyles'
export { getQuoteAssemblyUnitTypes } from './quoteAssemblyUnitTypes'
export { downloadQuoteLineItemDesignFile } from './quoteLineItemDesignFiles'
export {
  createNewQuoteRevision,
  duplicateQuoteRevision,
  getQuoteById,
  updateQuote
} from './quotes'
export {
  createRatesAssemblyAdder,
  getRatesAssemblyAdderById,
  getRatesAssemblyAdders,
  updateRatesAssemblyAdder
} from './ratesAssemblyAdders'
export {
  createRatesBlendAdder,
  getRatesBlendAdderById,
  getRatesBlendAdders,
  updateRatesBlendAdder
} from './ratesBlendAdders'
export { getRatesEndCostAdders } from './ratesEndCostAdders'
export {
  createRatesGlobal,
  getBigJackPiecesPerHour,
  getLittleJackPiecesPerHour,
  getRatesGlobal,
  updateRatesGlobal
} from './ratesGlobal'
export {
  createRatesInk,
  getRatesInkById,
  getRatesInks,
  updateRatesInk
} from './ratesInks'
export {
  createRatesPaper,
  getRatesPaper,
  getRatesPaperById,
  updateRatesPaper
} from './ratesPaper'
export {
  createRatesPaperAdder,
  getRatesPaperAdderById,
  getRatesPaperAdders,
  updateRatesPaperAdder
} from './ratesPaperAdders'
export {
  createRatesScoringAdder,
  getRatesScoringAdderById,
  getRatesScoringAdders,
  updateRatesScoringAdder
} from './ratesScoringAdders'
export {
  createRawInventory,
  getRawInventory,
  getRawInventoryByVendorOrderId,
  updateRawInventory
} from './rawInventory'
export {
  createRawInventoryReconciliationAdjustment,
  createRawInventoryScrapAdjustment
} from './rawInventoryAdjustment'
export {
  getBillOfLadingReportByShippingId,
  getCustomerOrdersCsvReport,
  getFinishedGoodsInventoryAdjustmentsCsvReport,
  getFinishedGoodsInventoryStockCsvReport,
  getPurchaseOrderReport,
  getQuoteReportById,
  getQuoteReportByLineItemId,
  getRawInventoryAdjustmentsCsvReport,
  getRawInventoryStockCsvReport,
  getReceivingCsvReport,
  getShippingCsvReport,
  getTravelerReport,
  getTravelerReportStopCount,
  getVendorOrdersCsvReport,
  getVendorUsageCsvReport
} from './reports'
export { createShipping, getShipping, getShippingById, updateShipping } from './shipping'
export {
  createShippingItem,
  getShippingItemById,
  getShippingItems,
  updateShippingItem
} from './shippingItems'
export {
  createVendorOrder,
  getVendorOrderById,
  getVendorOrders,
  getVendorOrdersReceiving,
  readyToSchedule,
  reorderVendorOrder,
  updateVendorOrder
} from './vendorOrders'

const apiClient = axios.create({
  // Pass cookies
  // withCredentials: true
})

apiClient.interceptors.response.use(
  response => response,
  async error => {
    if (error.response.status === 400) {
      // If there's a single error formatted like this, it's an ApplicationValidationException with a specific message for the user
      // Throw it as an array with one element for consistent error formatting
      if (error.response.data.error) {
        throw [error.response.data.error.message]
      }
      // If there's multiple errors re-throw them as separate errors.
      if (error.response.data.errors) {
        var errors = Object.keys(error.response.data.errors).flatMap(
          key => error.response.data.errors[key]
        )
        throw errors
      }
    } else if (error.response.status === 401 || error.response.status === 403) {
      apiClient.post('/api/account/sign-out').then(() => {
        // If the /api returns status 401 (Unauthorized) or 403 (Forbidden), return the user to sign in.
        window.location.assign('/account/sign-in')
      })
      return false
    } else if (error.response.status === 500) {
      throw ['Error: Request failed with status code 500!']
    }

    throw [error]
  }
)

export default apiClient
