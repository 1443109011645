import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField
} from '@mui/material'
import { ExplanationAccordion } from 'components/_template/accordion'
import { showFormErrorsPrompt } from 'components/_template/form/FormErrorsPrompt'
import {
  ForkliftPalletLocationDto,
  ForkliftToDoDto,
  forkliftToDosScanPalletBarcodeValidationSchema
} from 'dtos'
import { Formik, setNestedObjectValues } from 'formik'
import { useRef } from 'react'

interface ForkliftToDosScanPalletBarcodeDialogProps {
  forkliftPalletLocations?: ForkliftPalletLocationDto[]
  forkliftToDo?: ForkliftToDoDto
  onClose: () => void
  open: boolean
  onSave: (values: ForkliftToDoDto) => Promise<void | ForkliftToDoDto>
}

export default function ForkliftToDosScanPalletBarcodeDialog({
  forkliftPalletLocations,
  forkliftToDo,
  onClose,
  open,
  onSave
}: ForkliftToDosScanPalletBarcodeDialogProps) {
  // These refs need to be cast to type any because TypeScript doesn't like them being initialized to null
  const vendorOrderNumberRef: any = useRef(null)
  const vendorItemNumberRef: any = useRef(null)
  const palletNumberRef: any = useRef(null)
  const dropOffForkliftPalletLocationRef: any = useRef(null)

  const onFocusVendorOrderNumber = () => {
    vendorOrderNumberRef.current.focus()
  }
  const onFocusVendorItemNumber = () => {
    vendorItemNumberRef.current.focus()
  }
  const onFocusPalletNumber = () => {
    palletNumberRef.current.focus()
  }
  const onFocusDropOffForkliftPalletLocation = () => {
    dropOffForkliftPalletLocationRef.current.focus()
  }

  return (
    // disableRestoreFocus seems necessary for the autofocus prop to function. See https://github.com/mui/material-ui/issues/33004#issuecomment-1455260156
    <Dialog open={open} disableRestoreFocus>
      <Formik
        enableReinitialize
        initialValues={forkliftToDo || new ForkliftToDoDto()}
        validateOnBlur
        validateOnChange
        validationSchema={forkliftToDosScanPalletBarcodeValidationSchema}
        onSubmit={(values, formikHelpers) => {
          // Clear the form values whether the save method saves successfully or throws an error
          onSave(values).finally(() => {
            onFocusVendorOrderNumber()
            formikHelpers.resetForm()
          })
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setTouched,
          submitForm,
          touched,
          validateForm,
          values
        }) => {
          const onSubmitForm = () => {
            // Due to an issue with Formik, the form fields need to be manually set as touched when there are errors on submit.
            // Otherwise, sometimes the field errors won't display even though the validation throws them
            validateForm().then(errors => {
              const errorKeys = Object.keys(errors)
              if (errorKeys.length === 0) {
                submitForm()
              } else {
                setTouched(setNestedObjectValues(errors, true))
                showFormErrorsPrompt(errors)
              }
            })
          }

          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} alignItems='center'>
                  <DialogTitle>
                    Scan Barcodes (or key in values) for each pallet
                  </DialogTitle>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      error={Boolean(
                        touched.vendorOrderNumber && errors.vendorOrderNumber
                      )}
                      fullWidth
                      helperText={touched.vendorOrderNumber && errors.vendorOrderNumber}
                      label='Vendor Order Number'
                      name='vendorOrderNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.vendorOrderNumber || ''}
                      inputRef={vendorOrderNumberRef}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          onSubmitForm()
                        }
                      }}
                      inputProps={{ tabIndex: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.vendorItemNumber && errors.vendorItemNumber)}
                      fullWidth
                      helperText={touched.vendorItemNumber && errors.vendorItemNumber}
                      label='Vendor Item Number'
                      name='vendorItemNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.vendorItemNumber || ''}
                      inputRef={vendorItemNumberRef}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          onSubmitForm()
                        }
                      }}
                      inputProps={{ tabIndex: 2 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.palletNumber && errors.palletNumber)}
                      fullWidth
                      helperText={touched.palletNumber && errors.palletNumber}
                      label='Pallet Number'
                      name='palletNumber'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.palletNumber || ''}
                      inputRef={palletNumberRef}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          onSubmitForm()
                        }
                      }}
                      inputProps={{ tabIndex: 3 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={isSubmitting}
                      error={Boolean(
                        touched.dropOffForkliftPalletLocation &&
                          errors.dropOffForkliftPalletLocation
                      )}
                      fullWidth
                      helperText={
                        touched.dropOffForkliftPalletLocation &&
                        errors.dropOffForkliftPalletLocation
                      }
                      label='Drop Off Location'
                      name='dropOffForkliftPalletLocation'
                      onBlur={handleBlur}
                      onChange={e => {
                        setFieldValue(
                          'dropOffForkliftPalletLocation',
                          JSON.parse(e.target.value)
                        )
                      }}
                      select
                      value={
                        values?.dropOffForkliftPalletLocation
                          ? JSON.stringify(
                              forkliftPalletLocations?.filter(
                                forkliftPalletLocation =>
                                  forkliftPalletLocation.id ===
                                  values.dropOffForkliftPalletLocation!.id
                              )[0]
                            )
                          : ''
                      }
                      inputRef={dropOffForkliftPalletLocationRef}
                      onKeyUp={e => {
                        if (e.key === 'Enter') {
                          onSubmitForm()
                        }
                      }}
                      inputProps={{ tabIndex: 3 }}
                    >
                      {forkliftPalletLocations
                        ?.filter(
                          forkliftPalletLocation =>
                            (values.forkliftInstruction?.specialIdentifier ==
                              'PICK_UP_FROM_RECEIVING' &&
                              forkliftPalletLocation.specialIdentifier == null) ||
                            (values.forkliftInstruction?.specialIdentifier ==
                              'PICK_UP_AND_DELIVER_TO_BIG_JACK' &&
                              forkliftPalletLocation.specialIdentifier == 'BIG_JACK') ||
                            (values.forkliftInstruction?.specialIdentifier ==
                              'PICK_UP_AND_DELIVER_TO_LITTLE_JACK' &&
                              forkliftPalletLocation.specialIdentifier == 'LITTLE_JACK')
                        )
                        .map(forkliftPalletLocation => (
                          <MenuItem
                            key={forkliftPalletLocation.id}
                            value={JSON.stringify(forkliftPalletLocation)}
                          >
                            {forkliftPalletLocation.description}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <ExplanationAccordion>
                      If the Vendor Order Number, Vendor Item Number, and Pallet Number
                      combination does not have an associated Forklift to Do, a warning
                      will be displayed saying the Forklift to Do cannot be found.
                    </ExplanationAccordion>
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions sx={{ mb: 2, mr: 2 }}>
                <Button
                  disabled={isSubmitting}
                  color='secondary'
                  onClick={() => {
                    onClose()
                  }}
                  tabIndex={6}
                  variant='text'
                >
                  CLOSE
                </Button>

                <Button
                  disabled={isSubmitting}
                  onClick={() => {
                    onSubmitForm()
                  }}
                  tabIndex={5}
                  variant='contained'
                >
                  SUBMIT
                </Button>
              </DialogActions>
            </form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
