import { ExplanationAccordion } from 'components/_template/accordion'
import { ReadMoreOutlined, SearchOutlined } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  Divider,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { Layout } from 'components/_template'
import { List, ListHeaderCard, ListItemCard } from 'components/_template/list'
import { useNavigate } from 'react-router-dom'
import { Avatar } from '@mui/material'
import { Box, Grid } from '@mui/material'
import {
  green,
  grey,
  red,
  orange,
  blueGrey,
  lightBlue,
  purple
} from '@mui/material/colors'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import ContactsIcon from '@mui/icons-material/Contacts'
import DescriptionIcon from '@mui/icons-material/Description'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import TagFacesIcon from '@mui/icons-material/TagFaces'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import InventoryIcon from '@mui/icons-material/Inventory'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
export default function MaterialCoordinator() {
  const navigate = useNavigate()

  return (
    <Layout title='Material Coordinator'>
      {/* HEADER CARD */}
      {/* HEADER CARD */}
      {/* HEADER CARD */}

      <List
        header={
          <ListHeaderCard
            title='Material Coordinator'
            filters={[
              <TextField
                data-search
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchOutlined fontSize='small' />
                    </InputAdornment>
                  )
                }}
                label='Search'
                name='search'
                size='small'
                type='search'
              />
            ]}
          />
        }
      >
        {/* DATA CARD */}
        {/* DATA CARD */}
        {/* DATA CARD */}

        <ListItemCard
          key={1}
          title={'ACME Motors - WO#: 54321'}
          actions={[
            <Tooltip title={'Details'}>
              <IconButton
                onClick={() => navigate('/material-coordinator/new')}
                color='primary'
                // size='large'
              >
                <ReadMoreOutlined fontSize='large' />
              </IconButton>
            </Tooltip>
          ]}
        >
          <Typography variant='body2' sx={{ color: '#7F7F7F', fontWeight: 600 }}>
            {'Customer part number: T-1000 RP 8675309'}
          </Typography>
          <Typography variant='body2' sx={{ color: '#7F7F7F', fontWeight: 600 }}>
            {'Committed quantity (pieces): 240,000'}
          </Typography>
        </ListItemCard>
        {/* ACCORDION NOTES CARD */}
        {/* ACCORDION NOTES CARD */}
        {/* ACCORDION NOTES CARD */}

        <Grid item xs={12}>
          <ExplanationAccordion>
            Shows opportunities once they are marked as sold that don't have work orders
            and vendor orders that are created on the next screen.
          </ExplanationAccordion>
          {/* <ExplanationAccordion development>
             	     xxxxxxxxxxxxxxxxxxx||xxxxxxxxxxxxxxxxxxx 
             	   </ExplanationAccordion> */}
        </Grid>
      </List>
    </Layout>
  )
}
