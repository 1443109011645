import { Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'

export type ListHeaderCardProps = {
  title: string
  filters?: React.ReactNode[]
  actions?: React.ReactNode[]
}

export default function ListHeaderCard({ title, filters, actions }: ListHeaderCardProps) {
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2} alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Typography variant='h5' sx={{ fontWeight: 600 }}>
              {title}
            </Typography>
          </Grid>

          {filters &&
            filters.map((filter, index) => (
              <Grid item key={index}>
                {filter}
              </Grid>
            ))}

          {actions &&
            actions.map((action, index) => (
              <Grid item key={index}>
                {action}
              </Grid>
            ))}
        </Grid>
      </CardContent>
    </Card>
  )
}
