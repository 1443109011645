import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { QuoteDto } from 'dtos'
import { useEffect, useState } from 'react'

interface PrintPreviousQuoteDialogProps {
  onClose: () => void
  onPrint: (quoteId: string, revisionNumber: number) => void
  open: boolean
  quote: QuoteDto
}

export default function PrintPreviousQuoteDialog({
  onClose,
  onPrint,
  open,
  quote
}: PrintPreviousQuoteDialogProps) {
  const [revisionNumber, setRevisionNumber] = useState<number>(1)
  const [options, setOptions] = useState<number[]>([])

  useEffect(() => {
    const ceiling = quote.quoteRevision?.revisionNumber ?? 1

    const previsionRevisions: number[] = []

    for (let i = 1; i < ceiling; i++) {
      previsionRevisions.push(i)
    }

    setOptions(previsionRevisions)
    setRevisionNumber(previsionRevisions[previsionRevisions.length - 1])
  }, [quote])

  return (
    <Dialog open={open}>
      <DialogContent>
        <Grid container spacing={2} alignItems='center'>
          <DialogTitle>Select previous revision to print</DialogTitle>

          <Grid item xs={12}>
            <TextField
              label='Revision #'
              value={revisionNumber}
              select
              fullWidth
              onChange={e => {
                setRevisionNumber(+e.target.value)
              }}
            >
              {options.map(option => (
                <MenuItem key={option} value={option}>
                  Revision {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant='text' color='secondary' onClick={onClose}>
          CLOSE
        </Button>

        <Button
          variant='contained'
          onClick={() => {
            onPrint(quote.id!, revisionNumber)
          }}
        >
          PRINT
        </Button>
      </DialogActions>
    </Dialog>
  )
}
