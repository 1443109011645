import { ApiResponse, CompanyDto } from 'dtos'
import { CompaniesParameters } from 'parameters'
import { URIQuery } from 'utils'
import apiClient from '.'

export const getCompanies = (parameters: CompaniesParameters) =>
  apiClient
    .get<ApiResponse<CompanyDto[]>>('/api/companies' + URIQuery({ ...parameters }))
    .then(({ data }) => data)

export const getCompanyTargetCompanies = () =>
  apiClient
    .get<ApiResponse<CompanyDto[]>>('/api/companies/target-companies')
    .then(({ data }) => data)

export const getCompanyShippingCompanies = () =>
  apiClient
    .get<ApiResponse<CompanyDto[]>>('/api/companies/shipping-companies')
    .then(({ data }) => data)

export const getCompanyVendors = () =>
  apiClient
    .get<ApiResponse<CompanyDto[]>>('/api/companies/vendors')
    .then(({ data }) => data)
